import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './LandingHero.styles';
import useLandingPageStyles from 'modules/LandingPage/views/LandingPage/Landing.styles';

export const LandingHero = () => {
  const classes = useStyles(useTheme());
  const landingPageClasses = useLandingPageStyles(useTheme());

  return (
    <section className={classes.LandingHero}>
      <div className={`${landingPageClasses.LandingBody}`}>
        <div className={classes.LandingHeroBody}>
          <div className={classes.HeroHeadlineBody}>
            <span className={classes.HeroHeadline1}>Increase your design sales</span>
            <span className={classes.HeroHeadline2}>with <span className={classes.Underline}>custom mockups</span></span>
            <div className={classes.SubheadLine}>
              Create Mockups with a few clicks from a huge collection of predefined models. No extra software required.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
