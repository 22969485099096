import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  header: {
    backgroundColor: '#FFF0DF',
    color: theme.palette.text.primary,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    transition: 'all 0.2s ease-out',
  },
  headerSmall: {
    height: '60px',
    borderBottom: '1px solid gainsboro',
  },
  headerLarge: {
    height: '100px',
  },
  headerBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(5),
    zIndex: 100,
    left: 0,
  },
  headerComponent: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    padding: '8px',
    '&>a': {
      height: '100%',
    },
  },
  headerActionButton: {
    marginLeft: theme.spacing(2),
    background: 'linear-gradient(45deg,#e08255 0,#fb5b5b 100%)',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.5rem',
    },
    
  },
  headerLogo: {
    height: '30px',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: '13px',
    },
  },
  headerLinks: {
    listStyle: 'none',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  headerLink: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: '#463a3a',
    fontSize: '1.2rem',
    '&:hover': {
      color: '#ff6c0f',
    }
  },
  menuIcon: {
    display: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      //display: 'initial',
    },
  },
  menuList: {

  },
}));
