import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ModelSelection.styles';
import { ModelThumbnailCollection } from 'modules/Simulator3d/components/ModelThumbnailCollection';
import { ModelCategory } from 'modules/ThreeManager/Models/enum/ModelCategory';
import { Tooltip } from 'shared/components/Tooltip';

import CategoryAll from 'shared/assets/images/category_all.svg';
//import CategoryCosmetics from 'shared/assets/images/category_cosmetics.svg';
import CategoryDecorations from 'shared/assets/images/category_decoration.svg';
import CategoryElectronics from 'shared/assets/images/category_electronics.svg';
import CategoryFloor from 'shared/assets/images/category_floor.svg';
import CategoryFurniture from 'shared/assets/images/category_furniture.svg';
import CategoryFrame from 'shared/assets/images/category_frame.svg';
import CategoryLights from 'shared/assets/images/category_lights.svg';
//import CategoryPackaging from 'shared/assets/images/category_packaging.svg';
import CategoryPlants from 'shared/assets/images/category_plants.svg';
import CategoryTextiles from 'shared/assets/images/category_textiles.svg';
import CategoryWall from 'shared/assets/images/category_wall.svg';
import CategoryWorkUtils from 'shared/assets/images/category_workutils.svg';
import Questionmark from 'shared/assets/images/questionmark.svg';

interface ModelCategorySelection { id: ModelCategory | string, icon: string, label: string};

const categories = [
  { id: ModelCategory.Walls, icon: CategoryWall, label: 'Walls' },
  { id: ModelCategory.Floors, icon: CategoryFloor, label: 'Floors' },
  { id: ModelCategory.Textiles, icon: CategoryTextiles, label: 'Textiles' },
  { id: ModelCategory.Decorations, icon: CategoryDecorations, label: 'Decorations' },
  { id: ModelCategory.Electronics, icon: CategoryElectronics, label: 'Electronics' },
  { id: ModelCategory.Frames, icon: CategoryFrame, label: 'Frames' },
  { id: ModelCategory.Furniture, icon: CategoryFurniture, label: 'Furniture' },
  { id: ModelCategory.Lights, icon: CategoryLights, label: 'Lights' },
  //{ id: ModelCategory.Packaging, icon: CategoryPackaging, label: 'Packaging' },
  { id: ModelCategory.Plants, icon: CategoryPlants, label: 'Plants' },
  //{ id: ModelCategory.Cosmetics, icon: CategoryCosmetics, label: 'Cosmetics' },
  { id: ModelCategory.WorkUtils, icon: CategoryWorkUtils, label: 'WorkUtils' },
  { id: ModelCategory.All, icon: CategoryAll, label: 'All' },
];

const bottomCategories = [
  { id: 'Questionmark', icon: Questionmark, label: 'Help' },
];

interface ModelSelectionProps {
  onModelSelection: (_id: string) => void,
  onHelpClick: () => void,
}

export function ModelSelection(props: ModelSelectionProps) {
  const { onModelSelection, onHelpClick } = props;
  const classes = useStyles(useTheme());
  const [ selectedCategory, setSelectedCategory ] = React.useState(ModelCategory.Walls);

  const renderCategoryButton = (category: ModelCategorySelection, onClick: () => void) => {
    return (
      <Tooltip arrow key={`Tooltip_${category.label}`} title={category.label || ''} placement="right">
        <div
          onClick={onClick}
          className={`
            ${classes.ModelSelectionCategory}
            ${category.id === selectedCategory ? classes.ModelSelectionCategorySelected : classes.ModelSelectionCategoryNonSelected}
          `}
        >
          <img alt="" className={classes.ModelSelectionCategoryIcon} src={category.icon} />
        </div>
      </Tooltip>
    )
  }

  return (
    <div id="modelSelection" className={classes.ModelSelection}>
      <div className={classes.ModelSelectionCategoryContainer}>
        <div className={classes.ModelSelectionCategoryBlock}>
          {
            categories.map(category => renderCategoryButton(category, () => setSelectedCategory(category.id)))
          }
        </div>
        <div className={classes.ModelSelectionCategoryBlock}>
          {
            bottomCategories.map(category => renderCategoryButton(category, onHelpClick))
          }
        </div>
      </div>
      <div className={classes.ModelSelectionBody}>
        <div className={classes.ModelSelectionTitle}>{selectedCategory}</div>
        <ModelThumbnailCollection filter={selectedCategory} onSelect={onModelSelection}></ModelThumbnailCollection>
      </div>
    </div>
  );
}
