import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';


export default makeStyles((theme: Theme) => createStyles({
  ImageEditContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ImageEditCanvas: {
    width: '100%',
    height: '100%',
  },
}));
