import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ThumbnailCollection: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    overflow: 'hidden',
  },
  Thumbnail: {
    height: "90px",
    width: "90px",
    backgroundColor: '#464646',
    '& img': {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    '&:hover': {
      backgroundColor: '#4f4e4e',
    }
  },
  ThumbnailGrid: {
    display: "grid",
    width: "100%",
    height: "100%",
    gridGap: "4px",
    listStyle: "none",
    marginTop: 0,
    marginBottom:0,
    overflowY: "auto",
    padding: theme.spacing(0.5),
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  IntersectionBar: {
    width: '100%',
    height: '5px',
  },
  IntersectionGutter: {
    height: theme.spacing(3),
    width: '100%',
  }
}));
