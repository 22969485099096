import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import { Theme, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'rootReducer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import defaultLightTheme from 'shared/assets/theme/lightTheme';
import { Login } from './Login';
import { Register } from './Register';
import { loginUser, loginUserWithGoogle, registerUser, resetLoginError } from 'features/user/user';
import { ResetPassword } from './ResetPassword';
import history from 'shared/utils/history';

export enum LoginDialogViewState {
  Signin,
  Signup,
  ResetPassword,
}

export interface LoginDialogProps {
  isOpen: boolean;
  loginViewState?: LoginDialogViewState;
  redirectOnSuccessTo?: string;
  onCloseClick: () => void;
  onSwitchViewStateClick: (viewState: LoginDialogViewState) => void;
}

const Dialog = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialog);

export function LoginDialog(props: LoginDialogProps) {
  const {
    isOpen = true,
    loginViewState = LoginDialogViewState.Signin,
    redirectOnSuccessTo,
    onCloseClick,
    onSwitchViewStateClick,
  } = props;
  const lightTheme = createMuiTheme(defaultLightTheme);
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state);

  const handleLoginClick = async (email: string, password: string) => {
    dispatch(loginUser(email, password));
  }

  const handleGoogleLoginClick = async (tokenId: string) => {
    dispatch(loginUserWithGoogle(tokenId));
  }

  const handleChange = () => {
    dispatch(resetLoginError());
  }

  const handleRegisterClick = async (email: string, password: string) => {
    dispatch(registerUser(email, password));
  }

  // dont open the login/register dialogs if the user is already logged in
  if (user.loading === false && user.data) {
    onCloseClick();
    if (redirectOnSuccessTo) {
      history.push(redirectOnSuccessTo);
    }
  }

  const renderDialogBody = () => {
    switch (loginViewState) {
      case LoginDialogViewState.ResetPassword:
        return <ResetPassword
          onSwitchToLoginClick={() => onSwitchViewStateClick(LoginDialogViewState.Signin)}
          onSwitchToRegisterClick={() => onSwitchViewStateClick(LoginDialogViewState.Signup)}
        />;
      case LoginDialogViewState.Signup:
        return <Register
          onChange={handleChange}
          isLoading={user.loading}
          hasError={user.errors || ''}
          onRegisterClick={handleRegisterClick}
          onSwitchToLoginClick={() => onSwitchViewStateClick(LoginDialogViewState.Signin)}
          onSwitchToResetPasswordClick={() => onSwitchViewStateClick(LoginDialogViewState.ResetPassword)}
        />;
      case LoginDialogViewState.Signin:
      default:
        return <Login
          onChange={handleChange}
          isLoading={user.loading}
          hasError={user.errors || ''}
          onLoginClick={handleLoginClick}
          onGoogleLoginClick={handleGoogleLoginClick}
          onSwitchToRegisterClick={() => onSwitchViewStateClick(LoginDialogViewState.Signup)}
          onSwitchToResetPasswordClick={() => onSwitchViewStateClick(LoginDialogViewState.ResetPassword)}
        />;
    }
  }

  return (
    <div>
      <ThemeProvider theme={lightTheme}>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          onClose={onCloseClick}
          open={isOpen}
        >
          { renderDialogBody() }
        </Dialog>
      </ThemeProvider>
    </div>
  );
}