import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ObjectProperties: {
  },
  ModelPropertiesSelection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
}));
