import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Tooltip } from 'shared/components/Tooltip';
import useStyles from './ToggleButtons.styles';

export interface ToggleButton {
  content: JSX.Element,
  value: string,
  disabled?: boolean,
  tooltipLabel?: string,
}

interface ToggleButtonsProps {
  selectedToggle?: string,
  orientation?: 'horizontal' | 'vertical',
  tooltipPlacement?: 'left' | 'right' | 'top' | 'bottom',
  toggleButtonData?: ToggleButton[],
  onToggleChange?: (event: React.MouseEvent<HTMLElement>, selectedToggleValue: string) => void,
}

export function ToggleButtons(props: ToggleButtonsProps) {
  const {
    selectedToggle,
    orientation='vertical',
    tooltipPlacement='right',
    toggleButtonData = [],
    onToggleChange = (event: React.MouseEvent<HTMLElement>, selectedToggleValue: string) => {},
  } = props;
  const classes = useStyles(useTheme());

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, selectedToggleValue: string) => {
    onToggleChange(event, selectedToggleValue);
  }

  return (
    <ToggleButtonGroup
      size="small"
      value={selectedToggle || toggleButtonData[0] || ''}
      exclusive
      onChange={handleToggleChange}
      orientation={orientation}
      className={classes.ToggleButtons}
    >
      {
        toggleButtonData.map(toggleButton => (
          <ToggleButton key={toggleButton.value} disabled={toggleButton.disabled} value={toggleButton.value}>
            <Tooltip title={toggleButton.tooltipLabel || ''} placement={tooltipPlacement}>
              { toggleButton.content }
            </Tooltip>
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
}
