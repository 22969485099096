import { configureStore, Action, Unsubscribe } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { ControllerAbstract } from 'modules/ThreeManager/Controller/ControllerAbstract';

import rootReducer, { RootState } from './rootReducer';

const store = configureStore({
  reducer: rootReducer
});

const subscribers: { [constrollerId: string]: Unsubscribe } = {};

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export function addControllerAsSubscriber(controller: ControllerAbstract) {
  if (subscribers[controller.id]) return;

  subscribers[controller.id] = (store.subscribe(() => {
    controller.processIncomingState(store.getState());
  }));
}

export function removeControllerAsSubscriber(controller: ControllerAbstract) {
  const foundUnsubscriber = subscribers[controller.id];
  if (foundUnsubscriber) foundUnsubscriber();
}

export default store;