import { Scene } from "three";
import { CameraSettings } from "features/scenes/types";
import { Vector3d } from "../Models/Vector3d";
import { StateUpdateHandlerAbstract } from "../StateUpdateHandler/StateUpdateHandlerAbstract";

export enum ControlsUpdateMode {
  PositionUpdate,
  RotationUpdate,
  ScaleUpdate,
  CameraPositionLookAtUpdate,
}

export interface ObjectVector3dUpdatePayload {
  threeObjectId: number,
  vector: Vector3d,
}

export interface CameraPositionLookAtUpdatePayload {
  position: Vector3d,
  lookAt: Vector3d,
}

export interface ControlsStateUpdateMessage {
  sceneId: string,
  stateUpdate: ControlsUpdateMode,
  payload: ObjectVector3dUpdatePayload | CameraPositionLookAtUpdatePayload,
}

export abstract class ControlsManagerAbstract {
  protected sceneId: string = '';
  protected renderCallback: (forced?: boolean) => void = () => {};
  protected updateHandlers: Array<StateUpdateHandlerAbstract<ControlsStateUpdateMessage>> = [];

  constructor(
    protected scene: Scene,
    protected camera: THREE.Camera,
    protected renderer: THREE.WebGLRenderer,
    protected canvas: HTMLCanvasElement,
  ) { }

  init(renderCallback: () => void = () => {}, sceneId: string) {
    this.renderCallback = renderCallback;
    this.sceneId = sceneId;
  }

  abstract setControlVisibility(isVisible: boolean): void;
  abstract setCamera(cameraSettings: CameraSettings): void;
  abstract setSelectedObject(selectedThreeObjectId?: number): void;
  abstract setCameraToFitObject(objectId: number, offset: number): void;

  addUpdateHandler(updateHandler: StateUpdateHandlerAbstract<ControlsStateUpdateMessage>) {
    if (this.updateHandlers.find(elem => elem.id === updateHandler.id)) return;
    this.updateHandlers.push(updateHandler);
  }

  removeUpdateHandler(updateHandler: StateUpdateHandlerAbstract<ControlsStateUpdateMessage>) {
    this.updateHandlers = this.updateHandlers.filter(elem => elem.id !== updateHandler.id);
  }

  protected controlsStateUpdateHandlersEmitAll(stateUpdate: ControlsUpdateMode, payload: ObjectVector3dUpdatePayload | CameraPositionLookAtUpdatePayload) {
    const message: ControlsStateUpdateMessage = {
      sceneId: this.sceneId,
      stateUpdate: stateUpdate,
      payload,
    }
    this.updateHandlers.forEach(updateHandler => updateHandler.emit(message));
  }
}