import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { fetchAll } from 'features/models/models';

import { ThumbnailCollection, ThumbnailCollectionData } from 'shared/components/ThumbnailCollection';
import ModelApi from 'shared/utils/api/model';
import { ModelCategory } from 'modules/ThreeManager/Models/enum/ModelCategory';

const IMAGES_PER_PAGE = 18;

interface ModelThumbnailCollectionProps {
  filter?: string;
  onSelect?: (_id: string) => void,
}

export function ModelThumbnailCollection(props: ModelThumbnailCollectionProps) {
  const stableDispatch = React.useCallback(useDispatch(), [])
  const [ page, setPage ] = React.useState(1);
  const { models } = useSelector((state: RootState) => state);

  React.useEffect(() => {
    stableDispatch(fetchAll());
  }, [stableDispatch]);

  const handleCollectionEndReached = () => {
    setPage(prevPage => prevPage + 1);
  }

  const modelData: ThumbnailCollectionData[] = models.data
    .filter(modelData => {
      // dont filter if filter is missing or all is selected
      if (!props.filter || props.filter === ModelCategory.All) return true;
      // filter for categories.
      return modelData.category.includes(props.filter as ModelCategory);
    })
    .map(model => ({
      _id: model._id,
      thumbnailUrl: new ModelApi().createThumbnailUrl(model.thumbKey),
    }))
    .splice(0, IMAGES_PER_PAGE * page);

  return (
    <ThumbnailCollection
      data={modelData}
      onCollectionEndReached={handleCollectionEndReached}
      onSelect={props.onSelect}
    />
  );
}
