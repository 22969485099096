import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  TemplateScenesContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  TemplateScenes: {
    width: '100%',
    maxWidth: '1200px',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    gap: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
    '& a': {
      textDecoration: 'none',
    }
  },
}));
