import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  TemplateSceneCard: {
    height: '100%',
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '2px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  TemplateSceneCardImageContainer: {
    height: 'calc(100% - 50px)',
    width: '100%',
    position: 'relative',
  },
  TemplateSceneCardImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  TemplateSceneCardTitle: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.0rem',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  Info: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    backgroundColor: '#55555588',
    borderRadius: '999px',
    color: '#FFFFFF',
    lineHeight: 0,
    "&:hover": {
      color: '#000000',
    }
  }
}));