import * as React from 'react';
import { Button, Drawer, List, ListItemText, ListItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import useLandingPageStyles from 'modules/LandingPage/views/LandingPage/Landing.styles';
import useStyles from './Header.styles';
import history from 'shared/utils/history';
import logo from 'shared/assets/images/mockupfox_logo_black.svg';

const LINKS: any[] = [
  { label: 'Templates', link: '/mockupscenes' },
  { label: 'Blog', link: '/blog' },
];

export const Header = () => {
  const [ showsDrawer, setDrawer ] = React.useState(false);
  const [ isSmallHeader, setSmallHeader ] = React.useState(false);
  const classes = useStyles(useTheme());
  const landingPageClasses = useLandingPageStyles(useTheme());

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const handler = () =>
        setSmallHeader((isSmallHeader) => {
          if (
            !isSmallHeader &&
            (document.body.scrollTop > 100 ||
              document.documentElement.scrollTop > 100)
          ) {
            return true;
          }
  
          if (
            isSmallHeader &&
            document.body.scrollTop < 4 &&
            document.documentElement.scrollTop < 4
          ) {
            return false;
          }
  
          return isSmallHeader;
        });

      window.addEventListener("scroll", handler);
      return () => window.removeEventListener("scroll", handler);
    }
  }, []);

  const list = () => (
    <div
      className={classes.menuList}
      role="presentation"
      onClick={() => setDrawer(false)}
      onKeyDown={() => setDrawer(false)}
    >
      <List>
        {LINKS.map((link) => (
          <ListItem button key={link.label} onClick={() => history.push(link.link)}>
            <ListItemText primary={link.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const renderActionButton = () => {
      return (
        <Button
          variant="contained"
          color="primary"
          component="a"
          className={classes.headerActionButton}
          href="/mockupcreator"
        >
          Open Editor (Beta)
        </Button>
      )
  }

  return (
    <>
      <Drawer anchor="left" open={showsDrawer} onClose={() => setDrawer(false)}>
        {list()}
      </Drawer>
      <header className={`${classes.header} ${isSmallHeader ? classes.headerSmall : classes.headerLarge}`}>
        <div className={`${landingPageClasses.LandingBody}`}>
          <div className={classes.headerBody}>
            <div className={classes.headerComponent}>
              <MenuIcon onClick={() => setDrawer(true)} className={classes.menuIcon}/>
              <a href="/">
                <img alt="" src={logo} className={classes.headerLogo} />
              </a>
            </div>
            <div className={classes.headerComponent}>
              <ul className={classes.headerLinks}>
                {
                  LINKS.map(link => <a href={link.link}><li key={link.label} className={classes.headerLink}>{link.label}</li></a>)
                }
              </ul>
              {
                renderActionButton()
              }
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
