import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Tabs: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  TabTitleContainer: {
    display: 'flex',
  },
  TabTitle: {
    width: "fit-content",
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    cursor: 'pointer',
  },
  SelectedTab: {
    backgroundColor: theme.palette.background.paper,
  },
  TabBody: {
    flexGrow: 1,
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  }
}));
