import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from 'store';
import { ModelsState } from './types';

import ModelApi from 'shared/utils/api/model';

const initialState: ModelsState = {
  loading: false,
  data: [],
};

const modelsSlice = createSlice({
  name: 'Models',
  initialState,
  reducers: {
    fetchRequest(state) {
      state.loading = true;
      state.data = [];
      state.errors = undefined;
    },
    fetchSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchFailure(state, action: PayloadAction<any>) {
      state.loading = false;
      state.data = [];
      state.errors = action.payload;
    }
  }
});

const { fetchSuccess, fetchFailure, fetchRequest } = modelsSlice.actions;

export const fetchAll = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  // Dont reload if models are already loaded
  if (getState().models.data.length === 0 && getState().models.loading === false)
  {
    dispatch(fetchRequest());
    const allTemplates: Array<any> = await new ModelApi().getAll();
    if (allTemplates.length) {
      dispatch(fetchSuccess(allTemplates));
    } else {
      dispatch(fetchFailure('Not Found'));
    }
  }
}

export default modelsSlice.reducer;



