import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './MaterialProperty.styles';
import { StandardMaterialProperty } from './StandardMaterialProperty';
import { ObjectMaterial } from 'modules/ThreeManager/Models/ObjectMaterial';
import { MaterialType } from 'modules/ThreeManager/Models/enum/MaterialType';
import { PhongMaterialProperty } from './PhongMaterialProperty';

interface MaterialPropertyProps {
  objectMaterial?: ObjectMaterial,
  onStandardMaterialRoughnessChange: (roughness: number) => void,
  onStandardMaterialMetalnessChange: (metalness: number) => void,
  onPhongMaterialShininessChange: (shininess: number) => void,
  onPhongMaterialReflectivityChange: (reflectivity: number) => void,
}

export function MaterialProperty(props: MaterialPropertyProps) {
  const {
    objectMaterial,
    onStandardMaterialRoughnessChange,
    onStandardMaterialMetalnessChange,
    onPhongMaterialShininessChange,
    onPhongMaterialReflectivityChange,
  } = props;
  const classes = useStyles(useTheme());

  if (!objectMaterial) return <div>Please select an object</div>;

  return (
    <div className={classes.MaterialProperty}>
      {
        objectMaterial.type === MaterialType.MeshStandardMaterial
        ? <StandardMaterialProperty
            standardMaterialProperty={objectMaterial.property}
            onRoughnessChange={onStandardMaterialRoughnessChange}
            onMetalnessChange={onStandardMaterialMetalnessChange}
          />
        : <PhongMaterialProperty
            phongMaterialProperty={objectMaterial.property}
            onShininessChange={onPhongMaterialShininessChange}
            onReflectivityChange={onPhongMaterialReflectivityChange}
          />
      }
    </div>
  );
}
