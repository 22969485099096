import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { IconButton } from '@material-ui/core';
import TextFieldsIcon from '@material-ui/icons/TextFields';

import useStyles from './ImageLayerSelection.styles';
import { convertObjectToImageType } from 'modules/MappingEditor/utils/fabric.helper';
import { ImageType } from 'modules/MappingEditor/models/enum/ImageType';

interface ImageLayerSelectionProps {
  imageEditObjects: fabric.Object[],
  selectedImageObject?: string,
  onHandleMoveUp?: (objectId: string) => void,
  onHandleMoveDown?: (objectId: string) => void,
  onImageLayerClick?: (objectId: string) => void,
}

export function ImageLayerSelection(props: ImageLayerSelectionProps) {
  const {
    imageEditObjects = [],
    selectedImageObject = '',
    onHandleMoveUp = () => {},
    onHandleMoveDown = () => {},
    onImageLayerClick = () => {},
  } = props;
  const classes = useStyles(useTheme());

  const createLabel = (object: fabric.Object) => {
    return convertObjectToImageType(object)?.toString() || 'Image';
  }

  const getImage = (object: fabric.Object) => {
    switch(convertObjectToImageType(object)) {
      case ImageType.Pattern:
        if(object.data?.image) return <img alt="Preview" className={classes.PreviewImage} src={JSON.parse(object.data?.image).src} />; 
        break;
      case ImageType.Image:
        if((object as any).src) return <img alt="Preview" className={classes.PreviewImage} src={(object as any).src} />;
        break; 
      case ImageType.Circle: return (
        <svg width="32" className={classes.PreviewImage} height="32" xmlns="http://www.w3.org/2000/svg">
          <g>
            <ellipse ry="16" rx="16" cy="16" cx="16" stroke-width="0" stroke="#000" fill={object.fill as string}/>
          </g>
        </svg>
      );
      case ImageType.Rectangle: return (
        <svg width="32" className={classes.PreviewImage} height="32" xmlns="http://www.w3.org/2000/svg">
          <g>
            <rect height="32" width="32" y="0" x="0" fill-opacity="null" stroke-opacity="null" stroke-width="0" stroke="#000" fill={object.fill as string}/>
          </g>
        </svg>
      );
      case ImageType.TextBox: return <TextFieldsIcon className={classes.PreviewImage}/>
    }
    return null;
  }

  const handleMoveLayerUp = (objectId: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onHandleMoveUp(objectId);
  }

  const handleMoveLayerDown = (objectId: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onHandleMoveDown(objectId);
  }

  const handleImageLayerClick = (objectId: string) => {
    onImageLayerClick(objectId);
  }

  const reverseArr = (input: Array<any>) => {
    const ret = [];
    for(let i = input.length-1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
  }

  const imageLayers = reverseArr(imageEditObjects);

  return (
    <div className={classes.ImageLayerSelection}>
      {
        imageLayers.map((object, index, arr) => (
          <div 
            className={`
              ${classes.ImageLayerSelectionElement}
              ${selectedImageObject === object.data.id && classes.ImageLayerSelectionElementSelected}
            `}
            onClick={() => handleImageLayerClick(object.data.id)}
          >
            <div>
              <IconButton
                disabled={index === 0}
                size="small"
                aria-label="move layer up"
                onClick={handleMoveLayerUp(object.data.id)}
              >
                <ArrowUpwardIcon/>
              </IconButton>
              <IconButton
                disabled={index === arr.length - 1}
                size="small"
                aria-label="move layer down"
                onClick={handleMoveLayerDown(object.data.id)}
              >
                <ArrowDownwardIcon/>
              </IconButton>
            </div>
            <span>
              { createLabel(object) || 'Image' }
            </span>
            { getImage(object) }
          </div>
        ))
      }
    </div>
  );
}
