import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  TemplateScenesDetailsContainer: {
    color: '#232323',
    justifyContent: 'center',
    top: 0,
    width: '100%',
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  LoadingPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  }
}));
