import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import useStyles from './LandingCallToAction.styles';
import useLandingPageStyles from 'modules/LandingPage/views/LandingPage/Landing.styles';

export const LandingCallToAction = () => {
  const classes = useStyles(useTheme());
  const landingPageClasses = useLandingPageStyles(useTheme());

  const renderActionButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        component="a"
        className={classes.ActionButton}
        href="/mockupcreator"
      >
        Open Editor (Beta)
      </Button>
    )
  }

  return (
    <section className={classes.LandingCallToAction} id="callToAction">
      <div className={`${landingPageClasses.LandingBody}`}>
        <div className={classes.LandingSubscribeBody}>
          <div className={landingPageClasses.SubHeadline}>
            Create your Mockups now!
          </div>
        </div>
      </div>
      <div className={`${landingPageClasses.LandingBody}`}>
        <div className={classes.LandingNumbersBody}>
          <div className={classes.LandingNumbersDataContainer}>
            <div className={classes.LandingNumbersImpressiveNumber}>80%</div>
            <div><span className={classes.Underline}>money saved</span> compared to market places for mockups</div>
          </div>
          <div className={classes.LandingNumbersDataContainer}>
            <div className={classes.LandingNumbersImpressiveNumber}>86%</div>
            <div>of designers had an <span className={classes.Underline}>increase of sales</span> due to matching mockups</div>
          </div>
          <div className={classes.LandingNumbersDataContainer}>
            <div className={classes.LandingNumbersImpressiveNumber}>90%</div>
            <div>of designers <span className={classes.Underline}>increase likes</span> when using mockups</div>
          </div>
        </div>
      </div>
      <div className={`${landingPageClasses.LandingBody}`}>
        <div className={classes.LandingSubscribeBody}>
          { renderActionButton() }
        </div>
      </div>
    </section>
  )
}
