import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

const theme: ThemeOptions = {
  palette: {
    type: "dark",
    background: {
      default: '#FFFFFF',
      paper: '#404040',
    },
    primary: {
      main: '#6884F9',
    },
    secondary: {
      main: '#EA4D27',
    },
    text: {
      primary: '#E4E1E1',
    }
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiSelect: {
      root: {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
      icon: {
        top: "calc(50% - 10px)",
      }
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: '4px',
        paddingBottom: '4px',
      }
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: 'Noto sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  }
};

export default theme;