import React from 'react';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

import { ParentObject } from '../ParentObject/ParentObject';
import { SceneLight } from 'features/scenes/types';

interface ParentLightObjectProps {
  light: SceneLight,
  isSelected?: boolean,
  isVisible?: boolean,
  showChevron?: boolean,
  showChildren?: boolean,
  onClick: () => void,
  onVisibilityChange?: (objectSceneId: string, isVisible: boolean) => void,
}

export function ParentLightObject(props: ParentLightObjectProps) {
  const {
    light,
    isVisible = true,
    isSelected,
    showChevron,
    showChildren,
    onClick = () => {},
    onVisibilityChange = (objectSceneId: string, isVisible: boolean) => {},
  } = props;

  const handleVisibilityChange = (isVisible: boolean) => {
    onVisibilityChange(light.id, isVisible);
  }

  return (
    <ParentObject
      name={light.label}
      icon={<EmojiObjectsIcon/>}
      isSelected={isSelected}
      isVisible={isVisible}
      showChevron={showChevron}
      showChildren={showChildren}
      onClick={onClick}
      onVisibilityClick={handleVisibilityChange}
    />
  );
}
