import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  SaveDialog: {
  },
  SavePreviewImageContainer: {
    height: '300px',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  SavePreviewImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
}));
