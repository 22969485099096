import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  LandingCallToAction: {
    color: '#121212',
    padding: `${theme.spacing(10)}px ${theme.spacing(10)}px 0px ${theme.spacing(10)}px`,
  },
  LandingSubscribeBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  SubscribeHeading: {
    fontSize: '3rem',
    textAlign: 'center',
    lineHeight: 1,
  },
  ActionButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    fontSize: '1rem',
    background: 'linear-gradient(45deg,#e08255 0,#fb5b5b 100%)',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  LandingNumbers: {
    color: '#121212',
    padding: `${theme.spacing(10)}px ${theme.spacing(10)}px 0px ${theme.spacing(10)}px`,
  },
  LandingNumbersBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  LandingNumbersDataContainer: {
    padding: theme.spacing(4),
    fontSize: '1rem',
    width: '250px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  LandingNumbersImpressiveNumber: {
    fontSize: '4rem',
    color: '#ff6c0e',
    fontWeight: 'bold',
  },
  Underline: {
    backgroundImage: 'linear-gradient(120deg,#ff9958,#ff9958)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 0.3em',
    backgroundPosition: '0 88%',
  },
}));
