import { addObjectFinished, addObjectInProgress, removeObjectFinished, removeObjectInProgress, updateObjectFinished } from "features/scenes/scenes";
import { SceneHelper } from "modules/ThreeManager/helper/SceneHelper";
import store from "store";
import { ModelStateUpdateMessage, StateUpdateMode } from "../Manager/SceneManagerAbstract";
import { StateUpdateHandlerAbstract } from "./StateUpdateHandlerAbstract";

export class ModelStateUpdateHandler extends StateUpdateHandlerAbstract<ModelStateUpdateMessage> {
  emit(message: ModelStateUpdateMessage) {
    let { threeObjectId = -1, storeObjectId = '' } = message.payload;

    if (!storeObjectId) {
      storeObjectId = this.convertThreeObjectIdToStateObjectId(message.sceneId, threeObjectId);
    }
    switch(message.stateUpdate) {
      case StateUpdateMode.ObjectCreationInProgress: store.dispatch(addObjectInProgress(message.sceneId, storeObjectId)); break;
      case StateUpdateMode.ObjectCreated: store.dispatch(addObjectFinished(message.sceneId, storeObjectId, threeObjectId)); break;
      case StateUpdateMode.ObjectUpdated: store.dispatch(updateObjectFinished(message.sceneId, storeObjectId)); break;
      case StateUpdateMode.ObjectDeletionInProgress: store.dispatch(removeObjectInProgress(message.sceneId, storeObjectId)); break;
      case StateUpdateMode.ObjectDeleted: store.dispatch(removeObjectFinished(message.sceneId, storeObjectId)); break;
    }
  }

  private convertThreeObjectIdToStateObjectId(sceneId: string, threeObjectId: number) {
    const scene = store.getState().scenes.scenes.find(scene => scene.id === sceneId);
    if (!scene?.data) return '';

    return new SceneHelper(scene.data).getSceneObjectByThreeId(threeObjectId)?.id || '';
  }
}

