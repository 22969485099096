import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StarIcon from '@material-ui/icons/Star';

import useStyles from './AccountInfoPopover.styles';

interface AccountInfoPopoverProps {
  id: string | undefined,
  anchorEl: HTMLDivElement | null,
  email: string,
  showUpgradeButton: boolean,
  onAccountPanelClose: () => void,
  onUpgradeClick: () => void,
  onLogoutClick: () => void,
}

export function AccountInfoPopover(props: AccountInfoPopoverProps) {
  const classes = useStyles(useTheme());

  const {
    id,
    anchorEl,
    email,
    showUpgradeButton,
    onAccountPanelClose,
    onUpgradeClick,
    onLogoutClick,
  } = props;

  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onAccountPanelClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.AccountInfoPopover}>
        <div className={classes.AccountInfo}>
          <div className={classes.AccountBadge}>
            { email.slice(0,1).toUpperCase() }
          </div>
          <span className={classes.AccountEmail}>
            { email }
          </span>
        </div>
        <div className={classes.AccountButtonList}>
          <ul>
            {
              showUpgradeButton &&
              <li onClick={onUpgradeClick}>
                <StarIcon />
                Upgrade
              </li>
            }
            <li onClick={onLogoutClick}>
              <ExitToAppIcon />
              Logout
            </li>
          </ul>
        </div>
      </div>
    </Popover>
  );
}
