import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  NoMobileSupport: {
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(4),
    zIndex: 1000,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  NoMobileSupportImage: {
    maxWidth: '400px',
    width: '80%',
    marginBottom: theme.spacing(3),
  }
}));
