import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link } from 'react-router-dom';

import useStyles from './TemplateSceneCard.styles';
import { TemplateScene } from 'features/templateScenes/types';
import TemplateSceneApi from 'shared/utils/api/templateScene';

interface TemplateSceneCardProps {
  templateScene: TemplateScene,
  onOpenSceneClick: () => void,
  onDetailsClick: () => void,
}

export function TemplateSceneCard(props: TemplateSceneCardProps) {
  const {
    templateScene,
  } = props;
  const classes = useStyles(useTheme());

  return (
    <Link className={classes.TemplateSceneCard} to={`/mockupcreator/scene/${templateScene.slug}`}>
      <div className={classes.TemplateSceneCardImageContainer}>
        <img
          alt=""
          className={classes.TemplateSceneCardImage}
          src={new TemplateSceneApi().createTemplateScenePreviewThumbImageUrl(templateScene.slug)}
        />
        <Link className={classes.Info} to={`/mockupscenes/${templateScene.slug}`}>
          <InfoOutlinedIcon/>
        </Link>
      </div>
      <div className={classes.TemplateSceneCardTitle}>
        {templateScene.name}
      </div>
    </Link>
  );
}
