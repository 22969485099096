import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TextureIcon from '@material-ui/icons/Texture';
import ImageIcon from '@material-ui/icons/Image';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';

import { Sidebar } from 'shared/components/Sidebar';
import { Tabs } from 'shared/components/Tabs';
import useStyles from './MappingEditorLeftSidebar.styles';
import { ImageEditState } from 'features/imageEdit/types';
import { ImageLayerSelection } from '../ImageLayerSelection';
import { Separator } from 'shared/components/Separator';
import { UnsplashGallery } from 'shared/components/UnsplashGallery';

interface MappingEditorLeftSidebarProps {
  imageEditState: ImageEditState,
  createText: () => void,
  createCircle: () => void,
  drawRectangle: () => void,
  onAddImage: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onAddUnsplashToMapper: (imageUrl: string) => void,
  handleAddPattern: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleBackToSceneClick: () => void,
  handleMoveUp: (objectId: string) => void,
  handleMoveDown: (objectId: string) => void,
  handleImageLayerClick: (objectId: string) => void,
}

export function MappingEditorLeftSidebar(props: MappingEditorLeftSidebarProps) {
  const {
    imageEditState,
    createText,
    createCircle,
    drawRectangle,
    onAddImage,
    onAddUnsplashToMapper,
    handleAddPattern,
    handleBackToSceneClick,
    handleMoveUp,
    handleMoveDown,
    handleImageLayerClick,
  } = props;
  const classes = useStyles(useTheme());

  return (
    <Sidebar>
      <div className={classes.BackButton}>
        <Button
          variant="contained"
          color="primary"
          className={classes.BackToSceneButton}
          startIcon={<ArrowBackIcon />}
          onClick={handleBackToSceneClick}
        >
          Back to Scene
        </Button>
      </div>
      <div className={classes.ToolSelection}>
        <Tabs labels={['Elements', 'Unsplash']} class={classes.MappingEditorUpperTab}>
          <div className={classes.Tools}>
            <div className={classes.ToolsButton} onClick={createText}>
              <TextFieldsIcon />
              <span className={classes.ToolText}>Text Field</span>
            </div>
            <div className={classes.ToolsButton} onClick={createCircle}>
              <RadioButtonUncheckedIcon />
              <span className={classes.ToolText}>Circle</span>
            </div>
            <div className={classes.ToolsButton} onClick={drawRectangle}>
              <CheckBoxOutlineBlankIcon />
              <span className={classes.ToolText}>Rectangle</span>
            </div>
            <input
              accept="image/jpeg, image/png"
              id="uploadImageDesign"
              type="file"
              className={classes.uploadPatternDesign}
              onChange={onAddImage}
            />
            <label htmlFor="uploadImageDesign" className={classes.UploadLabel}>
              <div className={classes.ToolsButton}>
                <ImageIcon />
                <span className={classes.ToolText}>Image</span>
              </div>
            </label>
            
            <input
              accept="image/jpeg, image/png"
              id="uploadPatternDesign"
              type="file"
              className={classes.uploadPatternDesign}
              onChange={handleAddPattern}
            />
            <label htmlFor="uploadPatternDesign" className={classes.UploadLabel}>
              <div className={classes.ToolsButton}>
                <TextureIcon />
                <span className={classes.ToolText}>Repeated Pattern</span>
              </div>
            </label>
          </div>
          <div>
            <UnsplashGallery onAddUnsplashToMapper={onAddUnsplashToMapper} />
          </div>
        </Tabs>
      </div>
      <Separator />
      <div className={classes.LayerSelection}>
        <Tabs labels={['Image Layers']}>
          <div className={classes.FabricImageLayer}>
            <ImageLayerSelection
              imageEditObjects={imageEditState.objects}
              selectedImageObject={imageEditState.selectedObjectId}
              onHandleMoveUp={(objectId) => { handleMoveUp(objectId) }}
              onHandleMoveDown={(objectId) => { handleMoveDown(objectId) }}
              onImageLayerClick={(objectId) => { handleImageLayerClick(objectId) }}
            />
          </div>
        </Tabs>
      </div>
    </Sidebar>
  );
}
