import { Scene3d } from 'features/scenes/types';
import { TemplateScene } from 'features/templateScenes/types';
import ApiHelper from './api';

export default class TemplateSceneApi extends ApiHelper {
  private endpoint = '/templatescene';

  public createTemplateScenePreviewImageUrl(slug: string) {
    return `${this.baseUrl}${this.endpoint}/load/previewimage/regular/${slug}`;
  }

  public createTemplateScenePreviewThumbImageUrl(slug: string) {
    return `${this.baseUrl}${this.endpoint}/load/previewimage/thumb/${slug}`;
  }

  public loadTemplateScene3dData(slug: string): Promise<Scene3d> {
    return this.get(`${this.baseUrl}${this.endpoint}/load/scene/3d/${slug}`);
  }

  public loadTemplateSceneMetaData(slug: string): Promise<TemplateScene> {
    return this.get(`${this.baseUrl}${this.endpoint}/load/scene/meta/${slug}`);
  }

  public saveTemplateScene(name: string, sceneData: Scene3d, sceneImage: string, selectedModelId: string, selectedModelChildId: string) {
    return this.post(`${this.baseUrl}${this.endpoint}/save`,
      {
        name,
        sceneData,
        sceneImage,
        selectedModelId,
        selectedModelChildId,
      });
  }

  public getAll() {
    return this.get(`${this.baseUrl}${this.endpoint}/all`);
  }
}