import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from 'store';
import { Scenes, Scene3d } from './types';
import { SceneHelper } from 'modules/ThreeManager/helper/SceneHelper';
import cloneDeep from 'lodash.clonedeep';
import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';
import { createDefaultState, getLightByStoreId, getModelByStoreId, getObjectByStoreId, getScene, mergeSceneState, setAllObjectStates } from './scenesHelper';
import { changeSelectedChildObject, changeSelectedObject } from 'features/ui/ui';
import { ObjectStatus } from "modules/ThreeManager/Models/enum/ObjectStatus";
import { Vector3d } from 'modules/ThreeManager/Models/Vector3d';
import { TextureType } from 'modules/ThreeManager/Models/enum/TextureType';
import { ObjectPattern } from 'modules/ThreeManager/Models/ObjectPattern';
import { Mapping } from 'modules/ThreeManager/Models/Mapping';
import TemplateSceneApi from 'shared/utils/api/templateScene';
import { Model } from 'modules/ThreeManager/Models/Model';
import TextureApi from 'shared/utils/api/texture';
import ModelApi from 'shared/utils/api/model';

const defaultMapping = { };
const defaultPattern = { };

const initialState: Scenes = {
  scenes: []
};

const modelsSlice = createSlice({
  name: 'Scenes',
  initialState,
  reducers: {
    fetchSceneRequestSlice(state, action: PayloadAction<{ sceneId: string }>) {
      const { sceneId } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      scene.loading = true;
    },
    fetchSceneFailedSlice(state, action: PayloadAction<{ sceneId: string }>) {
      const { sceneId } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      scene.loading = false;
    },
    resetSceneSlice(state, action: PayloadAction<{ sceneId: string }>) {
      const { sceneId } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      setAllObjectStates(scene.data.lights, ObjectStatus.DeletionRequested);
      setAllObjectStates(scene.data.models, ObjectStatus.DeletionRequested);
      setAllObjectStates(scene.data.primitives, ObjectStatus.DeletionRequested);
      const newScene = mergeSceneState(createDefaultState(), scene);
      scene.data = newScene.data;
      scene.loading = false;
    },
    deleteSceneSlice(state, action: PayloadAction<{ sceneId: string }>) {
      const { sceneId } = action.payload;
      state.scenes = state.scenes.filter(scene =>scene.id !== sceneId);
    },
    loadSceneSlice(state, action: PayloadAction<{ sceneId: string, scene: Scene3d } >) {
      const { sceneId, scene } = action.payload;
      const currentScene = getScene(sceneId, state.scenes);
      setAllObjectStates(currentScene.data.lights, ObjectStatus.DeletionRequested);
      setAllObjectStates(currentScene.data.models, ObjectStatus.DeletionRequested);
      setAllObjectStates(currentScene.data.primitives, ObjectStatus.DeletionRequested);
      const newScene = mergeSceneState(currentScene, { id: currentScene.id, loading: false, data: scene});
      currentScene.data.cameraSetting = scene.cameraSetting;
      currentScene.data = newScene.data;
      currentScene.loading = false;
    },
    recreateAllObjectsSlice(state, action: PayloadAction<{ sceneId: string } >) {
      const { sceneId } = action.payload;
      const foundScene = getScene(sceneId, state.scenes);
      if (foundScene) {
        setAllObjectStates(foundScene.data.lights, ObjectStatus.CreationRequested);
        setAllObjectStates(foundScene.data.models, ObjectStatus.CreationRequested);
        setAllObjectStates(foundScene.data.primitives, ObjectStatus.CreationRequested);
      }
    },
    addModelSlice(state, action: PayloadAction<{ sceneId: string, sceneModel: SceneModel }>) {
      const { sceneId, sceneModel } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      sceneModel.status = ObjectStatus.CreationRequested;
      scene.data.models.push(sceneModel);
    },
    changeObjectStatusSlice(state, action: PayloadAction<{ sceneId: string, storeObjectId: string, newStatus: ObjectStatus }>) {
      const { sceneId, storeObjectId, newStatus } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundObject = getObjectByStoreId(scene, storeObjectId);
      if (foundObject) foundObject.status = newStatus;
    },
    removeObjectSlice(state, action: PayloadAction<{ sceneId: string, storeObjectId: string }>) {
      const { sceneId, storeObjectId } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      scene.data.models = scene.data.models.filter((model) => model.id !== storeObjectId);
      scene.data.lights = scene.data.lights.filter((light) => light.id !== storeObjectId);
      scene.data.primitives = scene.data.primitives.filter((prim) => prim.id !== storeObjectId);
    },
    updateObjectWithThreeObjectIdSlice(state, action: PayloadAction<{ sceneId: string, storeObjectId: string, threeObjectId: number }>) {
      const { sceneId, storeObjectId, threeObjectId } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundObject = getObjectByStoreId(scene, storeObjectId);
      if (foundObject) foundObject.threeObjectId = threeObjectId;
    },
    changeObjectPositionSlice(state, action: PayloadAction<{sceneId: string, storeObjectId: string, newPosition: Vector3d}>) {
      const { sceneId, storeObjectId, newPosition } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundObject = getObjectByStoreId(scene, storeObjectId);
      if (foundObject) foundObject.position = newPosition;
    },
    changeObjectScaleSlice(state, action: PayloadAction<{sceneId: string, storeObjectId: string, newScale: Vector3d}>) {
      const { sceneId, storeObjectId, newScale } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundObject = getObjectByStoreId(scene, storeObjectId);
      if (foundObject) foundObject.scale = newScale;
    },
    changeObjectRotationSlice(state, action: PayloadAction<{sceneId: string, storeObjectId: string, newRotation: Vector3d}>) {
      const { sceneId, storeObjectId, newRotation } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundObject = getObjectByStoreId(scene, storeObjectId);
      if (foundObject) foundObject.rotation = newRotation;
    },
    changeModelPropertyTypeSlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, textureType: TextureType, objectMaterialName: string}>) {
      const { sceneId, storeModelId, textureType, objectMaterialName } = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].selectedTextureType = textureType;
      }
    },
    changeModelColorPropertySlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, newColor: string, objectMaterialName: string}>) {
      const {sceneId, storeModelId, newColor, objectMaterialName} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].color = newColor;
      }
    },
    changeModelPatternPropertySlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, textureUri: string, objectMaterialName: string}>) {
      const {sceneId, storeModelId, textureUri, objectMaterialName} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        const pattern: ObjectPattern = foundModel.objectMaterials[objectMaterialName].pattern || defaultPattern;
        pattern.textureUri = textureUri;
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].pattern = pattern;
      }
    },
    changeModelPatternRepeatSlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, repeat: number, objectMaterialName: string}>) {
      const {sceneId, storeModelId, repeat, objectMaterialName} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        const pattern: ObjectPattern = foundModel.objectMaterials[objectMaterialName].pattern || defaultPattern;
        pattern.repeat = repeat;
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].pattern = pattern;
      }
    },
    changeModelStandardMaterialPropertySlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, key: string, value: number, objectMaterialName: string}>) {
      const {sceneId, storeModelId, key, value, objectMaterialName} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        const standardMaterial: THREE.MeshStandardMaterialParameters = foundModel.objectMaterials[objectMaterialName].property || {};
        (standardMaterial as any)[key] = value;
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].property = standardMaterial;
      }
    },
    changeModelPhongMaterialPropertySlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, key: string, value: number, objectMaterialName: string}>) {
      const {sceneId, storeModelId, key, value, objectMaterialName} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        const phongMaterial: THREE.MeshPhongMaterialParameters = foundModel.objectMaterials[objectMaterialName].property || {};
        (phongMaterial as any)[key] = value;
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].property = phongMaterial;
      }
    },
    changeCameraPositionSlice(state, action: PayloadAction<{sceneId: string, position: Vector3d, lookAt: Vector3d}>) {
      const {sceneId, position, lookAt} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      scene.data.cameraSetting.position = position;
      scene.data.cameraSetting.lookAt = lookAt;    
    },
    setObjectLoadingStateSlice(state, action: PayloadAction<{sceneId: string, storeObjectId: string, isLoading: boolean}>) {
      const {sceneId, storeObjectId, isLoading} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundObject = getObjectByStoreId(scene, storeObjectId);
      if (foundObject) foundObject.isLoading = isLoading;
    },
    setObjectVisibilitySlice(state, action: PayloadAction<{sceneId: string, storeObjectId: string, isVisible: boolean}>) {
      const {sceneId, storeObjectId, isVisible} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundObject = getObjectByStoreId(scene, storeObjectId);
      if (foundObject) {
        foundObject.status = ObjectStatus.ChangeRequested;
        foundObject.visible = isVisible;
      }
    },
    changeLightColorSlice(state, action: PayloadAction<{sceneId: string, storeObjectId: string, newColor: string}>) {
      const {sceneId, storeObjectId, newColor} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundLight = getLightByStoreId(scene, storeObjectId);
      if (foundLight) {
        foundLight.status = ObjectStatus.ChangeRequested;
        foundLight.property.color = newColor;
      }
    },
    changeLightIntensitySlice(state, action: PayloadAction<{sceneId: string, storeObjectId: string, newIntensity: number}>) {
      const {sceneId, storeObjectId, newIntensity} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundLight = getLightByStoreId(scene, storeObjectId);
      if (foundLight) {
        foundLight.status = ObjectStatus.ChangeRequested;
        foundLight.property.intensity = newIntensity;
      }
    },
    changeMappingUrlSlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, newUri: string, objectMaterialName: string}>) {
      const {sceneId, storeModelId, newUri, objectMaterialName} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        const mapping: Mapping = foundModel.objectMaterials[objectMaterialName].mapping || defaultMapping;
        mapping.textureUri = newUri;
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].mapping = mapping;
      }
    },
    changeMappingRawDataSlice(state, action: PayloadAction<{sceneId: string, storeModelId: string, fabricObjects: fabric.Object[], objectMaterialName: string}>) {
      const {sceneId, storeModelId, fabricObjects, objectMaterialName} = action.payload;
      const scene = getScene(sceneId, state.scenes);
      const foundModel = getModelByStoreId(scene, storeModelId);
      if (foundModel) {
        const mapping: Mapping = foundModel.objectMaterials[objectMaterialName].mapping || defaultMapping;
        mapping.rawData = fabricObjects;
        foundModel.status = ObjectStatus.ChangeRequested;
        foundModel.objectMaterials[objectMaterialName].mapping = mapping;
      }
    },
  }
});

const {
  fetchSceneRequestSlice,
  fetchSceneFailedSlice,
  resetSceneSlice,
  deleteSceneSlice,
  recreateAllObjectsSlice,
  loadSceneSlice,
  addModelSlice,
  changeObjectStatusSlice,
  removeObjectSlice,
  updateObjectWithThreeObjectIdSlice,
  changeObjectPositionSlice,
  changeObjectScaleSlice,
  changeObjectRotationSlice,
  changeModelPropertyTypeSlice,
  changeModelColorPropertySlice,
  changeModelPatternPropertySlice,
  changeModelPatternRepeatSlice,
  changeModelStandardMaterialPropertySlice,
  changeModelPhongMaterialPropertySlice,
  changeCameraPositionSlice,
  setObjectLoadingStateSlice,
  setObjectVisibilitySlice,
  changeLightColorSlice,
  changeLightIntensitySlice,
  changeMappingUrlSlice,
  changeMappingRawDataSlice,
} = modelsSlice.actions;

const addStructuralTextureToModel = async (model: Model) => {
  if (!model.objectMaterials) return;
  for (let objectMaterial of Object.values(model.objectMaterials)) {
    if (objectMaterial.structuralTextureId) {
      objectMaterial.structuralTextures = await new TextureApi().getStructuralTexture(objectMaterial.structuralTextureId);
    }
  }
} 

export const addModelRequestByModel = (sceneId: string, originalModel: Model): AppThunk => async (dispatch: AppDispatch, getState) => {
    const model = cloneDeep(originalModel);
    await addStructuralTextureToModel(model);
    const sceneModel = SceneHelper.convertInputModelToSceneModel(model);
    sceneModel.status = ObjectStatus.CreationInProgress;
    dispatch(addModelSlice({ sceneId, sceneModel }));
    dispatch(changeSelectedObject(sceneModel.id));
}

export const addModelRequestById = (sceneId: string, modelDatabaseId: string): AppThunk => async (dispatch: AppDispatch, getState) => {
  const originalModel = getState().models.data.find(model => model._id === modelDatabaseId);
  if (originalModel) {
    dispatch(addModelRequestByModel(sceneId, originalModel));
  }
}

export const addModelRequestWithSceneModel = (sceneId: string, sceneModelOriginal: SceneModel): AppThunk => async (dispatch: AppDispatch, getState) => {
  const sceneModel = cloneDeep(sceneModelOriginal);
  sceneModel.status = ObjectStatus.CreationInProgress;
  dispatch(addModelSlice({ sceneId, sceneModel }));
}

export const addObjectInProgress = (sceneId: string, storeObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectStatusSlice({ sceneId, storeObjectId, newStatus: ObjectStatus.CreationInProgress }));
}

export const addObjectFinished = (sceneId: string, storeObjectId: string, threeObjectId: number): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectStatusSlice({ sceneId, storeObjectId, newStatus: ObjectStatus.Ready }));
  dispatch(updateObjectWithThreeObjectIdSlice({ sceneId, storeObjectId, threeObjectId }));
}

export const removeObjectRequest = (sceneId: string, storeObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectStatusSlice({ sceneId, storeObjectId, newStatus: ObjectStatus.DeletionRequested }));
}

export const removeSelectedObjectRequest = (sceneId: string): AppThunk => async (dispatch: AppDispatch, getState) => {
  const selectedObjectId = getState().ui.selectedObjectSceneId || '';
  if (!selectedObjectId) return;
  dispatch(removeObjectRequest(sceneId, selectedObjectId));
}

export const removeObjectInProgress = (sceneId: string, storeObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectStatusSlice({ sceneId, storeObjectId, newStatus: ObjectStatus.DeletionInProgress }));
}

export const updateObjectFinished = (sceneId: string, storeObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectStatusSlice({ sceneId, storeObjectId, newStatus: ObjectStatus.Ready }));
}

export const removeObjectFinished = (sceneId: string, storeObjectId: string): AppThunk => async (dispatch: AppDispatch, getState) => {
  if (storeObjectId === '') return;
  dispatch(removeObjectSlice({ sceneId, storeObjectId }));
  const selectedObjectId = getState().ui.selectedObjectSceneId || '';
  if (!selectedObjectId) return;
  dispatch(changeSelectedObject(''));
}

export const changeObjectPosition = (sceneId: string, storeObjectId: string, newPosition: Vector3d): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectPositionSlice({sceneId, storeObjectId, newPosition}));
}

export const changeObjectScale = (sceneId: string, storeObjectId: string, newScale: Vector3d): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectScaleSlice({sceneId, storeObjectId, newScale}));
}

export const changeObjectRotation = (sceneId: string, storeObjectId: string, newRotation: Vector3d): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeObjectRotationSlice({sceneId, storeObjectId, newRotation}));
}

export const resetScene = (sceneId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(resetSceneSlice({sceneId}));
}

export const deleteScene = (sceneId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(deleteSceneSlice({sceneId}));
}

export const setObjectLoadingState = (sceneId: string, storeObjectId: string, isLoading: boolean): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setObjectLoadingStateSlice({sceneId, storeObjectId, isLoading}));
}

export const setObjectVisibilityState = (sceneId: string, storeObjectId: string, isVisible: boolean): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setObjectVisibilitySlice({sceneId, storeObjectId, isVisible}));
}

export const changeSceneLightColor = (sceneId: string, storeObjectId: string, newColor: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeLightColorSlice({sceneId, storeObjectId, newColor}));
}

export const changeSceneLightIntensity = (sceneId: string, storeObjectId: string, newIntensity: number): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeLightIntensitySlice({sceneId, storeObjectId, newIntensity}));
}

export const changeSceneMappingUrl = (sceneId: string, storeModelId: string, newUri: string): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeMappingUrlSlice({sceneId, storeModelId, newUri, objectMaterialName}));
}

export const changeSceneMappingRawData = (sceneId: string, storeModelId: string, fabricObjects: fabric.Object[] = []): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeMappingRawDataSlice({sceneId, storeModelId, fabricObjects, objectMaterialName}));
}

export const changeModelPropertyType = (sceneId: string, storeModelId: string, textureType: TextureType): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelPropertyTypeSlice({sceneId, storeModelId, textureType, objectMaterialName}));
}

export const changeModelColorProperty = (sceneId: string, storeModelId: string, newColor: string): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelColorPropertySlice({sceneId, storeModelId, newColor, objectMaterialName}));
}

export const changeModelPatternProperty = (sceneId: string, storeModelId: string, textureUri: string): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelPatternPropertySlice({sceneId, storeModelId, textureUri, objectMaterialName}));
}

export const changeModelPatternRepeat = (sceneId: string, storeModelId: string, repeat: number): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelPatternRepeatSlice({sceneId, storeModelId, repeat, objectMaterialName}));
}

export const changeModelStandardMaterialRoughness = (sceneId: string, storeModelId: string, roughness: number): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelStandardMaterialPropertySlice({sceneId, storeModelId, key: 'roughness', value: roughness, objectMaterialName}));
}

export const changeModelStandardMaterialMetalness = (sceneId: string, storeModelId: string, metalness: number): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelStandardMaterialPropertySlice({sceneId, storeModelId, key: 'metalness', value: metalness, objectMaterialName}));
}

export const changeModelPhongMaterialShininess = (sceneId: string, storeModelId: string, shininess: number): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelPhongMaterialPropertySlice({sceneId, storeModelId, key: 'shininess', value: shininess, objectMaterialName}));
}

export const changeModelPhongMaterialReflectivity = (sceneId: string, storeModelId: string, reflectivity: number): AppThunk => async (dispatch: AppDispatch, getState) => {
  const objectMaterialName = getState().ui.selectedChildObjectName || '';
  dispatch(changeModelPhongMaterialPropertySlice({sceneId, storeModelId, key: 'reflectivity', value: reflectivity, objectMaterialName}));
}

export const changeCameraPosition = (sceneId: string, cameraPosition: Vector3d, lookAt: Vector3d): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(changeCameraPositionSlice({sceneId, position: cameraPosition, lookAt}));
}

export const recreateAllObjects = (sceneId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(recreateAllObjectsSlice({sceneId}));
}

export const loadScene = (sceneId: string, slug: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchSceneRequestSlice({sceneId}));
  try {
    const sceneMetaData = await new TemplateSceneApi().loadTemplateSceneMetaData(slug);
    if (sceneMetaData.selectedModelId && sceneMetaData.selectedModelChildId) {
      dispatch(changeSelectedObject(sceneMetaData.selectedModelId));
      dispatch(changeSelectedChildObject(sceneMetaData.selectedModelChildId));
    }
    const scene3dData = await new TemplateSceneApi().loadTemplateScene3dData(slug);
    dispatch(loadSceneSlice({sceneId, scene: scene3dData}));
    dispatch(recreateAllObjectsSlice({sceneId}));
  } catch(e) {
    dispatch(fetchSceneFailedSlice({sceneId}));
  }
}

export const loadModelBySlug = (sceneId: string, slug: string): AppThunk => async (dispatch: AppDispatch) => {
  try {
    const modelData = await new ModelApi().getBySlug(slug);
    dispatch(addModelRequestByModel(sceneId, modelData));
    dispatch(recreateAllObjectsSlice({sceneId}));
  } catch(e) {
    dispatch(fetchSceneFailedSlice({sceneId}));
  }
}

export default modelsSlice.reducer;
