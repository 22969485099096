import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Login.styles';
import useParentStyles from '../LoginDialog.styles';
import { TextField } from '@material-ui/core';
import ActionButton from 'shared/components/ActionButton/ActionButton';
import { CircularSpinner } from 'shared/components/CircularSpinner';
import Logo from 'shared/assets/images/mockupfox_logo_black.svg';
import Pattern from 'shared/assets/images/pattern_background.svg';
import GoogleLogin from 'react-google-login';

export interface LoginProps {
  isLoading: boolean,
  hasError: string,
  onLoginClick: (email: string, password: string) => void;
  onGoogleLoginClick: (tokenId: string) => void;
  onChange: (email: string, password: string) => void;
  onSwitchToRegisterClick: () => void;
  onSwitchToResetPasswordClick: () => void;
}

export function Login(props: LoginProps) {
  const [ email, setEmail ] = React.useState('');
  const [ password, setPassword ] = React.useState('');

  const {
    isLoading,
    hasError,
    onChange,
    onLoginClick,
    onGoogleLoginClick,
    onSwitchToRegisterClick,
    onSwitchToResetPasswordClick
  } = props;
  
  const classes = useStyles(useTheme());
  const parentClasses = useParentStyles(useTheme());

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    onChange(newEmail, password);
  }

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
    onChange(email, newPassword);
  }

  const handleGoogleLogin = async (googleData: any) => {
    onGoogleLoginClick(googleData.tokenId);
  }

  const disableLoginButton = () => {
    if (isLoading) return true;
    if (email.length < 6 || password.length < 6) return true;
    return false;
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !disableLoginButton()) {
      onLoginClick(email, password);
    }
  }

  return (
    <div className={parentClasses.LoginDialog}>
            <div className={parentClasses.Pattern} style={{ 'backgroundImage': `url(${Pattern})` }}></div>

      <div className={parentClasses.LoginContent}>
        <img alt="" className={classes.Logo} src={Logo} />
        <div className={parentClasses.LoginDialogTitle}>
          Login to your account
        </div>
        <div className={parentClasses.LoginDialogBody}>
          <div className={classes.SSO}>
            <GoogleLogin
              className={classes.GoogleLogin}
              clientId={'850810151731-77nqhsm9db24sob5po4fpapmhrj2sis7.apps.googleusercontent.com'}
              buttonText="Log in with Google"
              onSuccess={handleGoogleLogin}
              onFailure={handleGoogleLogin}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          <div className={classes.OrText}>
            or
          </div>
          <TextField
            className={classes.LoginInput}
            label="Your Email"
            value={email}
            onChange={(event) => handleEmailChange(event.target.value)}
            onKeyDown={handleKeyDown}
          ></TextField>
          <TextField
            className={classes.LoginInput}
            label="Your Password"
            type="password"
            value={password}
            onChange={(event) => handlePasswordChange(event.target.value)}
            onKeyDown={handleKeyDown}
          ></TextField>
          <div className={parentClasses.LoginDialogButtonBar}>
            <ActionButton size="large" disabled={disableLoginButton()} onClick={() => onLoginClick(email, password)} fullWidth={true}>
              <span className={parentClasses.ButtonSpinner}>
                {isLoading && <CircularSpinner size={15}/>}
              </span>
              Login
            </ActionButton>
          </div>
          { 
            hasError &&
            <div className={parentClasses.LoginError}>{hasError}</div>
          }
          <div className={classes.NotRegisteredYetContainer}>
            <div className={classes.ForgotYourPasswordLabel}>
              <span className={parentClasses.LoginDialogLink} onClick={onSwitchToResetPasswordClick}>Forgot Password?</span>
            </div>
            <span className={parentClasses.LoginDialogLink} onClick={onSwitchToRegisterClick}>Not yet an account?</span>                
          </div>
        </div>
      </div>
    </div>
  );
}
