import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Dialog.styles';
import { CircularSpinner } from '../CircularSpinner';

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles(useTheme());

  return (
    <MuiDialogTitle disableTypography className={classes.DialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.CloseButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: '400px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface DialogButton {
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined,
  label: string,
  variant?: "text" | "outlined" | "contained",
  isLoading?: boolean,
  onClick: () => void,
}

export interface DialogProps {
  title?: string;
  children: React.ReactNode;
  isOpen: boolean;
  buttons?: DialogButton[];
  onCloseClick: () => void;
}

export function Dialog(props: DialogProps) {
  const { title, isOpen, children, buttons = [], onCloseClick } = props;

  return (
    <div>
      <MuiDialog onClose={onCloseClick} aria-labelledby="customized-dialog-title" open={isOpen}>
        {
          title &&
          <DialogTitle id="customized-dialog-title" onClose={onCloseClick}>
            { title }
          </DialogTitle>
        }
        <DialogContent dividers={!!title || !!buttons.length}>
          { children }
        </DialogContent>
        {
          buttons.length > 0 &&
          <DialogActions>
            {
              buttons.map(button => (
                <Button
                  autoFocus
                  onClick={button.onClick}
                  color={button.color || 'primary'}
                  variant={button.variant || 'contained'}
                  disabled={button.isLoading}
                  key={button.label}
                >
                  { button.isLoading && <CircularSpinner size={6} /> }
                  { button.label }
                </Button>
              ))
            }
          </DialogActions>
        }
      </MuiDialog>
    </div>
  );
}