import React from 'react';
import CategoryIcon from '@material-ui/icons/Category';

import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';
import { ParentObject } from '../ParentObject/ParentObject';
import { ChildObject } from '../ChildObject';
import { ObjectMaterial } from 'modules/ThreeManager/Models/ObjectMaterial';
import { TextureType } from 'modules/ThreeManager/Models/enum/TextureType';

interface ParentObjectProps {
  object: SceneModel,
  isSelected?: boolean,
  selectedSubObject?: string,
  isVisible?: boolean,
  showChildren?: boolean,
  isLoading?: boolean,
  onClick?: () => void,
  onChildClick?: (objectSceneId: string, childKey: string) => void,
  chevronClick?: (objectSceneId: string, expand: boolean) => void,
  onVisibilityChange?: (objectSceneId: string, isVisible: boolean) => void,
}

export function ParentModelObject(props: ParentObjectProps) {
  const {
    object,
    isVisible = true,
    isSelected,
    selectedSubObject = '',
    showChildren,
    isLoading = false,
    onClick = () => {},
    onChildClick = (objectSceneId: string, childKey: string) => {},
    chevronClick = (objectSceneId: string, expand: boolean) => {},
    onVisibilityChange = (objectSceneId: string, isVisible: boolean) => {},
  } = props;

  const getImage = (objectMaterial: ObjectMaterial) => {
    switch(objectMaterial.selectedTextureType) {
      case TextureType.Pattern: return objectMaterial.pattern?.textureUri;
      case TextureType.Mapping: return objectMaterial.mapping?.textureUri;
      default: return ''; 
    }
  }

  const getColor = (objectMaterial: ObjectMaterial) => {
    switch(objectMaterial.selectedTextureType) {
      case TextureType.Pattern: return '';
      case TextureType.Mapping: return '';
      default: return objectMaterial.color; 
    }
  }

  const handleVisibilityChange = (isVisible: boolean) => {
    onVisibilityChange(object.id, isVisible);
  }

  return (
    <>
      <ParentObject
        name={object.label}
        icon={<CategoryIcon/>}
        isSelected={isSelected}
        isVisible={isVisible}
        showChevron={object.objectMaterials && !!Object.entries(object.objectMaterials).length}
        showChildren={showChildren}
        isLoading={isLoading}
        onClick={onClick}
        chevronClick={() => chevronClick(object.id, !showChildren)}
        onVisibilityClick={handleVisibilityChange}
      />
      {
        showChildren && object.objectMaterials && Object.entries(object.objectMaterials).map(([key, value]) => {
          return <ChildObject
            name={value.name || key}
            key={value.name || key}
            image={getImage(value) || ''}
            color={getColor(value) || ''}
            isSelected={isSelected && selectedSubObject === key}
            onClick={() => onChildClick(object.id, key)}
          />
        })
      }
    </>
  );
}
