import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  LandingHero: {
    backgroundColor: '#FFF0DF',
    color: theme.palette.text.primary,
    height: '50vh',
    paddingBottom: theme.spacing(5),
  },
  LandingHeroBody: {
    height: '100%',
  },
  HeroHeadlineBody: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '4.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1.2,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0rem',
    },
  },
  HeroHeadline1: {
    color: '#FF6C0F',
  },
  HeroHeadline2: {
    color: '#121212',
  },
  SubheadLine: {
    color: '#8b8b8b',
    fontSize: '1.6rem',
    marginTop: theme.spacing(4),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.0rem',
    },
  },
  Underline: {
    backgroundImage: 'linear-gradient(120deg,#ff9958,#ff9958)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 0.3em',
    backgroundPosition: '0 88%',
  },
}));
