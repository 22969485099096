import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  RegisterInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  Logo: {
    height: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  NotRegisteredYetContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
}));
