import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ModelSimulatorWorkArea.styles';
import { Three } from 'modules/Simulator3d/components/Three';
import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';
import { PlaceDesignButton } from '../PlaceDesignButton';
import { ModelToolbar } from '../ModelToolbar';
import { ObjectEditOption, Ui } from 'features/ui/types';
import { SceneManager } from 'modules/ThreeManager/Manager/SceneManager';
import { FeedbackButton } from 'shared/components/FeedbackButton';

interface ModelSimulatorWorkAreaProps {
  ui: Ui,
  selectedModel?: SceneModel,
  isSceneEmpty: boolean,
  adminView?: boolean,
  onPlacePatternDesignClick: (base64Image: string) => void,
  onPlaceStandAloneDesignClick: (base64Image: string) => void,
  onNewScene: () => void,
  onSaveSceneClick: (imageUri: string) => void,
  onDeleteClick: () => void,
  onExportClick: (imageUri: string) => void,
  onEditOptionClick: (objectEditOption: ObjectEditOption) => void,
  onThreeManagerCreation: (sceneManager: SceneManager) => void,
}

export function ModelSimulatorWorkArea(props: ModelSimulatorWorkAreaProps) {
  const {
    ui,
    selectedModel,
    isSceneEmpty = true,
    adminView = false,
    onPlacePatternDesignClick,
    onPlaceStandAloneDesignClick,
    onNewScene,
    onSaveSceneClick,
    onDeleteClick,
    onExportClick,
    onEditOptionClick,
    onThreeManagerCreation,
  } = props;
  // eslint-disable-next-line
  const [ threeKey, _ ] = React.useState(ui.editorSceneId || `three_${new Date().getTime()}`);
  const [ sceneManager, setSceneManager ] = React.useState<SceneManager | null>(null);
  const classes = useStyles(useTheme());

  const handleSaveSceneClick = () => {
    if (!sceneManager) return;
    onSaveSceneClick(sceneManager.getSceneAsPngImage());
  }

  const handleExportClick = () => {
    if (!sceneManager) return;
    onExportClick(sceneManager.getSceneAsPngImage());
  }

  const handleThreeManagerCreation = (sceneManager: SceneManager) => {
    setSceneManager(sceneManager);
    onThreeManagerCreation(sceneManager);
  }

  return (
    <div id="workArea" className={classes.ModelSimulatorWorkArea}>
      <Three
        key={threeKey}
        keyProp={threeKey}
        onThreeManagerCreation={handleThreeManagerCreation}
      />
      {
        ui.selectedChildObjectName
        && selectedModel?.objectMaterials[ui.selectedChildObjectName]
        && selectedModel?.objectMaterials[ui.selectedChildObjectName].pattern
        && <PlaceDesignButton
          selectedModel={selectedModel}
          onPlaceStandAloneDesignClick={onPlaceStandAloneDesignClick}
          onPlacePatternDesignClick={onPlacePatternDesignClick}
        />
      }
      <div className={classes.FeedbackButtonContainer}>
        <FeedbackButton />
      </div>
      <ModelToolbar
        disableObjectTools={!selectedModel}
        selectedEditOption={ui.objectEditOption}
        adminView={adminView}
        onNewScene={onNewScene}
        onSaveSceneClick={handleSaveSceneClick}
        onDeleteClick={onDeleteClick}
        onEditOptionClick={onEditOptionClick}
        onExportClick={handleExportClick}
      />
      {
        isSceneEmpty &&
        <div className={classes.EmptySceneMessage}>
          The scene is empty. Select a model from the left sidebar.
        </div>
      }
    </div>
  );
}
