import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  PlaceYourDesignButton: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  PlaceDesignPopover: {
  },
  PlaceDesignButtonList: {
    '& ul': {
      paddingLeft: 0,
      '& li': {
        display: 'flex',
        alignItems: 'center',
        listStyle: 'none',
        height: '35px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: '100%',
        fontSize: '0.9rem',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '& svg': {
          marginRight: theme.spacing(1),
        },
      }
    }
  },
}));
