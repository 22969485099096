import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'rootReducer';
import useStyles from './MappingEditor.styles';
import { ImageEdit } from 'modules/MappingEditor/components/ImageEdit';
import { changeSceneMappingUrl, changeSceneMappingRawData } from 'features/scenes/scenes';
import { ImageManager } from 'modules/MappingEditor/utils/ImageManager/ImageManager';
import ModelApi from 'shared/utils/api/model';
import { openSimulator3d } from 'features/ui/ui';
import { addObject, modifyObject, moveObjectDown, moveObjectUp, setInitialImage } from 'features/imageEdit/imageEdit';
import { classToPlainObject } from 'modules/MappingEditor/utils/fabric.helper';
import { MappingEditorRightSidebar } from 'modules/MappingEditor/components/MappingEditorRightSidebar';
import { MappingEditorLeftSidebar } from 'modules/MappingEditor/components/MappingEditorLeftSidebar';
import { LoadingOverlay } from 'shared/components/LoadingOverlay';
import { SceneManager } from 'modules/ThreeManager/Manager/SceneManager';
import { SceneHelper } from 'modules/ThreeManager/helper/SceneHelper';

export function MappingEditor() {
  const dispatch = useDispatch();
  const [ isLoadingOverlayOpen, setIsLoadingOverlayOpen ] = React.useState(false);
  const [ sceneManager, setSceneManager ] = React.useState<SceneManager | null>(null);
  const { scenes, ui, imageEdit } = useSelector((state: RootState) => state);
  const classes = useStyles(useTheme());
  const stateRef = React.useRef<SceneManager | null>();
  stateRef.current = sceneManager;

  const scene = scenes.scenes.find(scene => scene.id === ui.editorSceneId);
  const selectedModel = new SceneHelper(scene?.data).getSceneModelById(ui.selectedObjectSceneId);
  const selectedModelMaterial = selectedModel?.objectMaterials[ui.selectedChildObjectName || ''];
  const backgroundUvImage = new ModelApi().createUvMapUrl(selectedModelMaterial?.mapping?.uvMap || '');
  const rawImageData = selectedModelMaterial?.mapping?.rawData as Array<fabric.Object> || [];

  React.useEffect(() => {
    if (imageEdit.initialImage) {
      ImageManager.getInstance().addImageWithImgUrl(imageEdit.initialImage);
      // the initial image was loaded, the state can be reset
      dispatch(setInitialImage(''));
    } else {
      rawImageData.forEach(object => dispatch(addObject(object)));
    }
  }, []);

  const handleImageChange = (imageBase64: string) => {
    dispatch(changeSceneMappingUrl(ui.editorSceneId, ui.selectedObjectSceneId, imageBase64));
    if (stateRef.current) {
      dispatch(changeSceneMappingUrl(stateRef.current.id, ui.selectedObjectSceneId, imageBase64));
    }
  }
  
  const handleBackToSceneClick = async () => {
    setIsLoadingOverlayOpen(true);
    const allObjects = ImageManager.getInstance().getAllObjects()?.map(object => classToPlainObject(object));
    dispatch(changeSceneMappingRawData(ui.editorSceneId, ui.selectedObjectSceneId, allObjects));
    handleImageChange(await ImageManager.getInstance().getBase64Image(2048));

    // Shame: without a delay the texture is not ready for rendering when returning to the main 3d view
    setTimeout(() => {
      dispatch(openSimulator3d());
    }, 1000);
  }

  const createText = () => ImageManager.getInstance().drawText();
  const createCircle = () => ImageManager.getInstance().drawCircle();
  const drawRectangle = () => ImageManager.getInstance().drawRectangle();

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const patternFile: File = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (f) => {
          const data = f.target?.result as string;
          if (data) {
            ImageManager.getInstance().addImageWithImgUrl(data);
          }
      };
      reader.readAsDataURL(patternFile);
    }
  }

  const handleAddUnsplashToMapper = (imageUrl: string) => {
    ImageManager.getInstance().addImageWithImgUrl(imageUrl);
  }

  const handleAddPattern = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const patternFile: File = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (f) => {
          const data = f.target?.result as string;
          if (data) {
            ImageManager.getInstance().addPatternWithImgUrl(data);
          }
      };
      reader.readAsDataURL(patternFile);
    }
  }

  const handleMoveUp = (objectId: string) => {
    ImageManager.getInstance().bringForward(objectId);
    dispatch(moveObjectUp(objectId));
  }

  const handleMoveDown = (objectId: string) => {
    ImageManager.getInstance().bringBackward(objectId);
    dispatch(moveObjectDown(objectId));
  }

  const handleImageLayerClick = (objectId: string) => {
    ImageManager.getInstance().selectObject(objectId);
  }

  const handleChange = async () => {
    const fabricObject = ImageManager.getInstance().getObjectWithId(imageEdit.selectedObjectId);

    if (fabricObject) {
      dispatch(modifyObject(classToPlainObject(fabricObject)));
      handleImageChange(await ImageManager.getInstance().getBase64Image(512));
    }
  }

  const handleSceneManagerCreated = (sceneManager: SceneManager) => {
    setSceneManager(sceneManager);
  }

  return (
    <div className={classes.ModelSimulator}>
      { 
        isLoadingOverlayOpen &&
        <LoadingOverlay message="Wrapping Image Around Object" />
      }
      <MappingEditorLeftSidebar
        imageEditState={imageEdit}
        createText={createText}
        createCircle={createCircle}
        drawRectangle={drawRectangle}
        onAddImage={handleAddImage}
        onAddUnsplashToMapper={handleAddUnsplashToMapper}
        handleAddPattern={handleAddPattern}
        handleBackToSceneClick={handleBackToSceneClick}
        handleMoveUp={handleMoveUp}
        handleMoveDown={handleMoveDown}
        handleImageLayerClick={handleImageLayerClick}
      />
      <div className={classes.ModelSimulatorContent}>
        <ImageEdit
          onImageChange={(imageBase64) => handleImageChange(imageBase64)}
          backgroundImageUrl={backgroundUvImage}
          initialImageData={rawImageData}
        />
      </div>
      <MappingEditorRightSidebar
        onChange={handleChange}
        onSceneMangerCreated={handleSceneManagerCreated}
        imageEditState={imageEdit}
        model={selectedModel}
        imageEditObjects={imageEdit.objects}
      />
    </div>
  );
}
