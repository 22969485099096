import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Landing: {
    color: '#121212',
    fontSize: '1rem',
  },
  LandingBody: {
    width: '100%',
    height: '100%',
    maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    flexDirection: 'column',
  },
  SubHeadline: {
    fontSize: '3rem',
    color: '#121212',
    fontWeight: 'bold',
    marginTop: '-0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0rem',
    },
  },
  FeatureTitle: {
    fontSize: '2.6rem',
    fontWeight: 'bold',
    color: '#000',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  ColoredFont: {
    color: '#FF6C0F',
  }
}));
