import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ModelProperties.styles';
import { ToggleButtons } from '../ToggleButtons/ToggleButtons';
import { TextureType } from 'modules/ThreeManager/Models/enum/TextureType';
import { ModelPatternProperty } from './ModelPatternProperty';
import { ModelColorProperty } from './ModelColorProperty';
import { ModelMappingProperty } from './ModelMappingProperty';
import { ObjectMaterial } from 'modules/ThreeManager/Models/ObjectMaterial';
import { SceneHelper } from 'modules/ThreeManager/helper/SceneHelper';
import { Mapping } from 'modules/ThreeManager/Models/Mapping';
import { ObjectPattern } from 'modules/ThreeManager/Models/ObjectPattern';

const colorToggleButton = {
  value: TextureType.Color.toString(),
  content: <span>Color</span>,
  disabled: false,
};

const patternToggleButton = {
  value: TextureType.Pattern.toString(),
  content: <span>Pattern</span>,
};

const mappingToggleButton = {
  value: TextureType.Mapping.toString(),
  content: <span>Mapping</span>,
};

interface ModelPropertiesProps {
  objectMaterial?: ObjectMaterial,
  repeatPattern?: number,
  onMappingEditClick?: () => void,
  onTextureTypeChange?: (materialType: TextureType) => void,
  onColorChange?: (newColor: string) => void,
  onPatternUpload?: (textureUri: string) => void,
  onPatternRepeatChange?: (repeat: number) => void,
}

export function ModelProperties(props: ModelPropertiesProps) {
  const { 
    objectMaterial,
    repeatPattern = 1,
    onMappingEditClick = () => {},
    onTextureTypeChange = (materialType: string) => {},
    onColorChange = (newColor: string) => {},
    onPatternUpload = (textureUri: string) => {},
    onPatternRepeatChange = (repeat: number) => {},
    
  } = props;
  const classes = useStyles(useTheme());
  const [ textureType, setTextureType ] = React.useState(objectMaterial?.selectedTextureType || TextureType.Pattern.toString());
  React.useEffect(() => setTextureType((TextureType as any)[objectMaterial?.selectedTextureType || TextureType.Pattern.toString()]), [objectMaterial?.selectedTextureType])

  // if there is not object material render nothing or default
  if (!objectMaterial) return null;

  const currentObjectMaterial = SceneHelper.getCurrentObjectMaterial(objectMaterial);

  const handleTextureTypeChange = (event: React.MouseEvent<HTMLElement>, newTextureType: string) => {
    onTextureTypeChange((TextureType as any)[newTextureType]);
    setTextureType(newTextureType);
  }
  
  const handleColorChange = (newColor: string) => {
    onColorChange(newColor);
  }

  const handlePatternUpload = (base64File: string) => {
    onPatternUpload(base64File);
    onPatternRepeatChange(objectMaterial.defaultRepeat || 1);
  }

  const renderModelColorProperty = () => (
    <ModelColorProperty
      color={currentObjectMaterial as string}
      onColorChange={handleColorChange}
    />
  )

  const renderModelPatternProperty = () => (
    <ModelPatternProperty
      patternProperty={currentObjectMaterial as ObjectPattern}
      repeatPattern={repeatPattern}
      onPatternUpload={handlePatternUpload}
      onPatternRepeatChange={onPatternRepeatChange}
    />
  )

  const renderModelMappingProperty = () => (
    <ModelMappingProperty
      mappingProperty={currentObjectMaterial as Mapping}
      onMappingEditClick={onMappingEditClick}
    />
  )

  const renderModelProperty = () => {
    switch(textureType) {
      case TextureType.Color.toString(): return renderModelColorProperty();
      case TextureType.Pattern.toString(): return renderModelPatternProperty();
      case TextureType.Mapping.toString(): return renderModelMappingProperty();
      default: return renderModelPatternProperty();
    }
  }

  const toggleButtons = [];
  if (objectMaterial.color) toggleButtons.push(colorToggleButton);
  if (objectMaterial.pattern) toggleButtons.push(patternToggleButton);
  if (objectMaterial.mapping) toggleButtons.push(mappingToggleButton);
  
  return (
    <>
      <div className={classes.ModelPropertiesSelection}>
        <ToggleButtons
          orientation="horizontal"
          onToggleChange={handleTextureTypeChange}
          selectedToggle={textureType || 'Pattern'}
          toggleButtonData={toggleButtons}
        />
      </div>
      { renderModelProperty() }
    </>
  );
}
