import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  articleContainer: {
    maxWidth: '800px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  articleTitle: {
    '& h1': {
      color: 'black',
      fontSize: '3rem',
      textAlign: 'center',
    }
  },
  articleFeatImage: {
    width: '100%',
    '&>img': {
      width: '100%',
    }
  },
  articleBody: {
    fontFamily: '"Open Sans", sans-serif',
    color: '#444',
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: 1.72,
    '& .wp-block-image': {
      width: '100%',
      marginLeft: 0,
      '& img': {
        width: '100%',
      },
      '& figcaption': {
        fontSize: '0.8rem',
        color: '#868686',
        textAlign: 'center',
      }
    }
  }
}));
