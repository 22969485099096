import { whoAmI } from 'features/user/user';
import { useDispatch } from 'react-redux';

export function LoginHelper() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  if (token) {
    dispatch(whoAmI());
  }

  return null;
}
