import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import useStyles from './ChildrenDetails.styles';
import { ObjectMaterial } from 'modules/ThreeManager/Models/ObjectMaterial';

interface ChildrenDetailsProps {
  objectMaterial: ObjectMaterial,
}

export function ChildrenDetails({objectMaterial}: ChildrenDetailsProps) {
  const classes = useStyles(useTheme());

  const renderAvailabilityIcon = (isAvailable: boolean) => (
    isAvailable
      ? <CheckCircleOutlineIcon className={`${classes.AvailabilityIcon} ${classes.Available}`} />
      : <HighlightOffIcon className={`${classes.AvailabilityIcon} ${classes.NonAvailable}`}/>
  )

  const renderAvailability = (text: string, icon: JSX.Element) => (
    <li>
      {text}
      {icon}
    </li>
  )

  return (
    <div className={classes.ChildrenDetails}>
      <b>{objectMaterial.name}</b>
      <ul className={classes.AvailabilityList}>
        { renderAvailability('Adjustable Color', renderAvailabilityIcon(!!objectMaterial.color)) }
        { renderAvailability('Repeated Pattern', renderAvailabilityIcon(!!objectMaterial.pattern)) }
        { renderAvailability('Mapping Image', renderAvailabilityIcon(!!objectMaterial.mapping)) }
      </ul>
    </div>
  );
}
