var glsl = require('glslify');
export default glsl(`
  #ifdef OBJECTSPACE_NORMALMAP

  normal = texture2D( normalMap, vUv ).xyz * 2.0 - 1.0; // overrides both flatShading and attribute normals

  #ifdef FLIP_SIDED

    normal = - normal;

  #endif

  #ifdef DOUBLE_SIDED

    normal = normal * ( float( gl_FrontFacing ) * 2.0 - 1.0 );

  #endif

  normal = normalize( normalMatrix * normal );

  #elif defined( TANGENTSPACE_NORMALMAP )

  vec3 mapN = texture2D( normalMap, vUv * structureRepeat ).xyz * 2.0 - 1.0;
  mapN.xy *= normalScale;

  #ifdef USE_TANGENT

    normal = normalize( vTBN * mapN );

  #else

    normal = perturbNormal2Arb( -vViewPosition, normal, mapN );

  #endif

  #elif defined( USE_BUMPMAP )

  normal = perturbNormalArb( -vViewPosition, normal, dHdxy_fwd() );

  #endif
`);
