import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './LandingExample.styles';
import useLandingPageStyles from 'modules/LandingPage/views/LandingPage/Landing.styles';

import coffeeExample from './images/coffee.jpg';
import imageWall from './images/image.jpg';
import pillow from './images/pillow.jpg';
import pillow2 from './images/pillow2.jpg';
import pillow3 from './images/pillow3.jpg';
import towel from './images/towel.jpg';

export const LandingExample = () => {
  const classes = useStyles(useTheme());
  const landingPageClasses = useLandingPageStyles(useTheme());

  return (
    <section className={classes.LandingExample} id="examples">
      <div className={`${landingPageClasses.LandingBody}`}>
        <div>
          <div className={landingPageClasses.FeatureTitle}>
            100+ models for <span className={landingPageClasses.ColoredFont}>unlimited possibilities</span>
          </div>
        </div>
        <div>
          Build your own unique scenes from a big library of professional models
        </div>
        <div className={classes.ExampleGallery}>
          <img alt="" className={classes.ExampleImages} src={coffeeExample} />
          <img alt="" className={classes.ExampleImages} src={imageWall} />
          <img alt="" className={classes.ExampleImages} src={pillow} />
          <img alt="" className={classes.ExampleImages} src={pillow2} />
          <img alt="" className={classes.ExampleImages} src={pillow3} />
          <img alt="" className={classes.ExampleImages} src={towel} />
        </div>
      </div>
    </section>
  )
}
