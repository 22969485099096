import { configuration as prodConfig } from './environment/production';
import { configuration as localConfig } from './environment/local';
import { configuration as devConfig } from './environment/development';

const environment = process.env.REACT_APP_HOST_ENV || 'development';

export const config = 
  environment === 'production'
  ? prodConfig
  : environment === 'local'
  ? localConfig
  : devConfig;
