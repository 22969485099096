import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Article.styles';
import { Helmet } from 'react-helmet';

import { BlogApi } from 'shared/utils/api/blog';
import { useParams } from 'react-router-dom';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';

const createTitle = (title: string) => `MockupFox - ${title}`;

export const Article = () => {
  const classes = useStyles(useTheme());
  const { articleSlug = '' } = useParams<{articleSlug: string}>();
  const [ articleHtml, setArticleHtml ] = React.useState();

  React.useEffect(() => {
    new BlogApi().getArticlePosts(articleSlug)
      .then(data => setArticleHtml(data))
      .catch(error => console.error(error));
  }, []);

  if(!articleHtml) return <div>Loading</div>;

  return (
    <>
      <Helmet>
        <title>{createTitle((articleHtml as any).title.rendered)}</title>
      </Helmet>
      <Header />
      <div className={`article article__body ${classes.articleContainer}`}>
        <div className={classes.articleTitle} dangerouslySetInnerHTML={{__html: `<h1>${(articleHtml as any).title.rendered}</h1>`}} />
        <div className={classes.articleFeatImage}>
          <img alt="" src={(articleHtml as any).fimg_url} />
        </div>
        <div className={classes.articleBody} dangerouslySetInnerHTML={{__html: (articleHtml as any).content.rendered}} />
      </div>
      <Footer />
    </>
  )
}
