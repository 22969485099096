import ApiHelper from './api';

export class BlogApi extends ApiHelper {
  private endpoint = '/article'; 

  public getCategoryPosts(slug: string): Promise<any> {
    return this.get(`${this.baseUrl}${this.endpoint}/category/${slug}`);
  }

  public getArticlePosts(slug: string): Promise<any> {
    return this.get(`${this.baseUrl}${this.endpoint}/slug/${slug}`);
  }
}
