import { Texture } from 'modules/ThreeManager/Models/Texture';
import ApiHelper from './api';

export default class TextureApi extends ApiHelper {
  private endpoint = '/texture';

  public createTextureUrl(modelKey: string) {
      return `${this.baseUrl}${this.endpoint}/file/${modelKey}`;
  }

  public getStructuralTexture(id: string): Promise<Texture> {
    return this.get(`${this.baseUrl}${this.endpoint}/structural/${id}`);
  }
}