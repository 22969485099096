import React from 'react';
import { Dialog } from 'shared/components/Dialog';
import useStyles from './CheckoutFormDialog.styles';
import { useTheme } from '@material-ui/core/styles';
import { CheckoutForm } from '../CheckoutForm/CheckoutForm';
import { Plan } from 'shared/models/Plan';
import { Switch } from '@material-ui/core';
import { PlanPeriod } from 'shared/models/enum/PlanPeriod';
import { CircularSpinner } from '../CircularSpinner';

export interface CheckoutFormDialogProps {
  isOpen: boolean;
  plan: Plan | undefined,
  onCloseClick: () => void;
  successCallback: () => void,
}

export function CheckoutFormDialog(props: CheckoutFormDialogProps) {
  const {
    isOpen = true,
    plan,
    onCloseClick = () => {},
    successCallback = () => {},
  } = props;

  const [ selectedBillingCycle, setSelectedBillingCycle ] = React.useState(PlanPeriod.Yearly);
  const classes = useStyles(useTheme());

  const handlePlanPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const planPeriod = event.target.checked ? PlanPeriod.Yearly : PlanPeriod.Monthly;
    setSelectedBillingCycle(planPeriod);
  };

  const renderPrice = () => {
    if (plan) {
      return `${
        selectedBillingCycle === PlanPeriod.Yearly
          ? Math.round((parseFloat(plan.priceYearlyDollar) / 12) * 100) / 100
          : plan.priceMonthlyDollar
      }$`;
    }
    return <CircularSpinner size={20} />
  }

  return (
    <Dialog
      isOpen={isOpen}
      title={'Upgrade to Professional'}
      onCloseClick={onCloseClick}
    >
      <div className={classes.BillingCycleContainer}>
        <span className={classes.BillingCycleTitel}>Billing Cycle</span>
        <div className={classes.BillingCycleSelection}>
          <div className={`${classes.BillingCycleMonthly} ${selectedBillingCycle === PlanPeriod.Monthly && classes.BillingCycleSelected}`}>
            Monthly
          </div>
          <Switch
            defaultChecked
            checked={selectedBillingCycle === PlanPeriod.Yearly}
            onChange={handlePlanPeriodChange}
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
          />
          <div className={`${classes.BillingCycleYearly} ${selectedBillingCycle === PlanPeriod.Yearly && classes.BillingCycleSelected}`}>
            Yearly (saves up to 40%)
          </div>
        </div>
      </div>
      <div className={classes.SelectedPlanContainer}>
        <div className={classes.SelectedPlanPrice}>
          <span className={classes.SelectedPlanMonthlyAmount}>
            { renderPrice() }
          </span>
          <span className={classes.SelectedPlanMonthlySubscript}>
            per month
          </span>
          <span className={classes.SelectedPlanMonthlySubscript}>
            { selectedBillingCycle === PlanPeriod.Yearly ? '(billed annually)' : '' }
          </span>
        </div>
        <div>
          <ul>
            <li>Add unlimited objects to a scene</li>
            <li>Access to all models</li>
            <li>Access to all template scenes</li>
            <li>Advanced material settings</li>
            <li>Use Mockups for commercial purpose</li>
          </ul>
        </div>
      </div>
      <CheckoutForm plan={plan || new Plan()} planPeriod={selectedBillingCycle} successCallback={successCallback}/>
    </Dialog>
  );
}