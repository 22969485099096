import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ModelColorProperty.styles';
import { ColorPicker } from 'shared/components/ColorPicker';

interface ModelColorPropertyProps {
  color?: string,
  onColorChange?: (newColor: string) => void,
}

export function ModelColorProperty(props: ModelColorPropertyProps) {
  const {
    color = "#FFFFFF",
    onColorChange = (newColor: string) => {},
  } = props;
  const classes = useStyles(useTheme());

  return (
    <div className={classes.ModelColorProperty}>
      <ColorPicker color={color as string || "#FFFFFF"} onColorChange={onColorChange}/>
    </div>
  );
}
