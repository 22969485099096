import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  AccountPanel: {
    
  },
  AccountInfo: {
    backgroundColor: 'burlywood',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '33px',
    width: '33px',
    borderRadius: '50px',
    fontSize: '1.1rem',
    cursor: 'pointer',
  },
  LoginRegisterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '5px',
    }
  },
}));
