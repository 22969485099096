var glsl = require('glslify');
export default glsl(`
  #ifdef USE_MAP
    vec4 texelColor = texture2D( map, vUv * colorMapRepeat );
    texelColor = mapTexelToLinear( texelColor );
    diffuseColor *= texelColor;
  #endif

  #ifdef USE_OVERLAYMAP
    if (overlayCount > 0) {
      vec4 overlayColor = texture2D( overlayColorMap1, vUv * structureRepeat );
      diffuseColor.r *= (1. - overlayStrength1) + overlayColor.r * overlayStrength1;
      diffuseColor.g *= (1. - overlayStrength1) + overlayColor.g * overlayStrength1;
      diffuseColor.b *= (1. - overlayStrength1) + overlayColor.b * overlayStrength1;
    }
  #endif
`);
