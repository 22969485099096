import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ModelSimulatorBody: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  ModelSimulatorLeftSidebar: {
    width: '335px!important',
  },
  ModelSimulatorContent: {
    flexGrow: 1,
    position: 'relative',
  },
  ChangeObjectDialogSelect: {
    width: '80px',
    height: '80px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ModelSelectionTabBody: {
    height: 0,
  }
}));
