import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'rootReducer';
import useStyles from './TemplateScenesOverview.styles';
import { loadSceneTemplates } from 'features/templateScenes/templateScenes';
import { TemplateSceneCard } from '../../components/TemplateSceneCard';
import { MockupLayout } from 'shared/components/MockupLayout';

export function TemplateScenesOverview() {
  const classes = useStyles(useTheme());
  const dispatch = useDispatch();
  const { templateScenes } = useSelector((state: RootState) => state);

  React.useEffect(() => {
    dispatch(loadSceneTemplates());
  }, []);

  return (
    <MockupLayout title="">
      <div className={classes.TemplateScenesContainer}>
        <div className={classes.TemplateScenes}>
          {
            templateScenes.data.map(templateScene => (
              <TemplateSceneCard
                templateScene={templateScene}
                onOpenSceneClick={() => {}}
                onDetailsClick={() => {}}
              />
            ))
          }
        </div>
      </div>
    </MockupLayout>
  );
}
