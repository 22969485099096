import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  HeaderContainer: {
    height: '50px',
    backgroundColor: '#000000',
    display: 'flex',
    justifyContent: 'center',
  },
  Header: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxWidth: '1200px',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '12px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'normal',
    },
  },
  HeaderItemContainer: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(1),
    }
  },
  Logo: {
    height: '60%',
    [theme.breakpoints.down('md')]: {
      height: '30%',
    },
  },
  MainMenu: {
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
    listStyle: 'none',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  MainMenuItem: {
    display: 'inline-block',
    color: '#fff',
    fontSize: '1.0625rem',
    verticalAlign: 'middle',
  },
  MenuLink: {
    display: 'inline-block',
    fontSize: 'inherit',
    textDecoration: 'none',
    verticalAlign: 'middle',
    padding: '10px 17px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff6c0f',
      color: 'white',
    }
  }
}));
