import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  categoryContainer: {
    maxWidth: '1200px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'repeat(auto-fill, 400px)',
    gap: '32px 32px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  excerpt: {

  },
  excerptImage: {
    width: '100%',
    height: '45%',
    '&>a>img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  excerptTitle: {
    '&>h2>a': {
      color: 'initial',
      textDecoration: 'initial',
      '&:hover': {
        borderBottom: '1px solid #33335e',
      }
    },
  },
  excerptDescription: {
    fontFamily: '"Open Sans", sans-serif',
    color: '#444',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.72,
  },
}));
