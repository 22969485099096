import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  SaveDialog: {
  },
  SavePreviewImageContainer: {
    height: '300px',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  SavePreviewImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  BillingCycleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  BillingCycleTitel: {
    color: 'white',
    fontSize: '1.0rem',
  },
  BillingCycleSelection: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  BillingCycleMonthly: {
    color: 'white',
    width: '45px',
  },
  BillingCycleYearly: {
    color: 'white',
    width: '145px',
  },
  BillingCycleSelected: {
    fontWeight: 'bold',
  },
  SelectedPlanContainer: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    color: 'white',
  },
  SelectedPlanPrice: {
    display: 'flex',
    flexDirection: 'column',
    width: '100px',
    justifyContent: 'center',
  },
  SelectedPlanMonthlyAmount: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  SelectedPlanMonthlySubscript: {
    fontSize: '0.8rem',
  },
  SeparationLine: {
    width: '100%',
    borderBottom: '1px solid #EEEEEE',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
