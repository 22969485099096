import * as THREE from 'three';
import { Vector3d } from '../Models/Vector3d';

export class ThreeObjectFactory {
  static createScene() {
    const scene = new THREE.Scene();
    scene.background = null;
    return scene;
  }
  
  static createRender(canvas: HTMLCanvasElement) {
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true
    }); 
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(canvas.width, canvas.height);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputEncoding = THREE.GammaEncoding;
    renderer.physicallyCorrectLights = true
    
    return renderer;
  }

  static createCanvas(document: Document, container: HTMLDivElement) {
    const canvas = document.createElement('canvas');  
    canvas.style.width = '100%';
    canvas.style.height = '100%';   
    container.appendChild(canvas);

    return canvas;
  }

  static createCamera(
    widthPx: number,
    heightPx: number,
    cameraPosition: Vector3d = {x: -2, y: 1, z: 2},
  ) {
    const aspectRatio = widthPx / heightPx;
    const fieldOfView = 45;
    const nearPlane = 0.1;
    const farPlane = 5000; 
    const camera = new THREE.PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
  
    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    camera.lookAt(0, 0, 0);
  
    return camera;
  }
}