import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './LandingVideo.styles';
import useLandingPageStyles from 'modules/LandingPage/views/LandingPage/Landing.styles';

import ScreenThumb from './mockupScreen1.jpg';
import HeroVideo from './mockupfox_demo.mp4';
import Curvy from './curvypart.svg';
import useIntersectionObserver from 'shared/hooks/useIntersectionObserver';

export const LandingVideo = () => {
  const classes = useStyles(useTheme());
  const landingPageClasses = useLandingPageStyles(useTheme());
  const [ref, entry] = useIntersectionObserver({ threshold: 0.9 });
  const [play, setPlay] = React.useState(true);

  if (entry && (entry as any).isIntersecting) {
    if (!play) {
      setPlay(true);
      (entry as any).target.play();
    }
  } else {
    if(play) {
      setPlay(false);
      if (entry) (entry as any).target.pause();
    }
  }

  return (
    <section className={classes.LandingVideo} style={{backgroundImage: `url(${Curvy})`, backgroundRepeat: 'no-repeat'}}>
      <div className={`${landingPageClasses.LandingBody}`}>
        <div className={classes.LandingVideoBody}>
          <video ref={(ref as any)} className={classes.Video} autoPlay={play} loop={true} preload="auto" poster={ScreenThumb} muted>
            <source src={HeroVideo} type="video/mp4" ></source>
          </video>
          <div></div>
        </div>
      </div>
    </section>
  )
}
