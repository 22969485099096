import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Checkbox, IconButton } from '@material-ui/core';

import useStyles from './ParentObject.styles';
import { CircularSpinner } from 'shared/components/CircularSpinner';

interface ParentObjectProps {
  name: string,
  icon: React.ReactElement,
  isSelected?: boolean
  showChevron?: boolean,
  showChildren?: boolean,
  isLoading?: boolean,
  isVisible?: boolean,
  onClick: () => void,
  chevronClick?: () => void,
  onVisibilityClick?: (isVisible: boolean) => void,
}

export function ParentObject(props: ParentObjectProps) {
  const {
    name,
    icon,
    isSelected,
    showChevron,
    showChildren,
    isLoading = false,
    isVisible = true,
    onClick = () => {},
    chevronClick = () => {},
    onVisibilityClick = (isVisible: boolean) => {},
  } = props;

  const classes = useStyles(useTheme());
  
  const handleSelectionClick = () => {
    onClick();
  }

  const handleChevronClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    chevronClick();
  }

  const renderChevron = () => (
    <IconButton size="small" disabled={!showChevron} aria-label="expand object" onClick={handleChevronClick}>
      { (showChildren ? <KeyboardArrowDownIcon/> : <ChevronRightIcon/>) }
    </IconButton>
  )

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onVisibilityClick(event.target.checked);
  };

  return (
    <div
      className={`${classes.ParentObject} ${isSelected && classes.ParentObjectSelected}`}
      onClick={handleSelectionClick}
    >
      <div className={classes.Extend}>
        {
          isLoading
          ? <div className={classes.LoadingSpinner}><CircularSpinner size={23}/></div>
          : renderChevron()
        }
      </div>
      <div className={classes.Name}>{ name }</div>
      <div className={classes.Icon}>{ icon }</div>
      <div className={classes.Visible}>
        <Checkbox
          checked={isVisible}
          color="default"
          onChange={handleVisibilityChange}
          onClick={e => e.stopPropagation()}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </div>
  );
}
