import { Scene } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ControlsManager } from "../Manager/ControlsManager";

export class ControlsFactory {
  static createControlsManager(
    scene: Scene,
    camera: THREE.Camera,
    renderer: THREE.WebGLRenderer,
    canvas: HTMLCanvasElement,
  ) {
    return new ControlsManager(scene, camera, renderer, canvas);
  }

  static createOrbitControls(camera: THREE.Camera, canvas: HTMLCanvasElement) {
    const controls = new OrbitControls(camera, canvas);
  
    controls.minDistance = 0.2;
    controls.maxDistance = 50;
  
    controls.maxPolarAngle = Math.PI / 2;
    controls.minPolarAngle = 0;
  
    controls.target.set(0, 0.5, 0);
  
    return controls;
  }
}