import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useStyles from './ModelMappingProperty.styles';
import { Mapping } from 'modules/ThreeManager/Models/Mapping';

interface ModelMappingPropertyProps {
  mappingProperty?: Mapping,
  onMappingEditClick?: () => void,
}

export function ModelMappingProperty(props: ModelMappingPropertyProps) {
  const { 
    mappingProperty,
    onMappingEditClick = () => {},
  } = props;
  const classes = useStyles(useTheme());

  return (
    <div className={classes.ModelMappingProperty}>
      <img
        alt=""
        className={classes.ModelMappingPropertyImage}
        src={mappingProperty?.textureUri}
        onClick={onMappingEditClick}
      />
      <Button
        variant="contained"
        color="inherit"
        className={classes.EditButton}
        startIcon={<EditIcon />}
        onClick={onMappingEditClick}
      >
        Edit
      </Button>
    </div>
  );
}
