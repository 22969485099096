import { SceneModel } from "modules/ThreeManager/Models//SceneModel";
import { ObjectMaterial } from "modules/ThreeManager/Models/ObjectMaterial";
import { SceneObjectBase } from "modules/ThreeManager/Models/SceneObjectBase";
import { Color } from "three";
import { Vector3d } from "modules/ThreeManager/Models/Vector3d";

export enum PrimitiveObjectType {
  Plane,
}

export interface PlaneObjectProperties {
  width: number,
  height: number,
  widthSegments: number,
  heightSegments: number,
}

export interface PrimitiveObject extends SceneObjectBase {
  type: PrimitiveObjectType,
  properties: PlaneObjectProperties,
  material: ObjectMaterial,
}

export enum SceneLightType {
  DirectionalLight = 'Directional Light',
  PointLight = 'Point Light',
  SpotLight = 'Spot Light',
}

export interface DirectionalLightProperty {
  intensity: number,
  color: string | Color | number,
}

export interface CameraSettings {
    position: Vector3d,
    lookAt: Vector3d
}

export interface SceneLight extends SceneObjectBase {
  type: SceneLightType,
  property: DirectionalLightProperty,
  castShadow?: boolean,
}

export interface Scene3dSettings {
  backgroundColor?: string,
}

export interface Scene3d {
  settings: Scene3dSettings;
  models: Array<SceneModel>;
  primitives: Array<PrimitiveObject>;
  lights: Array<SceneLight>;
  cameraSetting: CameraSettings;
  // camera position/rotation
  // background
}

export interface SceneState {
  id: string;
  loading: boolean;
  data: Scene3d;
  errors?: string;
}

export interface Scenes {
  readonly scenes: Array<SceneState>
}