import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Landing.styles';
import { Header } from 'modules/LandingPage/components/Header';
import { LandingHero } from 'modules/LandingPage/components/LandingHero';
import { LandingVideo } from 'modules/LandingPage/components/LandingVideo';
import { Footer } from 'shared/components/Footer';
import { LandingExample } from 'modules/LandingPage/components/LandingExample';
import { LandingFeature } from 'modules/LandingPage/components/LandingFeatures';
import { LandingCallToAction } from 'modules/LandingPage/components/LandingCallToAction';

export function Landing() {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.Landing}>
      <Header />
      <LandingHero />
      <LandingVideo />
      <LandingFeature />
      <LandingExample />
      <LandingCallToAction />
      <Footer />
    </div>
  );
}
