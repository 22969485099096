import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './NoMobileSupport.styles';

import Logo from 'shared/assets/images/logo.svg';

export function NoMobileSupport() {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.NoMobileSupport}>
      <img alt="" className={classes.NoMobileSupportImage} src={Logo} />
      Sorry, MockupFox currently doesn't support small mobile screens.
    </div>
  );
}
