import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import { RootState } from 'rootReducer';
import useStyles from './Simulator.styles';
import { ModelSimulator } from 'modules/Simulator3d/views/ModelSimulator';
import simulatorTheme from 'shared/assets/theme/simulatortheme';
import { MappingEditor } from 'modules/MappingEditor/views/MappingEditor';
import { loadModelBySlug, loadScene } from 'features/scenes/scenes';
import { Header } from 'modules/Simulator3d/components/Header';
import { SimulatorViewState } from 'features/ui/types';
import { TemplateScenesOverview } from 'modules/TemplateScenes/views/Overview';
import { openSimulator3d } from 'features/ui/ui';

const theme = createMuiTheme(simulatorTheme);

export function Simulator() {
  const classes = useStyles(useTheme());
  const dispatch = useDispatch();
  const { ui } = useSelector((state: RootState) => state);
  const { sceneSlug, modelSlug } = useParams<{sceneSlug: string, modelSlug: string}>();

  React.useEffect(() => {
    if(sceneSlug && ui.editorSceneId) {
      dispatch(loadScene(ui.editorSceneId, sceneSlug));
      dispatch(openSimulator3d());
    }
  }, [sceneSlug, ui.editorSceneId]);

  React.useEffect(() => {
    if(modelSlug && ui.editorSceneId) {
      dispatch(loadModelBySlug(ui.editorSceneId, modelSlug));
      dispatch(openSimulator3d());
    }
  }, [modelSlug, ui.editorSceneId]);

  const renderViewState = (simulatorViewState: SimulatorViewState) => {
    switch(simulatorViewState) {
      case SimulatorViewState.Simulator3d: return <ModelSimulator />;
      case SimulatorViewState.MappingEditor: return <MappingEditor />;
      case SimulatorViewState.TemplateScenes: return <TemplateScenesOverview />;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.Simulator}>
        <Header />
        {
          renderViewState(ui.simulatorViewState)
        }
      </div>
    </ThemeProvider>
  );
}
