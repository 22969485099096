import ApiHelper from './api';

export default class UserApi extends ApiHelper {
  private endpoint = '/user';

  public login(email: string, password: string): Promise<any> {
    return this.post(`${this.baseUrl}${this.endpoint}/login/email`, { email, password });
  }

  public loginWithGoogle(tokenId: string): Promise<any> {
    return this.post(`${this.baseUrl}${this.endpoint}/login/google`, { tokenId });
  }

  public register(email: string, password: string): Promise<any> {
    return this.post(`${this.baseUrl}${this.endpoint}/register`, { email, password });
  }

  public resetPassword(email: string): Promise<any> {
    return this.post(`${this.baseUrl}${this.endpoint}/recovery`, { email });
  }

  public whoAmI(): Promise<any> {
    return this.get(`${this.baseUrl}${this.endpoint}/whoami`);
  }

  public changeSubscription(paymentMethodId: string, planName: string, planPeriod: string): Promise<any> {
    return this.post(`${this.baseUrl}${this.endpoint}/subscription/change`, { paymentMethodId, planName, planPeriod });
  }
}
