const MAX_CACHE_KEY_LENGTH = 150

export abstract class LoaderAbstract<T, S = {}> {
  private cache: { [key: string]: T } = { };

  protected abstract async loader(key: string, options?: S): Promise<T>;
  protected abstract postProcessCacheLoad(loaded: T): T;

  public async load(key: string, options?: S) {
    const identifier = key.slice(0, MAX_CACHE_KEY_LENGTH);
    
    if (this.cache[identifier]) {
      return this.postProcessCacheLoad(this.cache[identifier]);
    } else {
      const loaded = await this.loader(key, options);
      this.cache[identifier] = loaded;
      return loaded;
    }
  }
}