import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import ModelApi from 'shared/utils/api/model';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { LoaderAbstract } from "./LoaderAbstract";

export class GeometryLoader extends LoaderAbstract<GLTF> {
  private dracoLoader = new DRACOLoader();

  constructor(
    private gltfLoader: GLTFLoader,
  ) {
    super();
    this.dracoLoader.setDecoderPath(`${new ModelApi().baseUrl}/../../draco/`);
    this.gltfLoader.setDRACOLoader(this.dracoLoader);
  }

  protected async loader(key: string): Promise<GLTF> {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(
        new ModelApi().createModelUrl(key),
        (gltf) => {
          gltf.scene.traverse((o) => {
            if ( o instanceof THREE.Mesh ) {
              o.castShadow = true;
              o.receiveShadow = true;
            }
          });
          resolve(gltf);
        },
        () => {},
        (error) => reject(error)
      )
    })
  }

  protected postProcessCacheLoad(gltf: GLTF) {
    gltf.scene = gltf.scene.clone();
    gltf.scenes = gltf.scenes.map((scene) => scene.clone());
    return gltf;
  }
}