import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import { ColorPicker } from 'shared/components/ColorPicker';
import useStyles from './LightObjectProperty.styles';
import { SceneLight } from 'features/scenes/types';

interface LightObjectPropertyProps {
  light: SceneLight; 
  onColorChange?: (newColor: string) => void;
  onIntensityChange?: (newIntensity: number) => void;
}

export function LightObjectProperty(props: LightObjectPropertyProps) {
  const { light, onColorChange = () => {}, onIntensityChange = () => {} } = props;
  const classes = useStyles(useTheme());
  const [ intensityInput, setIntensityInput ] = React.useState(props.light.property.intensity.toString());

  const handleIntensityChange = (intensity: string) => {
    setIntensityInput(intensity);
    const numbericIntensity = parseFloat(intensity);
    if (!isNaN(numbericIntensity)) onIntensityChange(numbericIntensity);
  }

  return (
    <div className={classes.LightObjectProperty}>
      <div className={classes.LightIntensity}>
        <span>Intensity</span>
        <TextField
          id="scaleChangeY"
          value={ intensityInput }
          onChange={(event) => handleIntensityChange(event.target.value)}
          variant="outlined"
          size="small"
        />
      </div>
      <div>
        Color
        <ColorPicker color={light.property?.color as string || "#FFFFFF"} onColorChange={onColorChange}/>
      </div>
    </div>
  );
}
