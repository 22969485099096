import { CameraSettings, SceneState } from "features/scenes/types";
import { ObjectEditOption } from "features/ui/types";
import { SceneHelper } from "modules/ThreeManager/helper/SceneHelper";
import { RootState } from "rootReducer";
import { VectorHelper } from "../helper/VectorHelper";
import { ControlsManager } from "../Manager/ControlsManager";
import { ControllerAbstract } from "./ControllerAbstract";

export class ControlsManagerController extends ControllerAbstract {

  private selectedObjectSceneId = '';
  private currentEditOption = ObjectEditOption.Move;
  private currentCameraSettings: CameraSettings = { position: {x: 0, y: 0, z: 0}, lookAt: {x: 0, y: 0, z: 0} };

  constructor(
    private controlsManager: ControlsManager,
  ) {
    super();
  }

  processIncomingState(state: RootState) {
    const { selectedObjectSceneId, editorSceneId, objectEditOption } = state.ui;
    const { scenes } = state.scenes;
    const scene = scenes.find(scene => scene.id === editorSceneId);

    if (scene) {
      this.processCurrentSelectedObject(scene, selectedObjectSceneId);
      this.processObjectEditOption(objectEditOption);
      this.processCameraSettings(scene.data.cameraSetting);
    }
  }

  private processCurrentSelectedObject(scene: SceneState, selectedObjectSceneId: string) {
    if (this.selectedObjectSceneId !== selectedObjectSceneId) {
      const selectedThreeId = new SceneHelper(scene.data).getSceneModelById(selectedObjectSceneId)?.threeObjectId || -1;
      if (selectedThreeId >= 0 || selectedObjectSceneId === '') {
        this.controlsManager.setSelectedObject(selectedThreeId);
        this.selectedObjectSceneId = selectedObjectSceneId;
      }
    }
  }

  private processObjectEditOption(objectEditOption: ObjectEditOption) {
    if (this.currentEditOption !== objectEditOption) {
      this.controlsManager.setEditOption(objectEditOption);
      this.currentEditOption = objectEditOption;
    }
  }

  private processCameraSettings(cameraSetting: CameraSettings) {
    if (this.hasCameraSettingChanged(cameraSetting))
    {
      this.currentCameraSettings = cameraSetting;
      this.controlsManager.setCamera(cameraSetting);
    }
  }

  private hasCameraSettingChanged(cameraSetting: CameraSettings) {
    const { position: currentPosition, lookAt: currentLookAt } = this.currentCameraSettings;
    const { position, lookAt } = cameraSetting;

    const hasPositionChanged = !VectorHelper.isEqual(currentPosition, position, 0.01);
    const hasLookAtChanged = !VectorHelper.isEqual(currentLookAt, lookAt, 0.01);
    return hasPositionChanged || hasLookAtChanged;
  }
}