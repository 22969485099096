import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  LandingFeature: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(4),
    color: '#121212',
  },
  SubHeadlineContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  TwoColumns: {
    display: 'flex',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }
  },
  Column: {
    width: '50%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    }
  },
  TextColumn: {
    paddingRight: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#646e7a',
  },
  FeatureVideo: {
    width: '100%',
    borderRadius: '4px',
  }
}));
