import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import useStyles from './ThreeModelPreview.styles';
import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';
import { SceneManager } from 'modules/ThreeManager/Manager/SceneManager';
import { addModelRequestWithSceneModel, deleteScene } from 'features/scenes/scenes';
import { SceneFactory } from 'modules/ThreeManager/Factories/SceneFactory';
import { ControllerAbstract } from 'modules/ThreeManager/Controller/ControllerAbstract';
import { removeControllerAsSubscriber } from 'store';

interface ThreeModelPreviewProps {
  model: SceneModel,
  onSceneMangerCreated: (sceneManager: SceneManager) => void,
}

export function ThreeModelPreview(props: ThreeModelPreviewProps) {
  const { model, onSceneMangerCreated } = props;
  const classes = useStyles(useTheme());
  const dispatch = useDispatch();

  const threeCanvasRef = React.useRef<HTMLDivElement>(null);
  const [ threeManager, setThreeManager ] = React.useState<SceneManager | null>(null);
  const [ sceneControllerArray, setSceneControllerArray ] = React.useState<Array<ControllerAbstract>>([]);

  React.useEffect(() => {
    return () => {
      if (sceneControllerArray) {
        sceneControllerArray.forEach(controller => removeControllerAsSubscriber(controller));
      }
    }
  }, [sceneControllerArray]);

  React.useEffect(() => {
    if (threeCanvasRef.current && !threeManager) {
      const { sceneManager, controller } = SceneFactory.createEmptyScene(document, threeCanvasRef.current);
      setSceneControllerArray(controller);
      setThreeManager(sceneManager);
      onSceneMangerCreated(sceneManager);
      dispatch(addModelRequestWithSceneModel(sceneManager.id, model));
    }
    return function cleanup() {
      if (threeManager) dispatch(deleteScene(threeManager.id));
    }
  }, [threeCanvasRef, threeManager]);

  return (
    <div
      className={classes.Canvas}
      ref={threeCanvasRef}
    />
  );
}
