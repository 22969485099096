import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  UnsplashGallery: {
  },
  UnsplashSearchInput: {
    display: 'flex',
    justifyContent: 'center',
  },
  UnsplashPhotoGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -8px -8px 0',
    paddingLeft: '0px',

    '& li': {
      flexGrow: 1,
      margin: '0 8px 8px 0',
      listStyleType: 'none',
    },
    '& li:last-child': {
      flexGrow: 10,
    },
    '& img': {
      maxHeight: 'calc(100% - 20px)',
      minWidth: '100%',
      objectFit: 'cover',
      verticalAlign: 'bottom',
      borderRadius: '4px',
      cursor: 'pointer',
    }
  },
  UnsplashAttribution: {
    fontSize: '0.7rem',
    color: 'gray',
    '& a': {
      color: 'gray',
    }
  }
}));
