import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const imageWidth = 250;

export default makeStyles((theme: Theme) => createStyles({
  ModelPatternProperty: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ModelPatternPropertyImage: {
    width: imageWidth,
    height: imageWidth,
    objectFit: 'cover',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  ModelPatternPropertyImageContainer: {
    position: 'relative',
    display: 'grid',
  },
  ButtonGroup: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '250px',
    justifyContent: 'space-evenly',
    position: 'absolute',
    bottom: 0,
    left: `calc(50% - ${imageWidth/2}px)`,
  },
  Button: {
    width: imageWidth/2 + 1,
    color: '#121212!important',
  },
  ModelPatternPropertySlider: {
    width: imageWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
  }
}));
