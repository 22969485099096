import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#171717c7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
    flexDirection: 'column',
  },
  OverlayQuote: {
    display: 'contents',
  },
  OverlayQuoteText: {
    fontSize: '1.3rem',
  },
  OverlayQuoteAuthor: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(3),
  },
}));
