import * as THREE from 'three';

import { ObjectMaterial } from "modules/ThreeManager/Models/ObjectMaterial";
import { MaterialType } from "../Models/enum/MaterialType";
import { OverlayTexture } from "../Models/OverlayTexture";
import { MaterialFactoryAbstract } from './AbstractFactories/MaterialFactoryAbstract';
import normalMapChunk from 'modules/ThreeManager/shader/replacementChunks/normalMap';
import colorMapChunk from 'modules/ThreeManager/shader/replacementChunks/colorMap';
import aoMapChunk from 'modules/ThreeManager/shader/replacementChunks/aoMap';
import roughnessMapChunk from 'modules/ThreeManager/shader/replacementChunks/roughnessMap';
import { TextureType } from '../Models/enum/TextureType';

export class MaterialFactory extends MaterialFactoryAbstract {
  createMaterial(
    material: ObjectMaterial,
    overlayColorMaps: OverlayTexture[] = [],
  ): THREE.MeshPhongMaterial | THREE.MeshStandardMaterial {
    let returnMaterial: THREE.MeshPhongMaterial | THREE.MeshStandardMaterial;

    const {
      selectedTextureType,
      structuralTextures,
      structuralRepeatModifier = 1,
    } = material;

    const structureRepeat = (structuralTextures?.mapRepeat || 1) * structuralRepeatModifier;
    const colorMapRepeat = selectedTextureType === TextureType.Pattern ? (material.pattern?.repeat || 1) : 1;

    switch(material.type) {
      case MaterialType.MeshPhongMaterial:
        const phongMat = new THREE.MeshPhongMaterial(material.property);
        this.applyCustomShaderChunk(phongMat, colorMapRepeat, structureRepeat, overlayColorMaps);
        returnMaterial = phongMat;
        break;
      case MaterialType.MeshStandardMaterial:
        const standardMat = new THREE.MeshStandardMaterial(material.property);
        this.applyCustomShaderChunk(standardMat, colorMapRepeat, structureRepeat, overlayColorMaps);
        returnMaterial = standardMat;
        break;
      default:
        const defaultMat = new THREE.MeshStandardMaterial(material.property);
        this.applyCustomShaderChunk(defaultMat, colorMapRepeat, structureRepeat, overlayColorMaps);
        returnMaterial = defaultMat;
        break;
    }

    returnMaterial.side = THREE.DoubleSide;
    returnMaterial.needsUpdate = true;
    return returnMaterial;
  }

  private applyCustomShaderChunk(
    mat: THREE.MeshPhongMaterial | THREE.MeshStandardMaterial,
    colorMapRepeat = 1,
    structureRepeat = 1,
    overlayColorMaps: OverlayTexture[] = [],
  ) {
    if (mat.normalMap) mat.normalMap.wrapS = mat.normalMap.wrapT = THREE.RepeatWrapping;
    if (mat.aoMap) mat.aoMap.wrapS = mat.aoMap.wrapT = THREE.RepeatWrapping;
    if (mat.map) mat.map.wrapS = mat.map.wrapT = THREE.RepeatWrapping;
    if (mat.displacementMap) mat.displacementMap.wrapS = mat.displacementMap.wrapT = THREE.RepeatWrapping;
    if ((mat as any).diffuseMap) (mat as any).diffuseMap.wrapS = (mat as any).diffuseMap.wrapT = THREE.RepeatWrapping;
  
    mat.onBeforeCompile = shader => {
      shader.uniforms.colorMapRepeat = { value: colorMapRepeat };
      shader.uniforms.structureRepeat = { value: structureRepeat };
      shader.uniforms.overlayColorMap1 = { value: overlayColorMaps[0] ? overlayColorMaps[0].overlayMap : null };
      shader.uniforms.overlayStrength1 = { value: overlayColorMaps[0] ? overlayColorMaps[0].overlayStrength : 0 };
      shader.uniforms.overlayCount = { value: overlayColorMaps.length };
  
      shader.fragmentShader = `
        ${overlayColorMaps.length ? '#define USE_OVERLAYMAP' : ''}
        uniform float colorMapRepeat;
        uniform float structureRepeat;
        uniform int overlayCount;
        uniform sampler2D overlayColorMap1;
        uniform float overlayStrength1;
        ${shader.fragmentShader}
      `;
  
      shader.fragmentShader = shader.fragmentShader.replace('#include <normal_fragment_maps>', normalMapChunk);
      shader.fragmentShader = shader.fragmentShader.replace('#include <map_fragment>', colorMapChunk);
      shader.fragmentShader = shader.fragmentShader.replace('#include <aomap_fragment>', aoMapChunk);
      shader.fragmentShader = shader.fragmentShader.replace('#include <roughnessmap_fragmentt>', roughnessMapChunk);    
    }
  }
}
