import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ParentObject: {
    width: '100%',
    height: '40px',
    borderBottom: `1px solid ${theme.palette.background.default}`,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#4f4e4e',
    },
  },
  ParentObjectSelected: {
    backgroundColor: `${theme.palette.primary.dark}!important`,
  },
  Extend: {
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  LoadingSpinner: {
    width: '23px',
    height: '23px',
  },
  Name: {
    flexGrow: 1,
    cursor: 'default',
  },
  Icon: {
    width: '40px',
    display: 'flex',
  },
  Visible: {
    width: '40px',
  }
}));
