import React from 'react';
import { Helmet } from 'react-helmet';

import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { createTitle } from 'shared/utils/misc.helper';

export interface MockupLayoutProps {
  title?: string,
  children: JSX.Element,
}

export function MockupLayout(props: MockupLayoutProps) {
  const { title = '', children } = props;

  return (
    <>
      <Helmet>
        <title>{createTitle(title)}</title>
      </Helmet>
      <Header />
      { children }
      <Footer />
    </>
  );
}
