import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  IndentObject: {
    paddingLeft: '35px',
    backgroundColor: theme.palette.background.default,
  },
  ChildObject: {
    width: '100%',
    height: '40px',
    paddingLeft: '15px',
    borderBottom: `1px solid ${theme.palette.background.default}`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
  },
  ChildObjectSelected: {
    backgroundColor: theme.palette.primary.dark,
  },
  Name: {
    flexGrow: 1,
  },
  ImageContainer: {
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(6.5),
  },
  PreviewImage: {
    height: '100%',
    width: '100%',
    textIndent: '-10000px',
  }
}));
