import { SceneLight } from "features/scenes/types";
import { SceneModel } from "modules/ThreeManager/Models/SceneModel";
import { Object3D, PerspectiveCamera, Scene, WebGLRenderer } from "three";
import { ThreeHelper } from "../helper/ThreeHelper";
import { Vector3d } from "../Models/Vector3d";
import { ControlsManagerAbstract } from "./ControlsManagerAbstract";
import { LightManagerAbstract } from "./LightManagerAbstract";
import { ModelManagerAbstract } from "./ModelManagerAbstract";
import { SceneManagerAbstract, StateUpdateMode } from "./SceneManagerAbstract";

export class SceneManager extends SceneManagerAbstract {

  private isAnimated = true;
  private latestUpdateTime = 0;

  constructor(
    public canvas: HTMLCanvasElement,
    public scene: Scene,
    public renderer: WebGLRenderer,
    public camera: THREE.Camera,
    public controlsManager: ControlsManagerAbstract,
    public modelManager: ModelManagerAbstract,
    public lightManager: LightManagerAbstract,
    id?: string,
  ) {
    super(id);
    this.controlsManager.init(this.render.bind(this), this.id);
    this.setupCanvas();
    if (this.isAnimated) this.animation();
  }

  render(forced = false) {
    const nowTimestamp = new Date().getTime();
    const timeForNewRendering = (nowTimestamp - this.latestUpdateTime) < (1000 / 30);

    if ((timeForNewRendering && !this.isAnimated) || forced) {
      this.renderer.render(this.scene, this.camera);
      this.latestUpdateTime = nowTimestamp;
    }
  }

  animation() {
    if (this.isAnimated) {
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animation.bind(this));
      this.latestUpdateTime = new Date().getTime();
    }
  }

  async createModel(model: SceneModel) {
    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectCreationInProgress, { storeObjectId: model.id });
    try {
      const createdModel = await this.modelManager.createModel(model);
      this.applyPosRotScale(createdModel, model);
      this.scene.add(createdModel);
      this.render();
      this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectCreated, { storeObjectId: model.id, threeObjectId: createdModel.id });
    } catch(e) {
      this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectCreationFailed, { threeObjectId: model.threeObjectId });
    }
  }

  async updateModel(model: SceneModel) {
    const toBeUpdatedModel = this.scene.getObjectById(model.threeObjectId);
    if (!toBeUpdatedModel) return;

    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectUpdateInProgress, { threeObjectId: model.threeObjectId });
    await this.modelManager.updateModel(toBeUpdatedModel, model);
    this.render();
    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectUpdated, { threeObjectId: model.threeObjectId });
  }

  async createLight(light: SceneLight) {
    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectCreationInProgress, { storeObjectId: light.id });
    const newLight = await this.lightManager.createLight(light);
    this.scene.add(newLight);
    this.render();
    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectCreated, { storeObjectId: light.id, threeObjectId: newLight.id });
  }

  async updateLight(light: SceneLight) {
    const toBeUpdatedLight = this.scene.getObjectById(light.threeObjectId);
    if (!toBeUpdatedLight || !this.isLight(toBeUpdatedLight)) return;

    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectUpdateInProgress, { threeObjectId: light.threeObjectId });
    await this.lightManager.updateLight(toBeUpdatedLight as THREE.Light, light);
    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectUpdated, { threeObjectId: light.threeObjectId });
    this.render();
  }

  async deleteObject(threeObjectId: number) {
    const foundObject = this.scene.getObjectById(threeObjectId);
    if (!foundObject) return;
    
    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectDeletionInProgress, { threeObjectId });
    this.controlsManager.setSelectedObject()
    this.scene.remove(foundObject);
    this.modelStateUpdateHandlersEmitAll(StateUpdateMode.ObjectDeleted, { threeObjectId });
    this.render();
  }

  getObjectAtScreenPosition(screenPosition: THREE.Vector2) {
    const selectedObject = ThreeHelper.raycastFromCamera(this.scene, this.camera, screenPosition);
    return selectedObject?.id || null;
  }

  getSceneAsPngImage() {
    this.controlsManager.setControlVisibility(false);
    const image = this.renderer.domElement.toDataURL("image/png");
    this.controlsManager.setControlVisibility(true);
    return image;
  }

  adjustSceneToCanvasSize() {
    const { width, height } = this.canvas;
    this.renderer.setSize(width, height);
    if (this.camera.type === new PerspectiveCamera().type) {
      const perspectiveCamera = this.camera as PerspectiveCamera;
      perspectiveCamera.aspect = width / height;
      perspectiveCamera.updateProjectionMatrix();
    }
  }

  private setupCanvas() {
    this.canvas.style.width = '100%';
    this.canvas.style.height= '100%';
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;
    this.adjustSceneToCanvasSize();
  }

  private isLight(object: Object3D) {
    return !!(object as THREE.Light).isLight;
  }

  private applyPosition(object: Object3D, position: Vector3d) {
    object.position.set(position.x, position.y, position.z);
  }

  private applyRotation(object: Object3D, rotation: Vector3d) {
    object.rotation.set(rotation.x * Math.PI, rotation.y * Math.PI, rotation.z * Math.PI);
  }

  private applyScale(object: Object3D, scale: Vector3d) {
    object.scale.set(scale.x, scale.y, scale.z);
  }

  private applyPosRotScale(object: Object3D, model: SceneModel) {
    this.applyPosition(object, model.position);
    this.applyRotation(object, model.rotation);
    this.applyScale(object, model.scale);
  }
}