import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  LoginInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  ForgotYourPasswordLabel: {

  },
  Logo: {
    height: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  NotRegisteredYetContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  GoogleLogin: {
    width: '100%',
    justifyContent: 'center',
  },
  SSO: {
    marginTop: theme.spacing(4),
  },
  OrText: {
    width: '100%',
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 'bold',
  }
}));
