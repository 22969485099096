import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './PhongMaterialProperty.styles';
import { Slider, Typography } from '@material-ui/core';

interface PhongMaterialPropertyProps {
  phongMaterialProperty: THREE.MeshPhongMaterialParameters,
  onShininessChange: (shininess: number) => void,
  onReflectivityChange: (reflectivity: number) => void,
}

export function PhongMaterialProperty(props: PhongMaterialPropertyProps) {
  const {
    phongMaterialProperty = {},
	onShininessChange,
	onReflectivityChange,
  } = props;
  const classes = useStyles(useTheme());

  return (
    <div className={classes.MaterialProperty}>
      <div className={classes.MaterialPropertySlider}>
        <Typography id="phongMaterial-Shininess" gutterBottom>
		  Shininess
        </Typography>
        <Slider
          value={phongMaterialProperty?.shininess || 0}
          onChange={(event: any, value: any) => onShininessChange(value)}
          onChangeCommitted={(event: any, value: any) => onShininessChange(value)}
          min={0}
          max={1024}
          step={1}
        />
      </div>
	  <div className={classes.MaterialPropertySlider}>
        <Typography id="phongMaterial-Reflectivity" gutterBottom>
		  Reflectivity
        </Typography>
        <Slider
          value={phongMaterialProperty?.reflectivity || 0}
          onChange={(event: any, value: any) => onReflectivityChange(value)}
          onChangeCommitted={(event: any, value: any) => onReflectivityChange(value)}
          min={0}
          max={1024}
          step={1}
        />
      </div>
    </div>
  );
}

/*
    color?: Color | string | number;
	specular?: Color | string | number;
	shininess?: number;
	opacity?: number;
	map?: Texture | null;
	lightMap?: Texture | null;
	lightMapIntensity?: number;
	aoMap?: Texture | null;
	aoMapIntensity?: number;
	emissive?: Color | string | number;
	emissiveIntensity?: number;
	emissiveMap?: Texture | null;
	bumpMap?: Texture | null;
	bumpScale?: number;
	normalMap?: Texture | null;
	normalMapType?: NormalMapTypes;
	normalScale?: Vector2;
	displacementMap?: Texture | null;
	displacementScale?: number;
	displacementBias?: number;
	specularMap?: Texture | null;
	alphaMap?: Texture | null;
	envMap?: Texture | null;
	combine?: Combine;
	reflectivity?: number;
	refractionRatio?: number;
	wireframe?: boolean;
	wireframeLinewidth?: number;
	wireframeLinecap?: string;
	wireframeLinejoin?: string;
	skinning?: boolean;
	morphTargets?: boolean;
	morphNormals?: boolean;
*/