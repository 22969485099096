import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { Link } from 'react-router-dom';

import useStyles from './TemplateSceneDetailsBox.styles';
import TemplateSceneApi from 'shared/utils/api/templateScene';
import { TemplateScene } from 'features/templateScenes/types';

interface TemplateSceneDetailsBoxProps {
  templateScene: TemplateScene,
}

export function TemplateSceneDetailsBox(props: TemplateSceneDetailsBoxProps) {
  const classes = useStyles(useTheme()); 
  const {
    name = 'Template Scene',
    description = 'No description available',
    slug,
  } = props.templateScene;

  const applyLineBreaks = (str: string) => {
    return str.split(/\r\n|\n|\r|\\n/gm).map(line => <p>{line}</p>);
  }

  return (
    <div className={classes.TemplateScenesDetails}>
      <div className={classes.TemplateScenesTitle}>
        <Link to="/mockupscenes">
          <ArrowBackOutlinedIcon className={classes.BackArrow} />
        </Link>
        <Typography variant="h3" component="h1">
          { name }
        </Typography>
      </div>
      <div className={classes.TemplateSceneDetailsInfoContainer}>
        <img
          alt=""
          className={classes.TemplateScenesDetailsImage}
          src={new TemplateSceneApi().createTemplateScenePreviewThumbImageUrl(slug)}
        />
        <div className={classes.TemplateScenesDetailsText}>
          <div>
            <Typography className={classes.DescriptionText} variant="h5" component="h2" gutterBottom>
              Description
            </Typography>
            { applyLineBreaks(description || 'No description available') }
            <Button
              className={classes.OpenInEditorButton}
              variant="contained"
              color="primary"
              href={`/mockupcreator/scene/${slug}`}
            >
              Open in Creator
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
