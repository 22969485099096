import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  LandingExample: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    color: '#121212',
    backgroundColor: '#FFF0DF',
  },
  ExampleGallery: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  ExampleImages:{
    maxWidth: '300px',
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  }
}));
