import * as THREE from 'three';
import { LightManager } from '../Manager/LightManager';
import { SceneManager } from "../Manager/SceneManager";
import { ControlsFactory } from './ControlsFactory';
import { ModelManagerFactory } from './ModelManagerFactory';
import { ThreeObjectFactory } from './ThreeObjectFactory';

export class SceneManagerFactory {
  static createEmptySceneManager(document: Document, divElement: HTMLDivElement, id?: string) {
    const canvas = ThreeObjectFactory.createCanvas(document, divElement);
    const scene = ThreeObjectFactory.createScene();
    const renderer = ThreeObjectFactory.createRender(canvas);
    const camera = ThreeObjectFactory.createCamera(canvas.width, canvas.height);
    const controlsManager = ControlsFactory.createControlsManager(scene, camera, renderer, canvas);
    const modelManager = ModelManagerFactory.createModelManager();
    const lightManager = new LightManager();

    scene.add(new THREE.AmbientLight(0xffffff, 0.7));
    const light = new THREE.PointLight(0xffffff, 4, 50);
    light.position.set(8, 6, 6);
    scene.add(light);
    
    const sceneManager = new SceneManager(canvas, scene, renderer, camera, controlsManager, modelManager, lightManager, id);
    return sceneManager;
  }

}