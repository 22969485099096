import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './LandingFeature.styles';
import useLandingPageStyles from 'modules/LandingPage/views/LandingPage/Landing.styles';

import RealisticMaterial from './realistic_materials.png';
import PatternDemo from './pattern_demo.mp4';
import MappingDemo from './mapping_demo.mp4';

export const LandingFeature = () => {
  const classes = useStyles(useTheme());
  const landingPageClasses = useLandingPageStyles(useTheme());

  return (
    <section className={classes.LandingFeature} id="features">
      <div className={`${landingPageClasses.LandingBody}`}>
        <div className={classes.SubHeadlineContainer}>
          <span className={landingPageClasses.SubHeadline}>Sell a vision</span>
          <span className={landingPageClasses.SubHeadline}>matching your designs</span>
        </div>
        <div className={classes.TwoColumns}>
          <div className={`${classes.Column} ${classes.TextColumn}`}>
            <div className={landingPageClasses.FeatureTitle}>
              <span className={landingPageClasses.ColoredFont}>Realistic</span> Materials
            </div>
            <div>
              Create convincing mockups with realistic material structures added to your design.
            </div>
          </div>
          <div className={classes.Column}>
            <img alt="Realistic fabric on pillow" className={classes.FeatureVideo} src={RealisticMaterial} />
          </div>
        </div>
        <div className={classes.TwoColumns}>
          <div className={`${classes.Column} ${classes.TextColumn}`}>
            <div className={landingPageClasses.FeatureTitle}>
              Wrap your <span className={landingPageClasses.ColoredFont}>Pattern</span>
            </div>
            <div>
              With just one click wrap your pattern around a model and adjust it easily to fit your vision.
            </div>
          </div>
          <div className={classes.Column}>
            <video className={classes.FeatureVideo} autoPlay={true} loop={true} preload="auto" muted>
              <source src={PatternDemo} type="video/mp4" ></source>
            </video>
          </div>
        </div>
        <div className={classes.TwoColumns}>
          <div className={`${classes.Column} ${classes.TextColumn}`}>
            <div className={landingPageClasses.FeatureTitle}>
              ... or <span className={landingPageClasses.ColoredFont}>Designs</span>
            </div>
            <div>
              Position your design easily on 3d models and create a unique representation.
            </div>
          </div>
          <div className={classes.Column}>
            <video className={classes.FeatureVideo} autoPlay={true} loop={true} preload="auto" muted>
              <source src={MappingDemo} type="video/mp4" ></source>
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}
