import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ColorPicker: {
    '& input': {
      color: 'inherit',
      backgroundColor: 'inherit',
      borderRadius: '2px',
      boxShadow: '#666666 0px 0px 0px 1px inset !important',
    },
    '& span': {
      color: 'inherit !important',
    }
  },
}));
