import React from 'react';
import { Dialog } from 'shared/components/Dialog';
import useStyles from './SaveDialog.styles';
import { useTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

export interface SaveDialogProps {
  isOpen: boolean;
  sceneImage: string;
  isSaving: boolean;
  onCloseClick: () => void;
  onSaveClick: (name: string, sceneImage: string) => void,
}

export function SaveDialog(props: SaveDialogProps) {
  const {
    isOpen = true,
    sceneImage,
    onCloseClick,
    onSaveClick,
  } = props;

  const [ sceneName, setSceneName ] = React.useState('');

  const classes = useStyles(useTheme());

  return (
    <Dialog
      isOpen={isOpen}
      title={'Save Scene'}
      onCloseClick={onCloseClick}
      buttons={[
        { color: 'inherit', variant: 'text', label: 'Cancel', onClick: () => onCloseClick()},
        { color: 'primary', variant: 'contained', label: 'Save', onClick: () => onSaveClick(sceneName, sceneImage)}
      ]}
    >
      <div className={classes.SavePreviewImageContainer}>
        <img alt="Preview" className={classes.SavePreviewImage} src={sceneImage} />
      </div>
      <TextField
        id="SceneName"
        value={ sceneName }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSceneName(event.target.value)}
        variant="outlined"
        fullWidth
        size="small"
        placeholder="Enter a name for the scene"
      />
    </Dialog>
  );
}