import * as THREE from 'three';
import { TextureLoader } from "../Loader/TextureLoader";
import { TextureFactoryAbstract } from './AbstractFactories/TextureFactoryAbstract';

export class TextureFactory extends TextureFactoryAbstract {
  async loadTexture(key: string) {
    const textureLoader = new TextureLoader();
    return await textureLoader.load(key);
  }

  async createMappingTexture(textureForMesh: string): Promise<THREE.Texture | null> {
    const texture = await this.loadTexture(textureForMesh);
    if (!texture) return null;
    this.applySettingsToTexture(texture);
    return texture;
  }

  async createPatternTexture(textureForMesh: string, repeat = 1): Promise<THREE.Texture | null> {
    const texture = await this.loadTexture(textureForMesh);
    if (!texture) return null;
    this.applySettingsToTexture(texture, repeat);
    return texture;
  }

  private applySettingsToTexture(texture: THREE.Texture, repeat?: number) {
    texture.minFilter = THREE.LinearFilter;
    texture.anisotropy = 16;
    texture.magFilter = THREE.LinearFilter;
    texture.minFilter = THREE.LinearMipmapLinearFilter;
    texture.flipY = false;
    texture.encoding = THREE.sRGBEncoding;
  
    const texRatio = (texture.image.height / texture.image.width) || 1;
  
    texture.repeat.set((repeat || 1) * texRatio, repeat || 1);
    if (repeat !== 1) texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.rotation = 0;
  }
}
