import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import useStyles from './Login.styles';
import { createTitle } from 'shared/utils/misc.helper';
import { LoginDialog, LoginDialogViewState } from 'shared/components/LoginDialog';
import { previousUrls } from 'shared/utils/history';

export function Login() {
  const classes = useStyles(useTheme());
  const [ loginDialogViewState, setLoginDialogViewState ] = React.useState(LoginDialogViewState.Signin);

  const createRedirectLink = () => {
    if (previousUrls.length <= 1) return '/mockupcreator';
    return previousUrls[previousUrls.length - 2];
  }

  return (
    <>
      <Helmet>
        <title>{createTitle('Login')}</title>
      </Helmet>
      <div className={classes.backButton}></div>
      <LoginDialog
        isOpen={true}
        loginViewState={loginDialogViewState}
        redirectOnSuccessTo={createRedirectLink()}
        onCloseClick={() => {}}
        onSwitchViewStateClick={(viewState: LoginDialogViewState) => setLoginDialogViewState(viewState)}
      />
    </>
  );
}
