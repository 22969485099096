import { RootState } from "rootReducer";
import { generateUUID } from "shared/utils/misc.helper";

export abstract class ControllerAbstract {
  readonly id: string;

  constructor() {
    this.id = generateUUID();
  }

  abstract processIncomingState(state: RootState): void;
}