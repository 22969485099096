import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  AccountInfoPopover: {
    paddingTop: theme.spacing(2),
    paddingButton: theme.spacing(3),
  },
  AccountInfo: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  },
  AccountBadge: {
    backgroundColor: 'burlywood',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '35px',
    width: '35px',
    borderRadius: '50px',
    fontSize: '1.1rem',
  },
  AccountEmail: {
    width: '200px',
    overflow: 'hidden',
    marginLeft: theme.spacing(1),
  },
  AccountButtonList: {
    '& ul': {
      paddingLeft: 0,
      '& li': {
        display: 'flex',
        alignItems: 'center',
        listStyle: 'none',
        height: '35px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: '100%',
        fontSize: '0.9rem',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        '& svg': {
          marginRight: theme.spacing(1),
        },
      }
    }
  },
}));
