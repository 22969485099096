import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Footer.styles';

export const Footer = () => {
  const classes = useStyles(useTheme());

  return (
    <footer className={classes.FooterContainer}>
      <div className={classes.Footer}>
        <div className={classes.Column1}>
          <div className={classes.Headline}>
            About
          </div>
          <div className={classes.AboutText}>
            MockupFox allows designers to present their work as best as possible with highly customizable mockups.
          </div>
          <div className={classes.Copyright}>&copy; 2022 Holistic Coder GmbH</div>
        </div>
        <div className={classes.Column2}>
          <div className={classes.Headline}>
            Company
          </div>
          <div className={classes.Column2Links}>
            <Link className={classes.Column2Links} rel="nofollow" to="/privacy">
              Data Protection
            </Link>
          </div>
          <div className={classes.Column2Links}>
            <Link className={classes.Column2Links} rel="nofollow" to="/terms">
              Terms and Conditions
            </Link>
          </div>
          <div className={classes.Column2Links}>
            <Link className={classes.Column2Links} rel="nofollow" to="/impress">
              Impress
            </Link>
          </div>
        </div>
        <div className={classes.Column3}>
          
        </div>
      </div>
    </footer>
  );
}
