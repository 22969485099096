import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Header.styles';
import { useSelector } from 'react-redux';
import Logo from 'shared/assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { AccountPanel } from '../AccountPanel';
import { RootState } from 'rootReducer';

interface HeaderProps {
}

export function Header(props: HeaderProps) {
  const classes = useStyles(useTheme());
  const { user } = useSelector((state: RootState) => state);

  return (
    <>
      <header className={classes.HeaderContainer}>
        <div className={classes.Header}>
          <img alt="" className={classes.Logo} src={Logo} />
          <ul className={classes.MainMenu}>
            <li className={classes.MainMenuItem}>
              <Link to='/' className={classes.MenuLink}>Landing Page</Link>
            </li>
            <li className={classes.MainMenuItem}>
              <Link to='/mockupscenes' className={classes.MenuLink}>Templates</Link>
            </li>
            {
              user.data && (
                <li className={classes.MainMenuItem}>
                  <Link to='/mockupcreator' className={classes.MenuLink}>Mockup Creator</Link>
                </li>
              )
            }
            {
              //<li className={classes.MainMenuItem}>
              //  <Link to='/blog' className={classes.MenuLink}>Blog</Link>
              //</li>
            }
          </ul>
          <div className={classes.HeaderItemContainer}>
            {
              // <CreateSceneButton />
            }
            <AccountPanel />
          </div>
        </div>
      </header>
    </>
  );
}
