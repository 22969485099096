import { combineReducers } from '@reduxjs/toolkit'

import models from 'features/models/models';
import ui from 'features/ui/ui';
import imageEdit from 'features/imageEdit/imageEdit';
import user from 'features/user/user';
import templateScenes from 'features/templateScenes/templateScenes';
import scenes from 'features/scenes/scenes';

const rootReducer = combineReducers({
  models,
  ui,
  imageEdit,
  user,
  templateScenes,
  scenes,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;