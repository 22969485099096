import { fabric } from 'fabric';
import { SimplePaletteColorOptions } from '@material-ui/core';

import theme from 'shared/assets/theme/darkTheme';
import { generateUUID } from 'shared/utils/misc.helper';

const primaryColor = (theme.palette?.primary as SimplePaletteColorOptions).main || '#585858';
fabric.Object.prototype.cornerSize = 40;
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = primaryColor;
fabric.Object.prototype.rotatingPointOffset = 60;

export enum FabricObjectType {
  Text,
  Circle,
  Rectangle,
  Image,
  Pattern,
}

export class ImageManager {
  private static instance: ImageManager;
  private grid: fabric.Group | null = null;
  public canvas: fabric.Canvas | null = null;

  private constructor() { }

  public createGrid(gridAmount = 25) {
    if (!this.canvas) return;
    if (this.grid) this.canvas.remove(this.grid);

    const gridWidth = this.canvas.width || 100;
    const gridHeight = this.canvas.height || 100;

    const lineArray = [];
    const lineOption = {stroke: 'rgba(0,0,0,0.1)', strokeWidth: 1, selectable:false, strokeDashArray: [3, 3], moveCursor: 'default', hoverCursor: 'default'};

    const gridElementWidth = gridWidth/gridAmount;
    for(let i = gridAmount; i--;){
      lineArray.push( new fabric.Line([gridElementWidth*i, 0, gridElementWidth*i, gridHeight], lineOption) );
    }

    const gridElementHeight = gridHeight/gridAmount;
    for(let i = gridAmount; i--;){
      lineArray.push( new fabric.Line([0, gridElementHeight*i, gridWidth, gridElementHeight*i], lineOption) );
    }

    this.grid = new fabric.Group(lineArray, {left: 0, top: 0, selectable: false, moveCursor: 'default', hoverCursor: 'default'});
    this.setBackgroundWithGroup(this.grid);
  }

  public bringForward(objectId?: string) {
    let object = null
    if(!objectId && this.canvas) object = this.canvas?.getActiveObject();
    if(objectId) object = this.getObjectWithId(objectId);
    if(object) this.canvas?.bringForward(object);
  }

  public bringBackward(objectId?: string) {
    let object = null
    if(!objectId && this.canvas) object = this.canvas?.getActiveObject();
    if(objectId) object = this.getObjectWithId(objectId);
    if(object) this.canvas?.sendBackwards(object);
  }

  public getAllObjects() {
    return this.canvas?.getObjects();
  }

  public selectObject(objectId?: string) {
    let object = null
    if(!objectId && this.canvas) object = this.canvas?.getActiveObject();
    if(objectId) object = this.getObjectWithId(objectId);
    if(object) {
      this.canvas?.setActiveObject(object);
      this.canvas?.requestRenderAll();
    }
  }

  public addPatternWithImgUrl(fullUrl: string) {
    fabric.Image.fromURL(fullUrl, oImg => {
      if (!this.canvas) return;
      const { width = 1, height = 1 } = this.canvas;

      const pattern = this.createPattern(oImg, height / 1);

      const object = new fabric.Rect({
        left: 0.1 * width,
        top: 0.1 * height,
        fill: pattern,
        width: 0.8 * width,
        height: 0.8 * height,
        objectCaching: false,
        // save original Image
        data: { id: generateUUID(), type: FabricObjectType.Pattern, image: oImg, repeat: 1 },
      });
      
      this.canvas.add(object);
    })
  }

  public addImageWithImgUrl(fullUrl: string) {
    fabric.Image.fromURL(fullUrl, oImg => {
      if (!this.canvas) return;
      const { width = 1, height = 1 } = this.canvas;
      const imageWidth = oImg.width || 1;
      const imageHeight = oImg.height || 1;
      const scale = Math.min(0.8 * width / imageWidth, 0.8 * height / imageHeight);

      oImg.set({
        top: 0.1 * scale * imageHeight,
        left: 0.1 * scale * imageWidth,
        scaleX: scale,
        scaleY: scale,
        data: { id: generateUUID(), type: FabricObjectType.Image },
      });
      this.canvas.add(oImg).renderAll();
    }, { crossOrigin: 'anonymous' });
  }

  public addFabricObjects(objects: fabric.Object[]) {
    fabric.util.enlivenObjects(objects, (enlivenedObjects: any) => {
      enlivenedObjects.forEach((obj: any) => {
        if (obj.data.image) {
          const img = JSON.parse(obj.data.image);
          fabric.Image.fromURL(img.src, oImg => obj.data.image = oImg);
        }
        this.canvas?.add(obj);
      });
      this.canvas?.renderAll();
    }, 'fabric');
  }

  public getObjectWithId(objectId: string) {
    return this.canvas?.getObjects().find(object => object.data?.id === objectId);
  }

  public setOverlayWithImgUrl(fullUrl: string) {
    if (fullUrl.includes('.svg')) {
      fabric.loadSVGFromURL(fullUrl, (objects: fabric.Object[]) => this.setOverlayWithGroup(new fabric.Group(objects)));
    } else {
      fabric.Image.fromURL(fullUrl, this.setOverlayWithImage);
    }
  }

  public bind(canvas: fabric.Canvas) {
    if(!canvas) return;
    this.canvas = canvas;
    this.createGrid();
  }

  public async getBase64Image(size = 512) {
    const time = new Date().getTime();

    if (!this.canvas) return '';
    const staticCanvas = document.createElement('canvas');
    staticCanvas.width = size;
    staticCanvas.height = size;
    const ctx = staticCanvas.getContext('2d') as CanvasRenderingContext2D;
    if (this.canvas.overlayImage) this.canvas.overlayImage.visible = false;
    const activeObject = this.canvas.getActiveObject();
    this.canvas.discardActiveObject().renderAll();
    ctx.drawImage(this.canvas.getElement(), 0, 0, size, size);
    if (this.canvas.overlayImage) this.canvas.overlayImage.visible = true;
    this.canvas.setActiveObject(activeObject);
    this.canvas.renderAll();
    const dataUrl = staticCanvas.toDataURL();

    console.log(`time delta: ${new Date().getTime() - time}`);
    return dataUrl || '';
  }

  public drawText(textParams?: fabric.ITextboxOptions) {
    if(!this.canvas) return;

    const object = new fabric.Textbox('Text', textParams || {
      left: 50,
      top: 50,
      width: 150,
      fontSize: 200,
      data: { id: generateUUID(), type: FabricObjectType.Text },
    });
    
    this.canvas.add(object);
  }

  public drawCircle(circleParams?: fabric.ICircleOptions) {
    if(!this.canvas) return;

    const object = new fabric.Circle(circleParams || {
      left: 200,
      top: 200,
      fill: 'red',
      radius: 200,
      data: { id: generateUUID(), type: FabricObjectType.Circle },
    });
    
    this.canvas.add(object);
  }

  public drawRectangle(rectParams?: fabric.IRectOptions) {
    if(!this.canvas) return;

    const object = new fabric.Rect(rectParams || {
      left: 200,
      top: 200,
      fill: 'red',
      width: 200,
      height: 200,
      data: { id: generateUUID(), type: FabricObjectType.Rectangle },
    });
    
    this.canvas.add(object);
  }

  public resizeCanvas(width: number, canvasSize = 2048) {
    this.canvas?.setDimensions({ width: canvasSize, height: canvasSize}, {backstoreOnly: true});
    this.createGrid();
  }

  public deleteObject() {
    this.canvas?.remove(this.canvas?.getActiveObject());
  }

  public changeRepeatCount(repeatCount: number) {
    const selectedObject = this.canvas?.getActiveObject();
    if (!selectedObject) return;
    selectedObject.fill = this.createPattern(selectedObject.data.image, (selectedObject.height || 1) / repeatCount);
    selectedObject.data.repeat = repeatCount;
    this.canvas?.requestRenderAll();
  }

  public changeColor(color: string) {
    const selectedObject = this.canvas?.getActiveObject();
    if (!selectedObject) return;
    selectedObject.fill = color;
    selectedObject.dirty = true;
    this.canvas?.requestRenderAll();
  }

  static getInstance(): ImageManager {
    if (!ImageManager.instance) {
      ImageManager.instance = new ImageManager();
    }

    return ImageManager.instance;
  }

  private createPattern(oImg: fabric.Image, sizePx: number) {
    oImg.scaleToHeight(sizePx);
    const patternSourceCanvas = new fabric.StaticCanvas(null);
    patternSourceCanvas.add(oImg);
    patternSourceCanvas.setDimensions({
      width: oImg.getScaledWidth(),
      height: oImg.getScaledHeight(),
    });
    patternSourceCanvas.renderAll();

    const pattern = new fabric.Pattern({
      source: (patternSourceCanvas.getElement() as any),
      repeat: 'repeat'
    });

    return pattern;
  }

  private setOverlayWithImage(oImg: fabric.Image) {
    if (!this.canvas) return;
    this.canvas.setOverlayImage(oImg, this.canvas.renderAll.bind(this.canvas), {
      scaleX: (this.canvas?.width || 1) / (oImg?.width || 1),
      scaleY: (this.canvas?.height || 1) / (oImg?.height || 1)
    });
  }

  private setOverlayWithGroup(group: fabric.Group) {

    // Colorize the svg image
    group.getObjects().forEach(object => {
      object.set({
        stroke: primaryColor,
        strokeDashArray: [1, 1],
      });
    });

    group.cloneAsImage((oImg: fabric.Image) => {
      if (!this.canvas) return;
      this.canvas.setOverlayImage(oImg, this.canvas.renderAll.bind(this.canvas), {
        scaleX: (this.canvas?.width || 1) / (oImg?.width || 1),
        scaleY: (this.canvas?.height || 1) / (oImg?.height || 1)
      });
    })
  }

  private setBackgroundWithGroup(group: fabric.Group) {
    group.cloneAsImage((oImg: fabric.Image) => {
      if (!this.canvas) return;
      this.canvas.setBackgroundImage(oImg, this.canvas.renderAll.bind(this.canvas), {
        scaleX: (this.canvas?.width || 1) / (oImg?.width || 1),
        scaleY: (this.canvas?.height || 1) / (oImg?.height || 1)
      });
    })
  }
}