import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ImageLayerSelection: {

  },
  ImageLayerSelectionElement: {
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
    borderBottom: `1px solid ${theme.palette.background}`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  ImageLayerSelectionElementSelected: {
    backgroundColor: theme.palette.primary.dark,
  },
  PreviewImage: {
    height: '32px',
    width: '32px',
  },
  LeftPart: {
    display: 'flex',
  }
}));
