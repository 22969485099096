import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  SceneToolbar: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  DocumentToolbar: {
    display: 'grid',
    marginBottom: theme.spacing(1.5),

  },
  ModelToolbar: {
    display: 'grid',
  },
}));
