import * as THREE from 'three';
import { LightManagerAbstract } from "./LightManagerAbstract";
import { SceneLight, SceneLightType } from "features/scenes/types";

export class LightManager extends LightManagerAbstract {

  async createLight(light: SceneLight) {
    const returnLight = this.createThreeLightInstance(light);
    this.updateLight(returnLight, light);
    return returnLight;
  }

  async updateLight(toBeUpdatedLight: THREE.Light, light: SceneLight) {
    toBeUpdatedLight.color = new THREE.Color(light.property.color);
    toBeUpdatedLight.intensity = light.property.intensity;
    toBeUpdatedLight.position.set(light.position.x, light.position.y, light.position.z);
    return toBeUpdatedLight;
  }

  private createThreeLightInstance(light: SceneLight) {
    const { castShadow, property } = light;
    const { color, intensity } = property;
    const threeColor = new THREE.Color(color);

    switch(light.type) {
      case SceneLightType.DirectionalLight:
        return this.setupLight(new THREE.DirectionalLight(threeColor, intensity), castShadow);
      case SceneLightType.SpotLight:
        return this.setupLight(new THREE.SpotLight(threeColor, intensity), castShadow);
      case SceneLightType.PointLight:
        return this.setupLight(new THREE.PointLight(threeColor, intensity), castShadow);
      default:
        return this.setupLight(new THREE.DirectionalLight(threeColor, intensity), castShadow);
    }
  }

  private setupLight(basicLight: THREE.DirectionalLight | THREE.SpotLight | THREE.PointLight, castShadow = true) {
    basicLight.castShadow = castShadow;
    basicLight.shadow.bias = -0.00003;
    basicLight.shadow.mapSize.width = 2048 * 2;
    basicLight.shadow.mapSize.height = 2048 * 2;
    basicLight.shadow.radius = 2;

    if (basicLight.shadow.camera instanceof THREE.OrthographicCamera) {
      const d = 7;
      basicLight.shadow.camera.left = - d;
      basicLight.shadow.camera.right = d;
      basicLight.shadow.camera.top = d;
      basicLight.shadow.camera.bottom = - d;
    }

    return basicLight;
  }
}