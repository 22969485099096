import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  DialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    color: 'white',
  },
  CloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  DialogContent: {
    padding: theme.spacing(2),
    color: 'white',
  },
  DialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  }
}));
