import React from 'react';
import JoyRide, { CallBackProps, Step, TooltipRenderProps } from 'react-joyride';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import GetAppIcon from '@material-ui/icons/GetApp';

import useStyles from './Tour.styles';
import { Button } from '@material-ui/core';

import MouseLeftMove from './mouse_left_move.svg';
import MouseRightMove from './mouse_right_move.svg';
import MouseZoom from './mouse_middle_scroll.svg';
import HeroVideo from './mockupfox_demo.mp4';
import ModelAddVideo from './model_add.mp4';
import ObjectToolsVideo from './object_tools.mp4';
import ObjectSelectVideo from './object_select.mp4';
import ObjectMappingVideo from './object_mapping.mp4';

const Tooltip = (props: TooltipRenderProps) => {
  const {
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
  } = props;

  const classes = useStyles(useTheme());

  return (
    <div {...tooltipProps} className={classes.Tooltip}>
      <div className={classes.TooltipHeader}>
        <span>{ step.title }</span>
        <span onClick={closeProps.onClick} className={classes.TooltipClose}><CloseIcon/></span>
      </div>
      <div className={classes.TooltipBody}>
        { step.content }
      </div>
      <div className={classes.TooltipFooter}>
        <div>
          {
            index > 0 &&
            <Button onClick={backProps.onClick} size="small" variant="outlined" color="inherit">{backProps.title}</Button>
          }
        </div>
        <div>
          {
            continuous
            ? <Button onClick={primaryProps.title === step?.locale?.last ? closeProps.onClick : primaryProps.onClick} size="small" variant="contained" color="primary">{primaryProps.title}</Button>
            : <Button onClick={closeProps.onClick} size="small" variant="contained" color="primary">{closeProps.title}</Button>
          }
        </div>
      </div>
    </div>
  )
};

const testStepDef: Step[] = [
  {
    target: "#sceneToolbar",
    title: "Welcome to MockupFox",
    content: (
      <>
        <div>
          <video style={{width: '100%'}} autoPlay={true} loop={true} preload="auto" muted>
            <source src={HeroVideo} type="video/mp4" ></source>
          </video>
        </div>
        <div style={{padding: '16px'}}>
          <div>
            Tell a story with your designs and a matching mockup. You are totally free how to present your design with just some clicks.
          </div>
          <br/>
          <div>
            This tour will give you a short introduction and will take only a view minutes.
          </div>
        </div>
      </>
    ),
    placement: "center",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    }
  },
  {
    target: "#modelSelection",
    title: "Model Selection",
    content: (
      <>
        <div>
          <video style={{width: '100%'}} autoPlay={true} loop={true} preload="auto" muted>
            <source src={ModelAddVideo} type="video/mp4" ></source>
          </video>
        </div>
        <div style={{padding: '16px'}}>
          <div>
            When you click on a thumbnail the corresponding model will be imported.
          </div>
          <div>
            Feel free to add more than one model to a scene.
          </div>
        </div>
      </>
    ),
    placement: "right",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    }
  },
  {
    target: "#sceneToolbar",
    title: "Scene Toolbar",
    content: (
      <>
        <div>
          <video style={{width: '100%'}} autoPlay={true} loop={true} preload="auto" muted>
            <source src={ObjectToolsVideo} type="video/mp4" ></source>
          </video>
        </div>
        <div style={{padding: '16px'}}>
          <div>
            With the toolbar you can easily make changes:
          </div>
          <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '8px'}}>
            <InsertDriveFileIcon />
            <span style={{'marginLeft': '8px'}}>New Scene</span>
          </div>
          <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '8px'}}>
            <GetAppIcon />
            <span style={{'marginLeft': '8px'}}>Export view as png</span>
          </div>
          <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '16px'}}>
            <ControlCameraIcon />
            <span style={{'marginLeft': '8px'}}>Move selected model</span>
          </div>
          <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '8px'}}>
            <AutorenewIcon />
            <span style={{'marginLeft': '8px'}}>Rotate selected model</span>
          </div>
          <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '8px'}}>
            <PhotoSizeSelectSmallIcon />
            <span style={{'marginLeft': '8px'}}>Scale selected model</span>
          </div>
          <div style={{'display': 'flex', 'alignItems': 'center', 'marginTop': '8px'}}>
            <DeleteIcon />
            <span style={{'marginLeft': '8px'}}>Delete selected model</span>
          </div>
        </div>
      </>
    ),
    placement: "right",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    }
  },
  {
    target: "#objectSelection",
    title: "Object Selection",
    content: (
      <>
        <div>
          <video style={{width: '100%'}} autoPlay={true} loop={true} preload="auto" muted>
            <source src={ObjectSelectVideo} type="video/mp4" ></source>
          </video>
        </div>
        <div style={{padding: '16px'}}>
          <div>
            You can find an overview of all the models in this selection list.
          </div>
          <div>
            A model might have several parts for customization.
            These parts can be selected here.
          </div>
        </div>
      </>
    ),
    placement: "left",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    }
  },
  {
    target: "#objectProperties",
    title: "Object Properties",
    content: (
      <>
        <div>
          <video style={{width: '100%'}} autoPlay={true} loop={true} preload="auto" muted>
            <source src={ObjectMappingVideo} type="video/mp4" ></source>
          </video>
        </div>
        <div style={{padding: '16px'}}>
          <div style={{marginBottom: '8px'}}>Change the color or pattern of the selected model:</div>
          <div><b>Color:</b> The color is applied to the whole model</div>
          <div><b>Pattern:</b> A repeated pattern is applied</div>
          <div><b>Mapping:</b> Custom placement of graphics</div>
        </div>
      </>
    ),
    placement: "left-end",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    }
  },
  {
    target: "#workArea",
    title: "Mouse Controller",
    content: (
      <>
        <div style={{padding: '16px'}}>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '16px'}}>
            <img alt="Mouse Move with left click" src={MouseLeftMove} style={{height: '45px', marginRight: '8px'}}/>
            Move camera around
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '16px'}}>
            <img alt="Mouse Move with right click"  src={MouseRightMove} style={{height: '45px', marginRight: '8px'}}/>
            Move the point the camera is looking at
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '16px', paddingLeft: '12px', paddingRight: '12px'}}>
            <img alt="Mouse zoom with center button" src={MouseZoom} style={{height: '45px', marginRight: '8px'}}/>
            Zoom in/out
          </div>
        </div>
      </>
    ),
    placement: "center",
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    }
  },
]

interface TourProps {
  stepDefinition?: any,
  onClose: () => void,
}

export function Tour(props: TourProps) {
  const {
    stepDefinition = testStepDef,
    onClose = () => {},
  } = props;

  const callback = (data: CallBackProps) => {
    const { action } = data;
    if (action === 'close') {
      onClose();
      setOpen(false);
    }
  };

  const [ isOpen, setOpen ] = React.useState(true);

  return (
    <>
      <JoyRide
        steps={stepDefinition}
        continuous={true}
        run={isOpen}
        showProgress={true}
        callback={callback}
        tooltipComponent={Tooltip}
        disableOverlayClose={true}
        locale={{
          last: `Let's start`,
        }}
      />
    </>
  );
}
