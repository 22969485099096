import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Separator.styles';

export function Separator() {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.Separator} />
  );
}
