export enum ObjectEditOption {
  Move = 'Move' as any,
  Rotate = 'Rotate' as any,
  Scale = 'Scale' as any,
}

export enum SimulatorViewState {
  Simulator3d = 'Simulator3d',
  MappingEditor = 'MappingEditor',
  TemplateScenes = 'TemplateScenes',
}

export interface Ui {
  selectedObjectSceneId: string;
  editorSceneId: string;
  selectedChildObjectName?: string;
  objectEditOption: ObjectEditOption;
  simulatorViewState: SimulatorViewState;
  collapsedObjects: string[];                // collapsed objects (stores object ids; internalSceneId)
  currentGuidingTooltipStep: number;
  tourOpen: boolean;
}