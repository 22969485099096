import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  LoginDialog: {
    margin: 0,
    color: '#424242',
    backgroundColor: '#fbfbfb',
    display: 'flex',
  },
  Pattern: {
    width: '25%',
    '& img': {
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  LoginContent: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(2),
    }
  },
  LoginDialogTitle: {
    fontSize: '2rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    }
  },
  LoginDialogBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    }
  },
  SeparationLine: {
    width: '100%',
    borderBottom: '1px solid #EEEEEE',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  LoginDialogLink: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  LoginDialogButtonBar: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing(5),
    backgroundColor: 'white',
  },
  LoginError: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
    marginTop: '8px',
    textAlign: 'center',
  },
  ButtonSpinner: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  LoginSuccess: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.success.main,
    marginTop: '8px',
    textAlign: 'center',
  }
}));
