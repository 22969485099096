import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ChildrenDetails: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  AvailabilityList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    "& li": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  AvailabilityIcon: {
    marginLeft: theme.spacing(1),
    opacity: 0.6,
  },
  Available: {
    color: theme.palette.success.main,
  },
  NonAvailable: {
    color: theme.palette.error.main,
  }
}));
