import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import useStyles from './TemplateSceneDetailsModels.styles';
import { Scene3d } from 'features/scenes/types';
import ModelApi from 'shared/utils/api/model';
import { Typography } from '@material-ui/core';
import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';

interface TemplateSceneDetailsModelsProps {
  template3DScene?: Scene3d,
}

export function TemplateSceneDetailsModels(props: TemplateSceneDetailsModelsProps) {
  const classes = useStyles(useTheme());
  const [ modelData, setModelData ] = React.useState<Array<SceneModel>>([]);

  React.useEffect(() => {
    if (props.template3DScene) {
      Promise.all(props.template3DScene.models.map(model => new ModelApi().getById(model._id)))
      .then(data => setModelData(data))
      .catch(err => console.log(err));
    }
  }, [props.template3DScene]);

  if (!props.template3DScene) {
    return null;
  }

  return (
    <div className={classes.TemplateSceneDetailsModels}>
      <Typography variant="h5" component="h2">
        Included Models
      </Typography>
      <div className={classes.DetailsModelThumbnailContainer}>
        {
          modelData.map(model => <Link to={`/mockupmodels/${model.slug}`}>
            <img
              alt=""
              src={new ModelApi().createThumbnailUrl(model.thumbKey)}
              className={classes.DetailsModelThumbnail}
            />
          </Link>)
        }
      </div>
    </div>
  );
}
