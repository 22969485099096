import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './LoadingOverlay.styles';
import { SquareFoldSpinner } from '../SquareFoldSpinner';
import { quotes } from './LoadingQuotes';

interface LoadingOverlayProps {
  message?: string;
}

export const LoadingOverlay = (props: LoadingOverlayProps) => {
  const randomQuoteIndex = () => Math.floor(Math.random() * quotes.length);
  // eslint-disable-next-line
  const [ quoteIndex, _ ] = React.useState(randomQuoteIndex);
  const { message = 'Loading...' } = props;
  const classes = useStyles(useTheme());

  const { text, author } = quotes[quoteIndex];

  return (
    <div className={classes.Overlay}>
      <div className={classes.OverlayQuote}>
        <span className={classes.OverlayQuoteText}>"{text}"</span>
        <span className={classes.OverlayQuoteAuthor}>--{author}</span>
      </div>
      <SquareFoldSpinner />
      <span>{ message }</span>
    </div>
  );
}
