import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  LandingVideo: {
    color: theme.palette.text.primary,
  },
  LandingVideoBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Video: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '5px',
    boxShadow: '0px 0px 18px 6px rgba(0,0,0,0.2)',
  }
}));
