import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Register.styles';
import useParentStyles from '../LoginDialog.styles';
import { TextField } from '@material-ui/core';
import ActionButton from 'shared/components/ActionButton/ActionButton';
import { CircularSpinner } from 'shared/components/CircularSpinner';
import Logo from 'shared/assets/images/mockupfox_logo_black.svg';
import Pattern from 'shared/assets/images/pattern_background.svg';

export interface RegisterProps {
  isLoading: boolean,
  hasError: string,
  onRegisterClick: (email: string, password: string) => void;
  onChange: (email: string, password: string) => void;
  onSwitchToLoginClick: () => void;
  onSwitchToResetPasswordClick: () => void;
}

export function Register(props: RegisterProps) {
  const [ email, setEmail ] = React.useState('');
  const [ password, setPassword ] = React.useState('');

  const {
    isLoading,
    hasError,
    onChange,
    onRegisterClick,
    onSwitchToLoginClick,
    onSwitchToResetPasswordClick,
  } = props;

  const classes = useStyles(useTheme());
  const parentClasses = useParentStyles(useTheme());

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    onChange(newEmail, password);
  }

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
    onChange(email, newPassword);
  }

  const disableRegisterButton = () => {
    if (isLoading) return true;
    if (email.length < 6 || password.length < 6) return true;
    return false;
  }

  return (
    <div className={parentClasses.LoginDialog}>
      <div className={parentClasses.Pattern}>
        <img alt="" src={Pattern} />
      </div>
      <div className={parentClasses.LoginContent}>
        <img alt="" className={classes.Logo} src={Logo} />
        <div className={parentClasses.LoginDialogTitle}>
          Create custom mockups
        </div>
        <div className={parentClasses.LoginDialogBody}>
          <TextField
            className={classes.RegisterInput}
            label="Your Email"
            value={email}
            onChange={(event) => handleEmailChange(event.target.value)}
          ></TextField>
          <TextField
            className={classes.RegisterInput}
            label="Your Password"
            type="password"
            value={password}
            onChange={(event) => handlePasswordChange(event.target.value)}
          ></TextField>
          <div className={parentClasses.LoginDialogButtonBar}>
            <ActionButton size="large" disabled={disableRegisterButton()} onClick={() => onRegisterClick(email, password)} fullWidth={true}>
              <span className={parentClasses.ButtonSpinner}>
                {isLoading && <CircularSpinner size={15}/>}
              </span>
              Register
            </ActionButton>
          </div>
          { 
            hasError &&
            <div className={parentClasses.LoginError}>{hasError}</div>
          }
          <div className={classes.NotRegisteredYetContainer}>
            <span className={parentClasses.LoginDialogLink} onClick={onSwitchToResetPasswordClick}>Forgot Password?</span>
            <span className={parentClasses.LoginDialogLink} onClick={onSwitchToLoginClick}>Login instead</span>                
          </div>
        </div>
      </div>
    </div>
  );
}