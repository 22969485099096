import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from 'store';
import { ImageEditState } from './types';
import { moveArrayItem } from 'shared/utils/misc.helper';

const initialState: ImageEditState = {
  selectedObjectId: '',
  objects: [],
  initialImage: '',
};

const modelsSlice = createSlice({
  name: 'ImageEdit',
  initialState,
  reducers: {
    resetStoreAction(state) {
      state.objects = [];
      state.selectedObjectId = initialState.selectedObjectId;
    },
    changeSelectionAction(state, action: PayloadAction<{selectionId: string}>) {
      state.selectedObjectId = action.payload.selectionId;
    },
    addObjectAction(state, action: PayloadAction<{fabricObject: fabric.Object}>) {
      const { fabricObject } = action.payload;
      state.objects.push(fabricObject as any);
    },
    removeObjectAction(state, action: PayloadAction<{fabricObjectId: string}>) {
      const { fabricObjectId } = action.payload;
      state.objects = state.objects.filter(object => object.data?.id !== fabricObjectId);
    },
    modifyObjectAction(state, action: PayloadAction<{fabricObject: fabric.Object}>) {
      const { fabricObject } = action.payload;
      const foundObject = state.objects.find(object => object.data?.id === fabricObject.data?.id);
      if (foundObject) {
        // track only properties that are relevent for the store
        foundObject.data = fabricObject.data;
        foundObject.opacity = fabricObject.opacity;
        foundObject.left = fabricObject.left;
        foundObject.top = fabricObject.top;
        if (typeof fabricObject.fill === 'string') foundObject.fill = fabricObject.fill;
      }
    },
    moveObjectUpAction(state, action: PayloadAction<{fabricObjectId: string}>) {
      const { fabricObjectId } = action.payload;
      const pos = state.objects.map(object => object.data?.id || '0').indexOf(fabricObjectId);
      if(pos >= 0) {
        moveArrayItem(state.objects, pos, pos + 1);
      }
    },
    moveObjectDownAction(state, action: PayloadAction<{fabricObjectId: string}>) {
      const { fabricObjectId } = action.payload;
      const pos = state.objects.map(object => object.data?.id || '0').indexOf(fabricObjectId);
      if(pos >= 0) {
        moveArrayItem(state.objects, pos, pos - 1);
      }
    },
    setInitialImageAction(state, action: PayloadAction<{initialImage: string}>) {
      const { initialImage } = action.payload;
      state.initialImage = initialImage;
    },
  }
});

const {
  resetStoreAction,
  changeSelectionAction,
  addObjectAction,
  removeObjectAction,
  modifyObjectAction,
  moveObjectUpAction,
  moveObjectDownAction,
  setInitialImageAction,
} = modelsSlice.actions;

export const resetImageEditStore = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(resetStoreAction());
}

export const changeSelection = (selectionId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeSelectionAction({ selectionId }));
}

export const addObject = (fabricObject: fabric.Object): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(addObjectAction({ fabricObject }));
}

export const removeObject = (fabricObject: string |  fabric.Object | null): AppThunk => async (dispatch: AppDispatch, getState) => {
  let fabricObjectId = getState().imageEdit.selectedObjectId;
  if (typeof fabricObject === 'string') fabricObjectId = fabricObject;
  else if (fabricObject) fabricObjectId = (fabricObject as fabric.Object)?.data?.id;
  dispatch(removeObjectAction({ fabricObjectId }));
}

export const modifyObject = (fabricObject: fabric.Object): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(modifyObjectAction({ fabricObject }));
}

export const moveObjectUp = (fabricObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(moveObjectUpAction({ fabricObjectId }));
}

export const moveObjectDown = (fabricObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(moveObjectDownAction({ fabricObjectId }));
}

export const setInitialImage = (initialImage: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setInitialImageAction({ initialImage }));
}

export default modelsSlice.reducer;
