import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ModelSimulator: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  ModelSimulatorContent: {
    flexGrow: 1,
  },
  
}));
