import React from 'react';
import * as ReactGA from 'react-ga';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';

import { RootState } from 'rootReducer';
import useStyles from './ModelSimulator.styles';
import { Sidebar } from 'shared/components/Sidebar';
import { ModelSelection } from 'modules/Simulator3d/components/ModelSelection';
import { ObjectSidebar } from 'modules/Simulator3d/components/ObjectSidebar';
import { ModelSimulatorWorkArea } from 'modules/Simulator3d/components/ModelSimulatorWorkArea';
import { SceneHelper } from 'modules/ThreeManager/helper/SceneHelper';
import { changeEditOption, closeTour, openImageEditor, openTour, setEditorSceneId } from 'features/ui/ui';
import { ObjectEditOption } from 'features/ui/types';
import { createDefaultState } from 'features/scenes/scenesHelper';

import { Tour } from 'modules/Simulator3d/components/Tour';
import { TextureType } from 'modules/ThreeManager/Models/enum/TextureType';
import { NoMobileSupport } from 'modules/Simulator3d/components/NoMobileSupport';
import { config } from 'config';
import { Dialog } from 'shared/components/Dialog';
import { LoginDialog, LoginDialogViewState } from 'shared/components/LoginDialog';
import TemplateSceneApi from 'shared/utils/api/templateScene';
import { SaveDialog } from 'shared/components/SaveDialog';
import { setInitialImage } from 'features/imageEdit/imageEdit';
import { LoadingOverlay } from 'shared/components/LoadingOverlay';
import { SceneManager } from 'modules/ThreeManager/Manager/SceneManager';
import { addModelRequestById, changeModelPatternProperty, changeModelPatternRepeat, changeModelPropertyType, changeSceneMappingRawData, removeSelectedObjectRequest, resetScene } from 'features/scenes/scenes';
import { ObjectStatus } from 'modules/ThreeManager/Models/enum/ObjectStatus';
import cloneDeep from 'lodash.clonedeep';
import { SceneObjectBase } from 'modules/ThreeManager/Models/SceneObjectBase';

export function ModelSimulator() {
  const dispatch = useDispatch();
  const classes = useStyles(useTheme());

  const { scenes, ui, user } = useSelector((state: RootState) => state);
  const [ isSignInNeededDialogOpen, setSignInNeededDialogOpen ] = React.useState(false);
  const [ showLoginRegisterDialog, setShowLoginRegisterDialog ] = React.useState(false);
  const [ saveSceneImage, setSaveSceneImage ] = React.useState('');
  const [ saveSceneSending, setSaveSceneSending ] = React.useState(false);
  const [ loginDialogViewState, setLoginDialogViewState ] = React.useState(LoginDialogViewState.Signup);
  const [ sceneManager, setSceneManager ] = React.useState<SceneManager | null>(null);

  const sceneManagerId = sceneManager?.id || '';
  const scene = scenes.scenes.find(scene => scene.id === sceneManagerId) || createDefaultState() ;
  const selectedModel = new SceneHelper(scene.data).getSceneModelById(ui.selectedObjectSceneId || '');

  const handleModelSelection = (modelId: string) => {
    if (!user.data?.user) {
      if (scene.data.models.length < config.planSettings.allowedFreeModelsInScene) {
        dispatch(addModelRequestById(sceneManagerId, modelId));
      } else {
        setSignInNeededDialogOpen(true);
      }
    } else {
      dispatch(addModelRequestById(sceneManagerId, modelId));
    }
  }

  const handlePlacePatternDesignClick = (base64Image: string) => {
    if (!selectedModel?.objectMaterials || !ui.selectedChildObjectName) return;
    const selectedObjectMaterial = selectedModel.objectMaterials[ui.selectedChildObjectName];

    dispatch(changeModelPropertyType(sceneManagerId, ui.selectedObjectSceneId, TextureType.Pattern));
    dispatch(changeModelPatternRepeat(sceneManagerId, ui.selectedObjectSceneId, selectedObjectMaterial.defaultRepeat));
    dispatch(changeModelPatternProperty(sceneManagerId, ui.selectedObjectSceneId, base64Image));
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Place Pattern Design',
    });
  }

  const handlePlaceStandAloneDesignClick = (base64Image: string) => {
    if (!selectedModel?.objectMaterials || !ui.selectedChildObjectName) return;

    dispatch(setInitialImage(base64Image));
    dispatch(changeModelPropertyType(sceneManagerId, ui.selectedObjectSceneId, TextureType.Mapping));
    // resetting the mapping
    dispatch(changeSceneMappingRawData(sceneManagerId, ui.selectedObjectSceneId, []));
    dispatch(openImageEditor());
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Place Stand-Alone Design',
    });
  }

  const onNewScene = () => {
    dispatch(resetScene(sceneManagerId));
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Click New Scene Toolbar',
    });
  }

  const onDeleteClick = () => {
    dispatch(removeSelectedObjectRequest(sceneManagerId));
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Click Delete Toolbar',
    });
  }

  const onEditOptionClick = (objectEditOption: ObjectEditOption) => {
    dispatch(changeEditOption(objectEditOption));
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Change Edit Option Toolbar',
      label: objectEditOption ? objectEditOption.toString() : '',
    });
  }

  const handleExportClick = (imageUri: string) => {
    if (imageUri) {
      saveAs(imageUri, "mockupfox_export.png");
      ReactGA.event({
        category: 'Model Simulator',
        action: 'Click Export Toolbar',
      });
    }
  }

  const handleSaveSceneClick = (imageUri: string) => {
    setSaveSceneImage(imageUri || '');
  }

  const handleSaveDialogCloseClick = () => {
    if (!saveSceneSending) setSaveSceneImage('');
  }

  const handleSaveDialogSaveSceneClick = (name: string, sceneImage: string) => {
    setSaveSceneSending(true);

    const resetSceneObject = (objects: SceneObjectBase[]) => {
      objects.forEach(entity => {
        entity.status = ObjectStatus.CreationInProgress;
        entity.threeObjectId = -1;
      });
    }

    const saveData = cloneDeep(scene.data);
    resetSceneObject(saveData.lights);
    resetSceneObject(saveData.models);
    resetSceneObject(saveData.primitives);

    new TemplateSceneApi().saveTemplateScene(name, saveData, sceneImage, ui.selectedObjectSceneId || '', ui.selectedChildObjectName || '')
      .then((data) => {
        setSaveSceneSending(false);
        setSaveSceneImage('');
      })
      .catch(err => setSaveSceneImage(''));

    ReactGA.event({
      category: 'Model Simulator',
      action: 'Click Save Scene Toolbar',
    });
  }

  const handleClose = () => {
    dispatch(closeTour());
  }

  const handleSceneManagerCreation = (sceneManager: SceneManager) => {
    dispatch(setEditorSceneId(sceneManager.id));
    setSceneManager(sceneManager);
  }

  const isLoadingOverlayOpen = scene.loading; //scene.data.models.reduce((isLoading, model) => model.status !== ObjectStatus.Ready || isLoading, false);

  return (
    <>
      <NoMobileSupport />
      { 
        isLoadingOverlayOpen &&
        <LoadingOverlay message="Building your scene" />
      }
      <Dialog
        isOpen={isSignInNeededDialogOpen}
        title={'Login for more access'}
        onCloseClick={() => setSignInNeededDialogOpen(false)}
        buttons={[
          { color: 'inherit', variant: 'text', label: 'Perhaps later', onClick: () => setSignInNeededDialogOpen(false)},
          { color: 'primary', variant: 'contained', label: 'Create free account', onClick: () => { setShowLoginRegisterDialog(true); setSignInNeededDialogOpen(false) }}
        ]}
      >
        { `Create an account to add more than ${config.planSettings.allowedFreeModelsInScene} models in a scene.` }
      </Dialog>
      <LoginDialog
        isOpen={showLoginRegisterDialog}
        loginViewState={loginDialogViewState}
        onCloseClick={() => setShowLoginRegisterDialog(false)}
        onSwitchViewStateClick={(viewState: LoginDialogViewState) => setLoginDialogViewState(viewState)}
      />
      <SaveDialog
        isOpen={!!saveSceneImage}
        sceneImage={saveSceneImage}
        isSaving={saveSceneSending}
        onCloseClick={handleSaveDialogCloseClick}
        onSaveClick={handleSaveDialogSaveSceneClick}
      />

      <div className={classes.ModelSimulatorBody}>
        <Sidebar className={classes.ModelSimulatorLeftSidebar}>
          <ModelSelection
            onModelSelection={handleModelSelection}
            onHelpClick={() => dispatch(openTour())}
          ></ModelSelection>
        </Sidebar>
        <ModelSimulatorWorkArea
          selectedModel={selectedModel}
          ui={ui}
          isSceneEmpty={!scene.data.models.length}
          adminView={user.data?.user?.isAdmin}
          onPlacePatternDesignClick={handlePlacePatternDesignClick}
          onPlaceStandAloneDesignClick={handlePlaceStandAloneDesignClick}
          onNewScene={onNewScene}
          onSaveSceneClick={handleSaveSceneClick}
          onDeleteClick={onDeleteClick}
          onEditOptionClick={onEditOptionClick}
          onExportClick={handleExportClick}
          onThreeManagerCreation={handleSceneManagerCreation}
        />
        <ObjectSidebar />
      </div>
      {
        ui.tourOpen &&
        <Tour onClose={handleClose} />
      }
    </>
  );
}
