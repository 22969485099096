import { UnsplashSearchResult } from 'shared/models/UnsplashSearchResult';
import ApiHelper from './api';

export default class UnsplashApi extends ApiHelper {
  private endpoint = '/unsplash';

  public loadImages(page = '1', searchQuery = ''): Promise<UnsplashSearchResult> {
    return this.get(`${this.baseUrl}${this.endpoint}/search/photos/${page}/${searchQuery}`);
  }

  public loadRandom(page = '1') {
    return this.get(`${this.baseUrl}${this.endpoint}/search/photos?page=${page}`);
  }
}