import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TemplateSceneApi from 'shared/utils/api/templateScene';
import { AppThunk, AppDispatch } from 'store';

import { TemplateScenesState, TemplateScene } from './types';

const initialState: TemplateScenesState = {
  loading: false,
  data: [],
};

const templateScenesSlice = createSlice({
  name: 'TemplateScenes',
  initialState,
  reducers: {
    sceneTemplatesRequestAction(state) {
      state.loading = true;
      state.errors = undefined;
    },
    sceneTemplatesSuccessAction(state, action: PayloadAction<TemplateScene[]>) {
      state.loading = false;
      state.data.push(...action.payload);
    },
    sceneTemplatesFailureAction(state, action: PayloadAction<any>) {
      state.loading = false;
      state.errors = action.payload;
    }
  }
});

const {
  sceneTemplatesRequestAction,
  sceneTemplatesSuccessAction,
  sceneTemplatesFailureAction,
} = templateScenesSlice.actions;

export const loadSceneTemplates = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  // check if the templates are already loaded; if they are, then dont load them again
  const loadedSceneTemplates = getState().templateScenes.data;
  if (loadedSceneTemplates.length > 0) return;

  dispatch(sceneTemplatesRequestAction());
  try {
    const allSceneTemplates: Array<TemplateScene> = await new TemplateSceneApi().getAll();
    dispatch(sceneTemplatesSuccessAction(allSceneTemplates));
  } catch (e) {
    dispatch(sceneTemplatesFailureAction(e.response?.data?.message || 'Not able to load scene templates'));
  }
}

export default templateScenesSlice.reducer;
