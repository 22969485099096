import cloneDeep from "lodash.clonedeep";
import * as THREE from 'three';

import { SceneModel } from "modules/ThreeManager/Models/SceneModel";
import { Object3D } from "three";
import { GeometryFactoryAbstract } from "../Factories/AbstractFactories/GeometryFactoryAbstract";
import { TextureFactoryAbstract } from "../Factories/AbstractFactories/TextureFactoryAbstract";
import { ModelManagerAbstract } from "./ModelManagerAbstract";
import { OverlayTexture } from "../Models/OverlayTexture";
import { TextureType } from "../Models/enum/TextureType";
import { ObjectMaterial } from "../Models/ObjectMaterial";
import TextureApi from "shared/utils/api/texture";
import { Texture } from "../Models/Texture";
import { MaterialType } from "../Models/enum/MaterialType";
import { MaterialFactoryAbstract } from "../Factories/AbstractFactories/MaterialFactoryAbstract";

export class ModelManager extends ModelManagerAbstract {

  constructor(
    private geometryFactory: GeometryFactoryAbstract,
    private textureFactory: TextureFactoryAbstract,
    private materialFactory: MaterialFactoryAbstract,
  ) {
    super();
  }

  async createModel(model: SceneModel) {
    const createdModel = await this.geometryFactory.loadModel(model.modelKey);
    await this.updateModel(createdModel.scene, model);
    return createdModel.scene;
  }

  async updateModel(toBeUpdatedModel: Object3D, model: SceneModel) {
    this.processModelVisibility(toBeUpdatedModel, model);
    await this.processTextureUpdate(toBeUpdatedModel, model);

    toBeUpdatedModel.userData.sceneModelData = model;
    return toBeUpdatedModel;
  }

  private processModelVisibility(toBeUpdatedModel: Object3D, model: SceneModel) {
    if (toBeUpdatedModel.visible !== model.visible) {
      toBeUpdatedModel.visible = model.visible;
    }
  }

  private async createOverlayMaps(
    structuralTextures: Texture | undefined,
    structuralOverlayStrength = 1,
  ): Promise<OverlayTexture | null> {
    if (structuralTextures?.colorMap) {
      const mapUri = new TextureApi().createTextureUrl(structuralTextures?.colorMap || '');
      const overlayColorMap = await this.textureFactory.createMappingTexture(mapUri);
      if (overlayColorMap) {
        overlayColorMap.wrapS = overlayColorMap.wrapT = THREE.RepeatWrapping;
        return { overlayMap: overlayColorMap, overlayStrength: structuralOverlayStrength };
      }
    }
    return null;
  }

  private async createColorMapForTextureType(
    textureType: TextureType,
    object: THREE.Mesh<any, any>,
    objectMaterial: ObjectMaterial,
  ): Promise<ObjectMaterial> {
    if (!objectMaterial.property) objectMaterial.property = { map: null}

    switch(textureType) {
      case TextureType.Mapping:
        const mappingTextureForMesh = objectMaterial.mapping?.textureUri || '';
        objectMaterial.property.map = mappingTextureForMesh
          ? await this.textureFactory.createMappingTexture(mappingTextureForMesh)
          : object.material.map;
        break;
      case TextureType.Pattern:
        const patternTextureForMesh = objectMaterial.pattern?.textureUri || '';
        objectMaterial.property.map = await this.textureFactory.createPatternTexture(patternTextureForMesh);
        break;
      case TextureType.Color:
        objectMaterial.property.color = objectMaterial.color || '#FFFFFF';
        break;
    }

    return objectMaterial;
  }

  private async createStructuralMaps(
    structuralTextures: Texture | undefined,
    structuralRepeatModifier: number,
    objectMaterial: ObjectMaterial,
  ) {
    if (!structuralTextures) return objectMaterial;

    if (structuralTextures.normalMap) {
      const mapUri = new TextureApi().createTextureUrl(structuralTextures.normalMap || '');
      objectMaterial.property.normalMap = await this.textureFactory.loadTexture(mapUri);
    }

    if (structuralTextures.ambientOcclusionMap) {
      const mapUri = new TextureApi().createTextureUrl(structuralTextures.ambientOcclusionMap || '');
      objectMaterial.property.aoMap = await this.textureFactory.loadTexture(mapUri);
    }

    if (structuralTextures.roughnessMap && objectMaterial.type === MaterialType.MeshStandardMaterial) {
      const mapUri = new TextureApi().createTextureUrl(structuralTextures.roughnessMap || '');
      (objectMaterial.property as THREE.MeshStandardMaterial).roughnessMap = await this.textureFactory.loadTexture(mapUri);
    }

    if (structuralTextures.displacementMap) {
      const mapUri = new TextureApi().createTextureUrl(structuralTextures.displacementMap || '');
      objectMaterial.property.displacementMap = await this.textureFactory.loadTexture(mapUri);
      objectMaterial.property.displacementScale = 1 / ((structuralTextures.mapRepeat || 1) * structuralRepeatModifier * 2);
    }

    return objectMaterial;
  }

  private processTextureUpdateForChildObject(object: Object3D, model: SceneModel) {
    return new Promise(async (resolve, reject) => {
      if ( object instanceof THREE.Mesh ) {
        let materialForMesh = model.objectMaterials[object.name];
        if (!materialForMesh) return resolve(object);

        const overlayColorMaps: OverlayTexture[] = [];
        const {
          selectedTextureType,
          structuralTextures,
          structuralRepeatModifier = 1,
          structuralOverlayStrength = 1,
        } = materialForMesh;

        materialForMesh = await this.createColorMapForTextureType(selectedTextureType, object, materialForMesh);
        materialForMesh = await this.createStructuralMaps(structuralTextures, structuralRepeatModifier, materialForMesh)
        
        const overlayColorMap = await this.createOverlayMaps(structuralTextures, structuralOverlayStrength);
        if (overlayColorMap) overlayColorMaps.push(overlayColorMap);

        object.material = this.materialFactory.createMaterial(materialForMesh, overlayColorMaps);
        object.castShadow = true;
        object.receiveShadow = true;
        
        resolve(object);
      } else {
        resolve(null);
      }
    })
  }

  private processTextureUpdate(toBeUpdatedModel: Object3D, modelTemplate: SceneModel) {
    return new Promise(async (resolve, reject) => {
      const model = cloneDeep(modelTemplate);
      const updatePromises: Array<Promise<any>> = [];

      toBeUpdatedModel.traverse((object) => updatePromises.push(
        this.processTextureUpdateForChildObject(object, model)
      ));
      Promise.all(updatePromises).then(resolve).catch(reject)
    })
  }
}