import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './BlogCategory.styles';
import { BlogApi } from 'shared/utils/api/blog';
import { useParams } from 'react-router-dom';
import { Header } from 'shared/components/Header';
import { Footer } from 'shared/components/Footer';
import { createTitle } from 'shared/utils/misc.helper';

export const BlogCategory = () => {
  const classes = useStyles(useTheme());
  const { categoryName = 'blog' } = useParams<{categoryName: string}>();
  const [ categoryPosts, setCategoryPosts ] = React.useState([]);

  React.useEffect(() => {
    new BlogApi().getCategoryPosts(categoryName)
      .then(data => setCategoryPosts(data))
      .catch(error => console.error(error));
  }, []);
  
  const renderPostExcerpt = (post: any) => {
    const excerptWithoutLink = post.excerpt.rendered.replace(/<a\b[^>]*>(.*?)<\/a>/i,"");
    const excerpt = excerptWithoutLink; // + `<a href="/article/${post.slug}" style="color:gray; margin-top: 6px">Read more...</a>`;
    return (
      <div className={classes.excerpt}>
        <div className={classes.excerptImage}>
          <a href={`/blog/article/${post.slug}`}>
            <img alt="" src={post.fimg_url} />
          </a>
        </div>
        <div className={classes.excerptTitle} dangerouslySetInnerHTML={{__html: `<h2><a href="/blog/article/${post.slug}">${post.title.rendered}</a></h2>`}} />
        <div className={classes.excerptDescription} dangerouslySetInnerHTML={{__html: excerpt}} />
      </div>
    )
  }

  const renderPostExcerpts = () => (
    categoryPosts.map(categoryPost => renderPostExcerpt(categoryPost))
  )

  if(!categoryPosts.length) return <div>Loading</div>;

  return (
    <>
      {
        categoryName &&
        <Helmet>
          <title>{createTitle(categoryName)}</title>
        </Helmet>
      }
      <Header />
      <div className={classes.categoryContainer}>
        {
          renderPostExcerpts()
        }
      </div>
      <Footer />
    </>
  )
}
