import React from 'react';
import CategoryIcon from '@material-ui/icons/Category';

import { ParentObject } from '../ParentObject/ParentObject';
import { PrimitiveObject } from 'features/scenes/types';

interface ParentPrimitiveObjectProps {
  object: PrimitiveObject,
  isSelected?: boolean,
  isVisible?: boolean,
  showChevron?: boolean,
  showChildren?: boolean,
  onClick: () => void,
  onVisibilityChange: (objectSceneId: string, isVisible: boolean) => void,
}

export function ParentPrimitiveObject(props: ParentPrimitiveObjectProps) {
  const {
    object,
    isVisible = true,
    isSelected,
    showChevron,
    showChildren,
    onClick = () => {},
    onVisibilityChange = (objectSceneId: string, isVisible: boolean) => {},
  } = props;

  const handleVisibilityChange = (isVisible: boolean) => {
    onVisibilityChange(object.id, isVisible);
  }

  return (
    <ParentObject
      name={object.label}
      icon={<CategoryIcon/>}
      isSelected={isSelected}
      isVisible={isVisible}
      showChevron={showChevron}
      showChildren={showChildren}
      onClick={onClick}
      onVisibilityClick={handleVisibilityChange}
    />
  );
}
