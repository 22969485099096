import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ObjectSidebar: {
    flexGrow: 1,
  },
  ObjectSidebarSelection: {
    overflowY: 'auto',
    height: '50px',
  }
}));
