import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

import useStyles from './NotFound.styles';
import { createTitle } from 'shared/utils/misc.helper';

import pageNotFound from 'shared/assets/images/page_not_found.svg';
import { Link } from 'react-router-dom';

export function NotFound() {
  const classes = useStyles(useTheme());

  return (
    <>
      <Helmet>
        <title>{createTitle('Uuuuups... This page is not here.')}</title>
      </Helmet>
      <div className={classes.NotFound}>
        <img alt="" className={classes.NotFoundImage} src={pageNotFound} />
        <p>This page doesn't exists. Perhaps you searched for something else?</p>
        <p>Back to <Link to="/">Main Page</Link>?</p>
      </div>
    </>
  );
}
