import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  TemplateScenesDetails: {
    width: '100%',
    maxWidth: '1200px',
    padding: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  TemplateSceneDetailsInfoContainer: {
    display: 'flex',
    width: '80%',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    }
  },
  TemplateScenesTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  TemplateScenesDetailsImage: {
    width: '40%',
    height: '40%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
    }
  },
  TemplateScenesDetailsText: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  BackArrow: {
    fontSize: '1.5rem',
    marginRight: '1rem',
    color: '#121212',
  },
  DescriptionText: {
    lineHeight: 1.4,
  },
  OpenInEditorButton: {
    marginTop: theme.spacing(2),
  }
}));
