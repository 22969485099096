import ApiHelper from './api';

export default class ModelApi extends ApiHelper {
  private endpoint = '/model';

  public getAll(): Promise<Array<any>> {
    return this.get(`${this.baseUrl}${this.endpoint}/all`);
  }

  public getById(id: string): Promise<any> {
    return this.get(`${this.baseUrl}${this.endpoint}/id/${id}`);
  }

  public getBySlug(slug: string): Promise<any> {
    return this.get(`${this.baseUrl}${this.endpoint}/slug/${slug}`);
  }

  public createModelUrl(modelKey: string) {
      return `${this.baseUrl}${this.endpoint}/file/model/${modelKey}`;
  }

  public createThumbnailUrl(modelKey: string) {
      return `${this.baseUrl}${this.endpoint}/file/thumb/${modelKey}`;
  }

  public createUvMapUrl(modelKey: string) {
    if (!modelKey) return '';
    return `${this.baseUrl}${this.endpoint}/file/uvmap/${modelKey}`;
  }
}