export interface Quote {
    text: string;
    author: string;
}

export const quotes: Array<Quote> = [
    {
        text: "Have no fear of perfection -- you’ll never reach it.",
        author: "Salvador Dali",
    },
    {
        text: "Design is intelligence made visible.",
        author: "Alina Wheeler",
    },
    {
        text: "You can't use up creativity. The more you use, the more you have.",
        author: "Maya Angelou",
    },
    {
        text: "Design adds value faster than it adds costs.",
        author: "Joel Spolsky",
    },
    {
        text: "Make it simple, but significant.",
        author: "Don Draper",
    },
    {
        text: "Every great design begins with an even better story.",
        author: "Lorinda Mamo",
    },
    {
        text: "The function of design is letting design function.",
        author: "Micha Commeren",
    },
    {
        text: "Good design is good business.",
        author: "Thomas Watson",
    },
    {
        text: "Good design is like a refrigerator—when it works, no one notices, but when it doesn’t, it sure stinks.",
        author: "Irene Au",
    },
    {
        text: "Design can be art. Design can be simple. That’s why it’s so complicated.",
        author: "Paul Rand",
    },
    {
        text: "Design is thinking made visual",
        author: "Saul Bass",
    },
    {
        text: "Good design is honest",
        author: "Dieter Rams",
    },
    {
        text: "If you do it right, it will last forever",
        author: "Massimo Vignelli",
    },
    {
        text: "The details are not the details. They make the design.",
        author: "Charles Eames",
    },
]