import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

import useStyles from './ModelDetails.styles';
import { MockupLayout } from 'shared/components/MockupLayout';
import { CircularSpinner } from 'shared/components/CircularSpinner';
import ModelApi from 'shared/utils/api/model';
import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';
import { ChildrenDetails } from 'modules/TemplateModels/components/ChildrenDetails';

export function ModelDetails() {
  const classes = useStyles(useTheme());
  const [ modelData, setModelData ] = React.useState<SceneModel>()
  const { modelName = 'notFound' } = useParams<{modelName: string}>();

  React.useEffect(() => {
    new ModelApi().getBySlug(modelName)
      .then(setModelData)
      .catch(err => console.log(err));
  }, []);

  const createTitle = () => {
    return modelData?.name || 'Loading...';
  };

  const renderSpinner = () => (
    <div className={classes.LoadingPage}>
      <CircularSpinner />
    </div>
  );

  const applyLineBreaks = (str: string) => {
    return str.split(/\r\n|\n|\r|\\n/gm).map(line => <p>{line}</p>);
  }

  const SubHeader = ({title}: {title: string}) => (
    <Typography className={classes.DescriptionText} variant="h5" component="h2" gutterBottom>
      { title }
    </Typography>
  );

  const renderModelDetails = (modelData: SceneModel) => (
    <article className={classes.ModelDetailsContainer}>
      <div className={classes.ModelDetailsTitle}>
        <Typography variant="h3" component="h1">
          { modelData.name }
        </Typography>
      </div>
      <div className={classes.ModelDetailsInfoContainer}>
        <img
          alt=""
          className={classes.ModelDetailsImage}
          src={new ModelApi().createThumbnailUrl(modelData.imageKey)}
        />
        <div className={classes.ModelDetailsText}>
          <div>
            <div>
              <SubHeader title="Description" />
              { applyLineBreaks(modelData.description || 'No description available') }
            </div>
            <div>
              <SubHeader title="Child Components" />
              <div className={classes.ChildrenDetailsList}>
                {
                  Object.values(modelData.objectMaterials).map(objectMaterial => <ChildrenDetails objectMaterial={objectMaterial} />)
                }
              </div>
            </div>
            <Button
              className={classes.OpenInEditorButton}
              variant="contained"
              color="primary"
              href={`/mockupcreator/model/${modelData.slug}`}
            >
              Open in Creator
            </Button>
          </div>
        </div>
      </div>
    </article>
  );

  return (
    <MockupLayout title={createTitle()}>
      {
        modelData
        ? renderModelDetails(modelData)
        : renderSpinner()
      }
    </MockupLayout>
  );
}
