import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { Redirect, Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import history from 'shared/utils/history';
import store from 'store';
import defaultDarkTheme from 'shared/assets/theme/darkTheme';
import { Simulator } from 'modules/Simulator';
import { Landing } from 'modules/LandingPage/views/LandingPage';
import { BlogCategory } from 'modules/Blog/views/BlogCategory';
import { Terms } from 'static/Terms';
import { DataProtection } from 'static/DataProtection';
import { LoginHelper } from 'shared/components/LoginHelper';
import { Article } from 'modules/Blog/views/Article';

import './index.css';
import { ProtectedRoute } from 'shared/components/ProtectedRoute';
import { Login } from 'modules/Login';
import { TemplateScenesDetails } from 'modules/TemplateScenes/views/Details';
import { TemplateScenesOverview } from 'modules/TemplateScenes/views/Overview';
import { ModelDetails } from 'modules/TemplateModels/views/ModelDetails';
import { NotFound } from 'modules/NotFound';
import { Impress } from 'static/Impress/Impress';

const darkTheme = createMuiTheme(defaultDarkTheme);
const stripePromise = loadStripe(process.env.REACT_APP_PK_STRIPE || 'invalid_public_key');

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={darkTheme}>
          <Router history={history}>
            <CssBaseline />
            <LoginHelper />
            <Switch>
              <ProtectedRoute path="/mockupcreator/scene/:sceneSlug" component={ Simulator } />
              <ProtectedRoute path="/mockupcreator/model/:modelSlug" component={ Simulator } />
              <ProtectedRoute path="/mockupcreator" component={ Simulator } />
              <Redirect from="/simulator" to="/mockupcreator" />
              <Route path="/login" component={ Login } />
              <Route path="/mockupmodels/:modelName" component={ ModelDetails } />
              <Route path="/mockupscenes/:sceneName" component={ TemplateScenesDetails } />
              <Route path="/mockupscenes" component={ TemplateScenesOverview } />
              <Route path="/blog/category/:categoryName" component={ BlogCategory } />
              <Route path="/blog/article/:articleSlug" component={ Article } />
              <Route path="/blog" component={ BlogCategory } />

              <Route path="/terms" component={ Terms } />
              <Route path="/privacy" component={ DataProtection } />
              <Route path="/impress" component={ Impress } />
              <Route path="/not-found" component={ NotFound } />
              <Route path="/" exact={ true } component={ Landing } />
              <Route path="*" component={ () => {
                window.location.href = '/not-found';
                return null;
              }} />
            </Switch>
          </Router>
        </ThemeProvider>
      </Elements>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
