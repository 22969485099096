import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  FooterContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: '#FFFFFF',
    background: '#121212',
    top: 0,
    width: '100%',
    marginTop: '60px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  Footer: {
    display: 'flex',
    width: '100%',
    maxWidth: '1200px',
  },
  Column1: {
    width: '50%',
    padding: theme.spacing(3),
  },
  Column2: {
    width: '50%',
    padding: theme.spacing(3),
    textAlign: 'right',
  },
  Column2Links: {
    textAlign: 'right',
    color: '#FFFFFF',
    textDecoration: 'inherit',
    fontSize: '0.8rem',
  },
  Column3: {
    width: '0%',
    padding: theme.spacing(3),
  },
  Headline: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  AboutText: {
    width: '100%',
    maxWidth: '300px',
    fontSize: '0.8rem',
  },
  Copyright: {
    color: '#FFFFFF',
    marginTop: '10px',
    fontSize: '0.8rem',
  }
}));
