import { createBrowserHistory } from 'history';
import * as ReactGA from 'react-ga';

const history = createBrowserHistory();
export const previousUrls: Array<string> = [];

history.listen(location => historyListener(location));

ReactGA.initialize('UA-80165319-13');

const historyListener = (location: any) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  previousUrls.push(location.pathname);
  if (previousUrls.length > 10) previousUrls.shift();
}

historyListener(window.location);

export default history;