var glsl = require('glslify');
export default glsl(`
  float roughnessFactor = roughness;

  #ifdef USE_ROUGHNESSMAP
    vec4 texelRoughness = texture2D( roughnessMap, vUv * structureRepeat );
    // reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
    roughnessFactor *= texelRoughness.g;
  #endif
`);
