import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changeSelectedObject, expandObject, collapseObject, changeSelectedChildObject } from 'features/ui/ui';
import { RootState } from 'rootReducer';
import { ParentModelObject } from './ParentModelObject';
import { ParentLightObject } from './ParentLightObject';
import { ParentPrimitiveObject } from './ParentPrimitiveObject';
import { setObjectVisibilityState } from 'features/scenes/scenes';
import { ObjectStatus } from 'modules/ThreeManager/Models/enum/ObjectStatus';

export function ObjectSelection() {
  const dispatch = useDispatch();
  const { scenes, ui } = useSelector((state: RootState) => state);

  const scene = scenes.scenes.find(scene => scene.id === ui.editorSceneId);

  const handleSelectedObject = (objectSceneId: string) => {
    dispatch(changeSelectedObject(objectSceneId));
  }

  const handleChevronClick = (objectSceneId: string, expand: boolean) => {
    if (expand) dispatch(expandObject(objectSceneId));
    else dispatch(collapseObject(objectSceneId));
  }

  const isExpanded = (internalSceneId: string) => {
    return !ui.collapsedObjects.includes(internalSceneId);
  }

  const handleChildClick = (objectSceneId: string, childKey: string) => {
    dispatch(changeSelectedObject(objectSceneId));
    dispatch(changeSelectedChildObject(childKey));
  }

  const handleVisibilityChange = (objectSceneId: string, isVisible: boolean) => {
    dispatch(setObjectVisibilityState(ui.editorSceneId, objectSceneId, isVisible));
  }
  
  return (
    <>
      {
        scene?.data.models.map(model => {
          return <ParentModelObject
            key={model.id}
            object={model}
            isSelected={model.id === ui.selectedObjectSceneId}
            selectedSubObject={ui.selectedChildObjectName}
            isLoading={model.status !== ObjectStatus.Ready}
            isVisible={model.visible}
            onClick={() => handleSelectedObject(model.id)}
            onChildClick={handleChildClick}
            chevronClick={handleChevronClick}
            showChildren={isExpanded(model.id)}
            onVisibilityChange={handleVisibilityChange}
          />
        })
      }
      {
        scene?.data.lights.map(light => {
          return <ParentLightObject
            key={light.id}
            light={light}
            isSelected={light.id === ui.selectedObjectSceneId}
            isVisible={light.visible}
            onClick={() => handleSelectedObject(light.id)}
            onVisibilityChange={handleVisibilityChange}
          />
        })
      }
      {
        scene?.data.primitives.map(primitive => {
          return <ParentPrimitiveObject
            key={primitive.id}
            object={primitive}
            isSelected={primitive.id === ui.selectedObjectSceneId}
            isVisible={primitive.visible}
            onClick={() => handleSelectedObject(primitive.id)}
            onVisibilityChange={handleVisibilityChange}
          />
        })
      }
    </>
  );
}
