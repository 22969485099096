import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './UnsplashGallery.styles';
import UnsplashApi from 'shared/utils/api/unsplash';
import { UnsplashSearchResultItem } from 'shared/models/UnsplashSearchResult';
import { TextField } from '@material-ui/core';

interface UnsplashGalleryProps {
  onAddUnsplashToMapper: (imageUrl: string) => void;
}

export const UnsplashGallery = (props: UnsplashGalleryProps) => {
  const classes = useStyles(useTheme());
  const { onAddUnsplashToMapper } = props;
  const queryInput = React.useRef(null);
  const intersectionRef = React.useRef<HTMLDivElement>(null);

  const [ imageList, setImageList ] = React.useState<UnsplashSearchResultItem[]>([]);
  const [ searchTerm, setSearchTerm ] = React.useState('');
  const [ loadedPage, setLoadedPage ] = React.useState(1);
  const [ unsplashSearch, setUnsplashSearch ] = React.useState('');

  React.useEffect(() => {
    new UnsplashApi().loadImages(`${loadedPage}`, searchTerm || 'poster')
      .then(loadedImageList => {
        setImageList([...imageList, ...loadedImageList.results]);
      })
      .catch(e => console.log(e));
  }, [loadedPage, searchTerm]);

  const loadAdditionalDesigns = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      setLoadedPage(prevPage => prevPage + 1);
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => loadAdditionalDesigns(entry),
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1
      }
    );
    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }
  }, [intersectionRef]);

  const searchPhotos = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setSearchTerm(unsplashSearch);
      setLoadedPage(1);
      setImageList([]);
    }
  }

  return (
    <div className="box">
      <form
        id="unsplash-search"
        className={classes.UnsplashSearchInput}
        onSubmit={searchPhotos}
      >
        <TextField
          id="UnsplashSearch"
          variant="outlined"
          value={unsplashSearch}
          ref={queryInput}
          fullWidth
          size="small"
          placeholder="Search for Images in Unsplash"
          defaultValue=""
          className="input"
          style={{ marginBottom: 20 }}
          onChange={(e) => setUnsplashSearch(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </form>

      <ul className={classes.UnsplashPhotoGrid}>
        {
          imageList.map((photo) => {
            return (
              <li key={photo.id}>
                <img
                  alt=""
                  src={photo.urls.thumb}
                  onClick={() => onAddUnsplashToMapper(photo.urls.regular)}
                />
                <span className={classes.UnsplashAttribution}>
                  Image by&nbsp;
                  <a
                    href={photo.user.portfolio_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {photo.user.username}
                  </a>
                </span>
              </li>
            );
          })
        }
      </ul>
      <div className="intersectionBar" ref={intersectionRef}></div>
    </div>
  );
}
