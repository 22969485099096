import * as axios from 'axios';
import Cookies from 'universal-cookie';

import { config } from 'config';

axios.default.defaults.withCredentials = true;

axios.default.interceptors.request.use(
  axiosConfig => {
    const { origin } = new URL(axiosConfig.url || '');
    const allowedOrigins = [config.backend];
    const token = localStorage.getItem('token');
    if (allowedOrigins.includes(origin)) {
      axiosConfig.headers.Authorization = `bearer ${token}`;
    }
    return axiosConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

export default class ApiHelper {
  public baseUrl = `${config.backend}/api/v1`;

  public get(url: string) {
    return this.executeCall({
      url,
      method: 'get',
    });
  }

  public delete(url: string) {
    return this.executeCall({
      url,
      method: 'delete',
    });
  }

  public post(url: string, data: any, options: any = {}) {
    return this.executeCall(Object.assign({}, options, {
      url,
      method: 'post',
      data
    }));
  }

  public put(url: string, data: any, options: any = {}) {
    return this.executeCall(Object.assign({}, options, {
      url,
      method: 'put',
      data
    }));
  }

  private executeCall(options: axios.AxiosRequestConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.default.request(options)
        .then((response) => {
          try {
            resolve(JSON.parse(response.data));
          } catch (e) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          if (err.response?.status === 401 && err.response?.data?.status === 'JWT expired') {
            console.log('token expired');
            const cookies = new Cookies();
            cookies.remove('token');
          }
          reject(err);
        });
    });
  }
}
