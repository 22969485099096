import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ModelSimulatorWorkArea: {
    flexGrow: 1,
    position: 'relative',
  },
  PlaceYourDesignButton: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  EmptySceneMessage: {
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  FeedbackButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: '20px',
  }
}));
