import React from 'react';
import * as ReactGA from 'react-ga';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'rootReducer';
import { Sidebar } from 'shared/components/Sidebar';
import { Tabs } from 'shared/components/Tabs';
import { ObjectSelection } from 'modules/Simulator3d/components/ObjectSelection';
import { Separator } from 'shared/components/Separator';
import useStyles from './ObjectSidebar.styles';
import { LightObjectProperty } from 'modules/Simulator3d/components/LightObjectProperty/LightObjectProperty';
import { openImageEditor } from 'features/ui/ui';
import { ModelProperties } from '../ModelProperties/ModelProperties';
import { TextureType } from 'modules/ThreeManager/Models/enum/TextureType';
import { NoSelectionProperty } from '../NoSelectionProperty';
import { MaterialProperty } from '../MaterialProperty';
import {
  changeSceneLightColor,
  changeSceneLightIntensity,
  changeModelColorProperty,
  changeModelPropertyType,
  changeModelPatternProperty,
  changeModelPatternRepeat,
  changeModelStandardMaterialRoughness,
  changeModelPhongMaterialShininess,
  changeModelStandardMaterialMetalness,
  changeModelPhongMaterialReflectivity,
} from 'features/scenes/scenes';


export function ObjectSidebar() {
  const dispatch = useDispatch();
  const classes = useStyles(useTheme());
  const { scenes, ui } = useSelector((state: RootState) => state);

  const scene = scenes.scenes.find(scene => scene.id === ui.editorSceneId);
  const selectedLight = scene?.data.lights.find((light => light.id === ui.selectedObjectSceneId));
  const selectedModel = scene?.data.models.find((model => model.id === ui.selectedObjectSceneId));

  const changeSelectedLightColor = (newColor: string) => {
    dispatch(changeSceneLightColor(ui.editorSceneId, ui.selectedObjectSceneId, newColor));
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Change Light Color',
    });
  }

  const changeSelectedLightIntensity = (newIntensity: number) => {
    dispatch(changeSceneLightIntensity(ui.editorSceneId, ui.selectedObjectSceneId, newIntensity));
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Change Light Intensity',
    });
  }
  
  const changeModelTextureType = (newTextureType: TextureType) => {
    dispatch(changeModelPropertyType(ui.editorSceneId, ui.selectedObjectSceneId, newTextureType));
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Change Texture Type',
      label: newTextureType,
    });
  }

  const changeModelPattern = (base64File: string) => {
    dispatch(changeModelPatternProperty(ui.editorSceneId, ui.selectedObjectSceneId, base64File));
  }

  const changePatternRepeat = (repeat: number) => {
    dispatch(changeModelPatternRepeat(ui.editorSceneId, ui.selectedObjectSceneId, repeat));
  }

  const changeColor = (newColor: string) => {
    dispatch(changeModelColorProperty(ui.editorSceneId, ui.selectedObjectSceneId, newColor));
  }

  const changeStandardMaterialRoughness = (newRoughness: number) => {
    dispatch(changeModelStandardMaterialRoughness(ui.editorSceneId, ui.selectedObjectSceneId, newRoughness));
  }
  
  const changeStandardMaterialMetalness = (newMetalness: number) => {
    dispatch(changeModelStandardMaterialMetalness(ui.editorSceneId, ui.selectedObjectSceneId, newMetalness));
  }
  
  const changePhongMaterialShininess = (newShininess: number) => {
    dispatch(changeModelPhongMaterialShininess(ui.editorSceneId, ui.selectedObjectSceneId, newShininess));
  }

  const changePhongMaterialReflectivity = (newReflectivity: number) => {
    dispatch(changeModelPhongMaterialReflectivity(ui.editorSceneId, ui.selectedObjectSceneId, newReflectivity));
  }

  const openModelImageEditor = () => {
    dispatch(openImageEditor());
  }

  const selectedObjectMaterial = (selectedModel?.objectMaterials && ui.selectedChildObjectName)
    ? selectedModel?.objectMaterials[ui.selectedChildObjectName]
    : undefined;

  return (
    <Sidebar>
      <div id="objectSelection" className={classes.ObjectSidebar}>
        <Tabs labels={['Objects']} class={classes.ObjectSidebarSelection}>
          <ObjectSelection></ObjectSelection>
        </Tabs>
      </div>
      <Separator />
      <div id="objectProperties">
        <Tabs labels={['Properties', 'Material']}>
          <div>
            { 
              selectedLight &&
              <LightObjectProperty
                light={selectedLight}
                onColorChange={changeSelectedLightColor}
                onIntensityChange={changeSelectedLightIntensity}
              />
            }
            {
              selectedObjectMaterial &&
              <ModelProperties
                objectMaterial={selectedObjectMaterial}
                repeatPattern={selectedObjectMaterial?.pattern?.repeat || 1}
                onColorChange={changeColor}
                onTextureTypeChange={changeModelTextureType}
                onMappingEditClick={openModelImageEditor}
                onPatternUpload={changeModelPattern}
                onPatternRepeatChange={changePatternRepeat}
              />
            }
            {
              !selectedLight && !selectedObjectMaterial && 
              <NoSelectionProperty />
            }
          </div>
          <div>
            {
              selectedObjectMaterial &&
              <MaterialProperty
                objectMaterial={selectedObjectMaterial}
                onStandardMaterialRoughnessChange={changeStandardMaterialRoughness}
                onStandardMaterialMetalnessChange={changeStandardMaterialMetalness}
                onPhongMaterialShininessChange={changePhongMaterialShininess}
                onPhongMaterialReflectivityChange={changePhongMaterialReflectivity}
              />
            }
            {
              !selectedObjectMaterial && 
              <NoSelectionProperty />
            }
          </div>
        </Tabs>
      </div>
    </Sidebar>
  );
}
