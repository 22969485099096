import { Button, Theme, withStyles } from '@material-ui/core';

const ActionButton = withStyles((theme: Theme) => ({
  root: {
    background: 'linear-gradient(45deg,#e08255 0,#fb5b5b 100%)',
    color: 'white',
  },
}))(Button);

export default ActionButton
