/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ChildObject.styles';
import noImage from 'shared/assets/images/noimage.svg';

interface ChildObjectProps {
  name: string,
  image: string,
  color: string,
  isSelected?: boolean
  onClick: () => void,
}

export function ChildObject(props: ChildObjectProps) {
  const classes = useStyles(useTheme());
  
  const handleSelectionClick = () => {
    if (props.onClick) props.onClick();
  }

  const getImage = () => {
    if(props.color && !props.image) return undefined;
    if(props.image) return props.image;
    return noImage;
  }

  return (
    <div className={classes.IndentObject}>
      <div
        className={`${classes.ChildObject} ${props.isSelected && classes.ChildObjectSelected}`}
        onClick={handleSelectionClick}
      >
        <div className={classes.Name}>{ props.name }</div>
        <div className={classes.ImageContainer}>
          <img className={classes.PreviewImage} style={{backgroundColor: props.color || '#FFFFFFFF'}} src={getImage()}/>
        </div>
      </div>
    </div>
  );
}
