import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

import useStyles from './TemplateScenesDetails.styles';
import { MockupLayout } from 'shared/components/MockupLayout';
import TemplateSceneApi from 'shared/utils/api/templateScene';
import { TemplateScene } from 'features/templateScenes/types';
import { CircularSpinner } from 'shared/components/CircularSpinner';
import { TemplateSceneDetailsBox } from 'modules/TemplateScenes/components/TemplateSceneDetailsBox';
import { Scene3d } from 'features/scenes/types';
import { TemplateSceneDetailsModels } from 'modules/TemplateScenes/components/TemplateSceneDetailsModels';

export function TemplateScenesDetails() {
  const classes = useStyles(useTheme());
  const [ templateMetaData, setTemplateMetaData ] = React.useState<TemplateScene>();
  const [ template3dData, setTemplate3dData ] = React.useState<Scene3d>();
  const { sceneName = 'notFound' } = useParams<{sceneName: string}>();

  React.useEffect(() => {
    new TemplateSceneApi().loadTemplateSceneMetaData(sceneName)
      .then(setTemplateMetaData)
      .catch(err => console.log(err));
    new TemplateSceneApi().loadTemplateScene3dData(sceneName)
      .then(setTemplate3dData)
      .catch(err => console.log(err));
  }, []);

  const createTitle = () => {
    return templateMetaData?.name || 'Loading...';
  };

  const renderSpinner = () => (
    <div className={classes.LoadingPage}>
      <CircularSpinner />
    </div>
  );

  const renderSceneDetails = (validTemplateMetaData: TemplateScene, template3dData: Scene3d) => (
    <article className={classes.TemplateScenesDetailsContainer}>
      <TemplateSceneDetailsBox templateScene={validTemplateMetaData} />
      <TemplateSceneDetailsModels template3DScene={template3dData} />
    </article>
  );

  return (
    <MockupLayout title={createTitle()}>
      {
        templateMetaData && template3dData
        ? renderSceneDetails(templateMetaData, template3dData)
        : renderSpinner()
      }
    </MockupLayout>
  );
}
