import { Model } from "modules/ThreeManager/Models/Model";
import { SceneModel } from "modules/ThreeManager/Models/SceneModel";
import { Scene3d } from "features/scenes/types";
import { ObjectMaterial } from "modules/ThreeManager/Models/ObjectMaterial";
import { generateUUID } from 'shared/utils/misc.helper';
import { TextureType } from '../Models/enum/TextureType';
import { ObjectStatus } from "../Models/enum/ObjectStatus";

export class SceneHelper {
  private scene: Scene3d | undefined;

  constructor(scene3d?: Scene3d) {
      this.scene = scene3d;
  }

  public getSceneModelById(id: string) {
    return this.scene?.models.find(sceneModel => sceneModel.id === id);
  }

  public getSceneModelByThreeId(id: number) {
    return this.scene?.models.find(sceneModel => sceneModel.threeObjectId === id);
  }

  public getSceneLightByThreeId(id: number) {
    return this.scene?.lights.find(sceneModel => sceneModel.threeObjectId === id);
  }

  public getScenePrimitiveByThreeId(id: number) {
    return this.scene?.primitives.find(sceneModel => sceneModel.threeObjectId === id);
  }

  public getSceneObjectByThreeId(id: number) {
    return this.getSceneModelByThreeId(id) || this.getSceneLightByThreeId(id) || this.getScenePrimitiveByThreeId(id);
  }

  static generateInternalSceneId() {
    return generateUUID();
  }

  static convertInputModelToSceneModel(model: Model): SceneModel {
    return {
      _id: model._id,
      id: Date.now().toString(),
      threeObjectId: 0,
      name: model.name,
      label: model.name,
      visible: true,
      thumbKey: model.thumbKey,
      imageKey: model.imageKey,
      modelKey: model.modelKey,
      category: model.category,
      objectMaterials: model.objectMaterials,
      position: {x: 0, y: 0, z: 0},
      scale: {x: 1, y: 1, z: 1},
      rotation: {x: 0, y: 0, z: 0},
      status: ObjectStatus.CreationInProgress,
      slug: model.slug,
      description: model.description,
    };
  }

  static getCurrentObjectMaterial(objectMaterial: ObjectMaterial) {
    switch (objectMaterial.selectedTextureType as TextureType) {
      case TextureType.Color.toString(): return objectMaterial.color;
      case TextureType.Pattern.toString(): return objectMaterial.pattern;
      case TextureType.Mapping.toString(): return objectMaterial.mapping;
      default: return objectMaterial.color;
    }
  }
}
