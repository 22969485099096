import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ModelDetailsContainer: {
    color: '#232323',
    justifyContent: 'center',
    top: 0,
    width: '100%',
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  LoadingPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  ModelDetailsTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  ModelDetailsInfoContainer: {
    display: 'flex',
    width: '80%',
    marginTop: theme.spacing(5),
  },
  ModelDetailsImage: {
    width: '40%',
    height: '40%',
    objectFit: 'cover',
    border: '1px solid #00000022',
    backgroundColor: '#4f4e4e',
  },
  ModelDetailsText: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  DescriptionText: {
    lineHeight: 1.4,
  },
  ChildrenDetailsList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  OpenInEditorButton: {
    marginTop: theme.spacing(2),
  }
}));
