import React from 'react';
import { ColorPicker } from 'shared/components/ColorPicker';

interface PrimitivePropertyProps {
  color?: string;
  onColorChange?: (newColor: string) => void;
}

export function PrimitiveProperty(props: PrimitivePropertyProps) {
  const {
    color = '#FFFFFF',
    onColorChange = (newColor: string) => {},
  } = props;

  return (
    <>
      <ColorPicker color={color} onColorChange={onColorChange}/>
    </>
  );
}
