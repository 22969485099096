import { ModelManager } from "../Manager/ModelManager";
import { GeometryFactory } from "./GeometryFactory";
import { MaterialFactory } from "./MaterialFactory";
import { TextureFactory } from "./TextureFactory";

export class ModelManagerFactory {
  static createModelManager() {
    const geometryFactory = new GeometryFactory();
    const textureFactory = new TextureFactory();
    const materialFactory = new MaterialFactory();
    return new ModelManager(geometryFactory, textureFactory, materialFactory);
  }

}