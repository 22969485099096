import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import { Button, Popover } from '@material-ui/core';

import useStyles from './PlaceDesignButton.styles';
import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';
import { convertEventToBase64 } from 'shared/utils/misc.helper';

interface PlaceDesignButtonProps {
  selectedModel?: SceneModel,
  onPlacePatternDesignClick: (base64Image: string) => void,
  onPlaceStandAloneDesignClick: (base64Image: string) => void,
}

export function PlaceDesignButton(props: PlaceDesignButtonProps) {
  const {
    selectedModel,
    onPlacePatternDesignClick,
    onPlaceStandAloneDesignClick,
  } = props;
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);
  const classes = useStyles(useTheme());

  if (!selectedModel) return null;

  const handlePlacePatternDesignClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const base64Image = await convertEventToBase64(e);
    onPlacePatternDesignClick(base64Image);
    setAnchorEl(null);
  }

  const handlePlaceStandAloneDesignClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const base64Image = await convertEventToBase64(e);
    onPlaceStandAloneDesignClick(base64Image);
    setAnchorEl(null);
  }

  const handlePlaceDesignTypePanelOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePlaceDesignTypePanelClose = () => {
    setAnchorEl(null);
  };

  const id = !!anchorEl ? 'place-design-popover' : undefined;

  return (
    <div className={classes.PlaceYourDesignButton}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PublishIcon />}
        component="button"
        onClick={handlePlaceDesignTypePanelOpen}
      >
        Place Your Design
      </Button>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePlaceDesignTypePanelClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.PlaceDesignPopover}>
          <div className={classes.PlaceDesignButtonList}>
            <ul>
              <li>
                <label
                  htmlFor="loadPattern"
                >
                  Repeated Pattern
                  <input
                    id="loadPattern"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handlePlacePatternDesignClick}
                  />
                </label>
              </li>
              <li>
                <label
                  htmlFor="loadStandAloneImage"
                >
                  Stand-alone Graphic
                  <input
                    id="loadStandAloneImage"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handlePlaceStandAloneDesignClick}
                  />
                </label>
              </li>
            </ul>
          </div>
        </div>
      </Popover>
    </div>
  );
}
