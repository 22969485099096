import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  FeedbackButton: {
  },
  SmileyContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(2),
  },
  Smiley: {
    cursor: 'pointer',
    height: '50px',
  },
  CommentTextField: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  ThankYouContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.5rem',
  },
  ThankYouImage: {
    width: 'fit-content',
  },
}));
