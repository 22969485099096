import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';

import useStyles from './FeedbackButton.styles';
import { Dialog } from '../Dialog';
import FeedbackApi from 'shared/utils/api/feedback';

import SmileyAngry from './images/smiley_angry.png';
import SmileyNotOkay from './images/smiley_notokay.png';
import SmileyOkay from './images/smiley_okay.png';
import SmileyHappy from './images/smiley_happy.png';
import SmileyLove from './images/smiley_love.png';
import Thanks from './images/thanks.png';

enum Mood {
  Angry = 'Angry',
  NotOkay = 'Not Okay',
  Okay = 'Okay',
  Happy = 'Happy',
  Love = 'Love',
}

interface FeedbackButtonProps { }

export function FeedbackButton(props: FeedbackButtonProps) {
  const classes = useStyles(useTheme());
  const [ isFeedbackDialogOpen, setIsFeedbackDialogOpen ] = React.useState(false);
  const [ selectedMood, setMood ] = React.useState<Mood | null>(null);
  const [ comment, setComment ] = React.useState('');
  const [ showThanks, setShowThanks ] = React.useState(false);

  const handleFeedbackButtonClick = () => {
    setIsFeedbackDialogOpen(true);
  }

  const handleSmileyClick = (mood: Mood) => {
    setMood(mood);
  }

  const handleCommentChange = (event: any) => {
    setComment(event.target.value);
  };

  const handleClose = () => {
    setIsFeedbackDialogOpen(false);
    setMood(null);
    setComment('');
  }

  const showThanksDialog = () => {
    setShowThanks(true);
    setTimeout(() => {
      setShowThanks(false);
    }, 2500);
  }

  const handleSubmit = () => {
    new FeedbackApi().sendBetaFeedback(selectedMood || '', comment)
      .then(() => {
        handleClose();
        showThanksDialog();
      })
      .catch(() => handleClose());
  }

  return (
    <>
      <Dialog
        isOpen={isFeedbackDialogOpen && !selectedMood}
        onCloseClick={() => handleClose()}
      >
        <>
          <span>How do you rate the experience of the beta version?</span>
          <div className={classes.SmileyContainer}>
            <img alt="angry smiley" className={classes.Smiley} onClick={() => handleSmileyClick(Mood.Angry)} src={SmileyAngry} />
            <img alt="not okay smiley" className={classes.Smiley} onClick={() => handleSmileyClick(Mood.NotOkay)} src={SmileyNotOkay} />
            <img alt="okay smiley" className={classes.Smiley} onClick={() => handleSmileyClick(Mood.Okay)} src={SmileyOkay} />
            <img alt="happy smiley" className={classes.Smiley} onClick={() => handleSmileyClick(Mood.Happy)} src={SmileyHappy} />
            <img alt="love smiley" className={classes.Smiley} onClick={() => handleSmileyClick(Mood.Love)} src={SmileyLove} />
          </div>
        </>
      </Dialog>
      <Dialog
        isOpen={isFeedbackDialogOpen && !!selectedMood}
        onCloseClick={() => handleClose()}
        buttons={[
          { color: 'primary', variant: 'contained', label: 'Submit', onClick: () => handleSubmit() }
        ]}
      >
        <>
          <span>Do you have some comment or wishes?</span>
          <TextField
            id="outlined-multiline-comments"
            className={classes.CommentTextField}
            label="Comment"
            multiline
            rows={4}
            onChange={handleCommentChange}
            value={comment}
            variant="outlined"
            placeholder="Place your comment here..."
          />
        </>
      </Dialog>
      <Dialog
        isOpen={showThanks}
        onCloseClick={() => handleClose()}
      >
        <div className={classes.ThankYouContainer}>
          <img alt="thanks" className={classes.ThankYouImage} src={Thanks} />
          <span>Thank you!</span>
        </div>
      </Dialog>
      <div className={classes.FeedbackButton}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FeedbackIcon />}
          component="button"
          onClick={handleFeedbackButtonClick}
        >
          Feedback
        </Button>
      </div>
    </>
  );
}
