import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Header: {
    height: '50px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between',
  },
  LogoContainer: {
    height: '60%',
    flexGrow: 1,
  },
  Logo: {
    height: '100%',
  },
  LoginRegisterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '5px',
    }
  },
  SimulatorMenuCenterButton: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  AccountInfo: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  Options: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  }
}));
