import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  TemplateSceneDetailsModels: {
    width: '100%',
    maxWidth: '1200px',
    padding: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  DetailsModelThumbnailContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  DetailsModelThumbnail: {
    height: '128px',
    width: '128px',
    marginRight: theme.spacing(2),
    border: '1px solid #00000022',
    backgroundColor: '#00000001',
  },
}));
