export class Plan {
  name: string;
  type: string;
  priceMonthlyDollar: string;
  priceYearlyDollar: string;
  numberOfAllowedObjectsInScene: number;

  constructor() {
    this.name = 'Free';
    this.type = 'Free'
    this.priceMonthlyDollar = '0.00';
    this.priceYearlyDollar = '0.00';
    this.numberOfAllowedObjectsInScene = 0;
  }
}
