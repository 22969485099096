import { SceneLight, Scenes } from "features/scenes/types";
import { ObjectStatus } from "modules/ThreeManager/Models/enum/ObjectStatus";
import { SceneModel } from "modules/ThreeManager/Models/SceneModel";
import { RootState } from "rootReducer";
import { SceneManager } from "../Manager/SceneManager";
import { ControllerAbstract } from "./ControllerAbstract";

export class SceneManagerController extends ControllerAbstract {
  // tracking what state object ids have been already sent
  private stateObjectIdsSentForCreation: Array<string> = [];
  // tracking what state object ids have been already sent
  private stateObjectIdsSentForDeletion: Array<string> = [];

  constructor(
    private sceneManager: SceneManager,
  ) {
    super();
  }

  processIncomingState(state: RootState) {
    const sceneState = this.getScene(state.scenes);
    if (!sceneState) return;

    sceneState.data.models.forEach(this.processModelData.bind(this))
    sceneState.data.lights.forEach(this.processLightData.bind(this))
  }

  private getScene(scenesFeature: Scenes) {
    return scenesFeature.scenes.find(scene => scene.id === this.sceneManager.id);
  }

  private processModelData(model: SceneModel) {
    if (!model.id) return;

    switch(model.status) {
      case ObjectStatus.Ready:
      case ObjectStatus.DeletionInProgress:
      case ObjectStatus.ChangeInProgress:
      case ObjectStatus.CreationInProgress:
        return;
      case ObjectStatus.CreationRequested:
        if (!this.stateObjectIdsSentForCreation.find(objectId => objectId === model.id)) {
          this.stateObjectIdsSentForCreation.push(model.id);
          return this.sceneManager.createModel(model);
        }
        break;
      case ObjectStatus.ChangeRequested:
        return this.sceneManager.updateModel(model);
      case ObjectStatus.DeletionRequested:
        if (!this.stateObjectIdsSentForDeletion.find(objectId => objectId === model.id) && model.threeObjectId) {
          this.stateObjectIdsSentForDeletion.push(model.id);
          return this.sceneManager.deleteObject(model.threeObjectId);
        }
        break;
      default: return;
    }
  }

  private processLightData(light: SceneLight) {
    if (!light.id) return;

    switch(light.status) {
      case ObjectStatus.Ready:
      case ObjectStatus.DeletionInProgress:
      case ObjectStatus.ChangeInProgress:
      case ObjectStatus.CreationInProgress:
        return;
      case ObjectStatus.CreationRequested:
        if (!this.stateObjectIdsSentForCreation.find(objectId => objectId === light.id)) {
          this.stateObjectIdsSentForCreation.push(light.id);
          return this.sceneManager.createLight(light);
        }
        break;
      case ObjectStatus.ChangeRequested:
        return this.sceneManager.updateLight(light);
      case ObjectStatus.DeletionRequested:
        if (!this.stateObjectIdsSentForDeletion.find(objectId => objectId === light.id)) {
          this.stateObjectIdsSentForDeletion.push(light.id);
          return this.sceneManager.deleteObject(light.threeObjectId);
        }
        break;
      default: return;
    }
  }
}