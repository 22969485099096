import * as React from 'react';
import * as ReactGA from 'react-ga';
import { useTheme } from '@material-ui/core/styles';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Stripe as StripeJs } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';

import './CheckoutForm.css';
import useStyles from './CheckoutForm.styles';
import { Button, Paper, InputBase, CircularProgress } from '@material-ui/core';
import { Stripe } from 'stripe';
import { RootState } from 'rootReducer';
import { Plan } from 'shared/models/Plan';
import UserApi from 'shared/utils/api/user';
import { PlanPeriod } from 'shared/models/enum/PlanPeriod';

interface IHandleAction {
  stripe: StripeJs,
  subscription?: Stripe.Subscription,
  invoice?: Stripe.Invoice,
  priceId?: string,
  paymentMethodId?: string,
  isRetry?: boolean,
  errorCallback?: (error: string) => void,
}

const handleCustomerActionRequired = (action: IHandleAction) => {

  const {
    stripe,
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
    errorCallback = () => {},
  } = action;

  if (subscription && subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = null;

  if (invoice) {
    paymentIntent = invoice.payment_intent;
  } else if (subscription?.latest_invoice && typeof subscription.latest_invoice !== 'string' ) {
    paymentIntent = subscription.latest_invoice.payment_intent;
  } else {
    return;
  }

  if (
    paymentIntent.status === 'requires_action' ||
    (isRetry === true && paymentIntent.status === 'requires_payment_method')
  ) {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw result;
        } else if (result.paymentIntent?.status === 'succeeded') {
          // There's a risk of the customer closing the window before callback
          // execution. To handle this case, set up a webhook endpoint and
          // listen to invoice.payment_succeeded. This webhook endpoint
          // returns an Invoice.
          return {
            priceId: priceId,
            subscription: subscription,
            invoice: invoice,
            paymentMethodId: paymentMethodId,
          };
        } else {
          throw result;
        }
      })
      .catch((error: any) => {
        errorCallback(error.error.message);
        return null;
      });
  } else {
    // No customer action needed
    return { subscription, priceId, paymentMethodId };
  }
}


export const CheckoutForm = ({
  plan,
  planPeriod,
  successCallback
}: {
  plan: Plan,
  planPeriod: PlanPeriod,
  successCallback: () => void
}) => {
  const { user } = useSelector((state: RootState) => state);

  const [isProcessing, setProcessingTo] = React.useState(false);
  const [checkoutError, setCheckoutError] = React.useState('');

  const [name, setName] = React.useState('');
  const [invalidName, setInvalidName] = React.useState(false);

  const classes = useStyles(useTheme());

  const stripe = useStripe();
  const elements = useElements();

  const handleCardDetailsChange = (ev: any) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError('');
  };

  const handleFormSubmit = async (ev: any) => {
    ev.preventDefault();

    const billingDetails = {
      name,
      email: user.data?.user?.email,
    };

    setProcessingTo(true);

    const cardElement = elements?.getElement("card");

    if (!cardElement || !stripe) return;

    // create payment method
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails
      });

      if (paymentMethodReq?.error) {
        setCheckoutError(paymentMethodReq?.error?.message || '');
        setProcessingTo(false);
        return;
      }

      if(!paymentMethodReq?.paymentMethod?.id) {
        setCheckoutError('Missing Payment Method');
        setProcessingTo(false);
        return;
      }

      // change subscription
      try {
        const subscription = await new UserApi().changeSubscription(paymentMethodReq.paymentMethod.id, plan.name, planPeriod);

        const actionResponse = await handleCustomerActionRequired({subscription, stripe, errorCallback: setCheckoutError});
        if (!actionResponse) {
          setProcessingTo(false);
          ReactGA.event({
            category: 'registration',
            action: 'payment accepted',
          });
          return successCallback();
        }
        if ( typeof actionResponse?.subscription?.latest_invoice !== 'string'
          && actionResponse?.subscription?.latest_invoice?.payment_intent?.status === 'requires_payment_method') {
          throw new Error('Card was declined');
        }

        if (subscription.status === 'active' || subscription.status === 'past_due') {
          setProcessingTo(false);
          successCallback();
          ReactGA.event({
            category: 'registration',
            action: 'payment accepted',
          });
        }
      } catch (err) {
        setCheckoutError(err?.message || 'Card was declined');
        setProcessingTo(false);
        return;
      }

    } catch (err) {
      setCheckoutError(err);
      setProcessingTo(false);
    }
  };

  const iframeStyles = {
    base: {
      color: "#232323",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "#87bbfd"
      }
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE"
    },
    complete: {
      iconColor: "#cbf4c9"
    }
  };

  const cardElementOpts = {
    style: iframeStyles,
    hidePostalCode: true
  };

  const checkNameValid = () => {
    setInvalidName(!name);
  }

  return (
    <section className="stripeForm">
      <form onSubmit={handleFormSubmit}>
        <div className={classes.inputField}>
          <Paper component="form" className={`${classes.inputPaper} ${invalidName ? classes.inputError : ''}`}>
            <InputBase
              className={classes.input}
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={() => checkNameValid()}
            />
          </Paper>
          { invalidName && <div className={classes.inputErrorText}>Please enter a name</div> }
        </div>
        <div>
          <div>
            <CardElement
              options={cardElementOpts}
              onChange={handleCardDetailsChange}
            />
          </div>
        </div>
        {checkoutError && <div>{checkoutError}</div>}
        <div>
          <Button
            disabled={isProcessing || !stripe || !name}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.payButton}
          >
            {
              isProcessing
                ? <span>Processing... <CircularProgress size={18} className={classes.buttonProgress} /></span>
                : `Pay $${planPeriod === PlanPeriod.Monthly ? plan.priceMonthlyDollar : plan.priceYearlyDollar}`
            }
          </Button>
        </div>
      </form>
    </section>
  );
};
