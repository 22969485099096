import React from 'react';
import { CircularProgress } from '@material-ui/core';

export interface CircularSpinnerProps {
  size?: number,
  color?: 'primary' | 'secondary' | 'inherit',
}

export function CircularSpinner(props: CircularSpinnerProps) {
  const { 
    size,
    color
  } = props;

  return (
    <CircularProgress color={color} size={size} />
  );
}
