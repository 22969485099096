import * as React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import useStyles from './AccountPanel.styles';
import { RootState } from 'rootReducer';
import { logoutUser } from 'features/user/user';
import { LoginDialog, LoginDialogViewState } from '../LoginDialog';
import { CheckoutFormDialog } from '../CheckoutFormDialog';
import { Plan } from 'shared/models/Plan';
import PlanApi from 'shared/utils/api/plan';
import { PlanType } from 'shared/models/enum/PlanType';
import { AccountInfoPopover } from './AccountInfoPopover';

interface AccountPanelProps {
}

export function AccountPanel(props: AccountPanelProps) {
  const classes = useStyles(useTheme());
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLDivElement | null>(null);
  const [ showUpgradeDialog, setShowUpgradeDialog ] = React.useState(false);
  const [ showLoginRegisterDialog, setShowLoginRegisterDialog ] = React.useState(false);
  const [ loginDialogViewState, setLoginDialogViewState ] = React.useState(LoginDialogViewState.Signin);
  const [ professionalPlan, setProfessionalPlan ] = React.useState<Plan | undefined>(undefined)
  const { user } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    new PlanApi().loadAllPlans()
    .then((plans: Plan[]) => {
      const professionalPlan = plans.find(plan => plan.name === PlanType.Professional);
      setProfessionalPlan(professionalPlan);
    })
    .catch(e => console.log(e));
  }, []);

  const handleAccountPanelOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountPanelClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleAccountPanelClose();
    dispatch(logoutUser());
  }

  const handleUpgradeClick = () => {
    handleAccountPanelClose();
    setShowUpgradeDialog(true);
  }

  const openSignupDialog = () => {
    setShowLoginRegisterDialog(true);
    setLoginDialogViewState(LoginDialogViewState.Signup);
  }

  const openSigninDialog = () => {
    setShowLoginRegisterDialog(true);
    setLoginDialogViewState(LoginDialogViewState.Signin);
  }
  
  const renderAccountInfo = () => {
    const accountEmail = user.data?.user.email;
    if (!accountEmail) return null;

    const firstLetter = accountEmail.slice(0, 1).toUpperCase();
    const id = !!anchorEl ? 'account-popover' : undefined;

    return (
      <>
        <div className={classes.AccountInfo} onClick={handleAccountPanelOpen}>
          { firstLetter }
        </div>
        <AccountInfoPopover
          id={id}
          anchorEl={anchorEl}
          email={accountEmail}
          showUpgradeButton={user.data?.user.plan !== PlanType.Professional}
          onAccountPanelClose={handleAccountPanelClose}
          onUpgradeClick={handleUpgradeClick}
          onLogoutClick={handleLogoutClick}
        />
      </>
    );
  }

  const renderLoginRegisterButtons = () => {
    return (
      <>
        <Button color="primary" variant="text" onClick={() => openSigninDialog()}>Login</Button>
        <Button color="primary" variant="contained" onClick={() => openSignupDialog()}>Register</Button>
      </>
    )
  }

  return (
    <>
      <LoginDialog
        isOpen={showLoginRegisterDialog}
        loginViewState={loginDialogViewState}
        onCloseClick={() => setShowLoginRegisterDialog(false)}
        onSwitchViewStateClick={(viewState: LoginDialogViewState) => setLoginDialogViewState(viewState)}
      />
      <CheckoutFormDialog
        isOpen={showUpgradeDialog}
        plan={professionalPlan}
        onCloseClick={() => setShowUpgradeDialog(false)}
        successCallback={() => setShowUpgradeDialog(false)}
      />
      <div className={classes.LoginRegisterContainer}>
        {
          user.data 
          ? renderAccountInfo()
          : renderLoginRegisterButtons()
        }
      </div>
    </>
  );
}
