import { changeObjectPosition, changeObjectRotation, changeObjectScale, changeCameraPosition } from "features/scenes/scenes";
import store from "store";
import { SceneHelper } from "modules/ThreeManager/helper/SceneHelper";
import { CameraPositionLookAtUpdatePayload, ControlsStateUpdateMessage, ControlsUpdateMode, ObjectVector3dUpdatePayload } from "../Manager/ControlsManagerAbstract";
import { StateUpdateHandlerAbstract } from "./StateUpdateHandlerAbstract";

export class ControlsStateUpdateHandler extends StateUpdateHandlerAbstract<ControlsStateUpdateMessage> {
  private controlChangeTimeout: number = 0;

  emit(message: ControlsStateUpdateMessage) {
    if (message.stateUpdate === ControlsUpdateMode.CameraPositionLookAtUpdate) {
      this.processCameraUpdate(message);
    } else {
      this.processObjectUpdate(message);
    }
  }

  private processCameraUpdate(message: ControlsStateUpdateMessage) {
    const payload = message.payload as CameraPositionLookAtUpdatePayload;
    const { position, lookAt } = payload;

    if (this.controlChangeTimeout) {
      clearTimeout(this.controlChangeTimeout);
      this.controlChangeTimeout = 0;
    }
    this.controlChangeTimeout = setTimeout(() => {
      store.dispatch(changeCameraPosition(message.sceneId, position, lookAt));
    }, 500) as any;
  }

  private processObjectUpdate(message: ControlsStateUpdateMessage) {
    const payload = message.payload as ObjectVector3dUpdatePayload;
    const { threeObjectId = -1 } = payload;
    const storeObjectId = this.convertThreeObjectIdToStateObjectId(message.sceneId, threeObjectId);

    switch(message.stateUpdate) {
      case ControlsUpdateMode.PositionUpdate: store.dispatch(changeObjectPosition(message.sceneId, storeObjectId, (message.payload as ObjectVector3dUpdatePayload).vector)); break;
      case ControlsUpdateMode.ScaleUpdate: store.dispatch(changeObjectScale(message.sceneId, storeObjectId, (message.payload as ObjectVector3dUpdatePayload).vector)); break;
      case ControlsUpdateMode.RotationUpdate: store.dispatch(changeObjectRotation(message.sceneId, storeObjectId, (message.payload as ObjectVector3dUpdatePayload).vector)); break;
    }
  }

  private convertThreeObjectIdToStateObjectId(sceneId: string, threeObjectId: number) {
    const scene = store.getState().scenes.scenes.find(scene => scene.id === sceneId);
    if (!scene?.data) return '';

    return new SceneHelper(scene.data).getSceneObjectByThreeId(threeObjectId)?.id || '';
  }
}

