import * as THREE from 'three';
import { LoaderAbstract } from "./LoaderAbstract";

export class TextureLoader extends LoaderAbstract<THREE.Texture | null> {

  async loader(key: string): Promise<THREE.Texture | null> {
    return new Promise((resolve, reject) => {
      new THREE.TextureLoader().load(
        key,
        texture => {
          texture.flipY = false;
          resolve(texture);
        },
        () => {},
        () => { resolve(null) }
      );
    })
  }

  postProcessCacheLoad(texture: THREE.Texture) {
    return texture;
  }
}