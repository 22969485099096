import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './NoSelectionProperty.styles';

export function NoSelectionProperty() {
  const classes = useStyles(useTheme());

  return (
    <div className={classes.NoSelectionProperty}>
      No Object Selected
    </div>
  );
}
