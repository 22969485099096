import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserApi from 'shared/utils/api/user';
import { AppThunk, AppDispatch } from 'store';

import { UserState } from './types';

const initialState: UserState = {
  loading: false,
};

const userSlice = createSlice({
  name: 'Scene',
  initialState,
  reducers: {
    loginRequestAction(state) {
      state.loading = true;
      state.data = undefined;
      state.errors = undefined;
    },
    loginSuccessAction(state, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload;
    },
    loginFailureAction(state, action: PayloadAction<any>) {
      state.loading = false;
      state.data = undefined;
      state.errors = action.payload;
    },
    logoutAction(state) {
      state.loading = false;
      state.data = undefined;
      state.errors = undefined;
    },
    resetLoginErrorAction(state) {
      state.errors = undefined;
    }
  }
});

const {
  loginRequestAction,
  loginSuccessAction,
  loginFailureAction,
  logoutAction,
  resetLoginErrorAction,
} = userSlice.actions;

export const loginUser = (email: string, password: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(loginRequestAction());
  try {
    const user = await new UserApi().login(email, password);
    if (user) {
      localStorage.setItem('token', user.token);
      dispatch(loginSuccessAction(user));
    }
  } catch (e) {
    dispatch(loginFailureAction(e.response?.data?.message || 'Invalid credentials'));
  }
}

export const loginUserWithGoogle = (tokenId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(loginRequestAction());
  try {
    const user = await new UserApi().loginWithGoogle(tokenId);
    if (user) {
      localStorage.setItem('token', user.token);
      dispatch(loginSuccessAction(user));
    }
  } catch (e) {
    dispatch(loginFailureAction(e.response?.data?.message || 'Invalid credentials'));
  }
}

export const registerUser = (email: string, password: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(loginRequestAction());
  try {
    const user = await new UserApi().register(email, password);
    if (user) {
      localStorage.setItem('token', user.token);
      dispatch(loginSuccessAction(user));
    }
  } catch (e) {
    dispatch(loginFailureAction(e.response?.data?.message || 'Not able to register'));
  }
}

export const resetLoginError = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(resetLoginErrorAction());
}

export const logoutUser = (): AppThunk => async (dispatch: AppDispatch) => {
  localStorage.setItem('token', '');
  dispatch(logoutAction());
}

export const whoAmI = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(loginRequestAction());
  try {
    const user = await new UserApi().whoAmI();
    dispatch(loginSuccessAction(user));
  } catch (e) {
    dispatch(loginFailureAction('Not Logged In'));
  }
}

export default userSlice.reducer;
