import React from 'react';
import * as THREE from 'three';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'rootReducer';
import useStyles from './Three.styles';
import { changeSelectedObject } from 'features/ui/ui';
import { recreateAllObjects } from 'features/scenes/scenes';
import { handleThreeKeyDown } from './ThreeKeyboardEvents';

import TransparentBackground from 'shared/assets/images/transparent_background.jpg';
import { SceneManager } from 'modules/ThreeManager/Manager/SceneManager';
import { SceneHelper } from 'modules/ThreeManager/helper/SceneHelper';
import { SceneFactory } from 'modules/ThreeManager/Factories/SceneFactory';
import { removeControllerAsSubscriber } from 'store';
import { ControllerAbstract } from 'modules/ThreeManager/Controller/ControllerAbstract';

const unsetMousePosition = {x: -1, y: -1};

interface ThreeProps {
  keyProp: string,
  onThreeManagerCreation: (sceneManager: SceneManager) => void,
}

export function Three(props: ThreeProps) {
  const { keyProp, onThreeManagerCreation } = props;
  const dispatch = useDispatch();
  const classes = useStyles(useTheme());
  const { scenes } = useSelector((state: RootState) => state);

  const [ threeManager, setThreeManager ] = React.useState<SceneManager | null>(null);
  const [ sceneControllerArray, setSceneControllerArray ] = React.useState<Array<ControllerAbstract>>([]);
  const [ mouseStart, setMouseStart ] = React.useState<{x: number, y: number}>(unsetMousePosition);
  const [ mouseMoved, setMouseMoved ] = React.useState(false);
  const threeCanvasRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.body.addEventListener('keydown', (ev) => handleThreeKeyDown(threeManager?.id || keyProp || '', ev));
    return () => 
      document.body.removeEventListener('keydown', (ev) => handleThreeKeyDown(threeManager?.id || keyProp || '', ev))
  }, []);

  React.useEffect(() => {
    return () => {
      if (sceneControllerArray) {
        sceneControllerArray.forEach(controller => removeControllerAsSubscriber(controller));
      }
    }
  }, [sceneControllerArray]);

  React.useEffect(() => {
    if (threeCanvasRef.current) {
      const { sceneManager, controller } = SceneFactory.createEmptyScene(document, threeCanvasRef.current, keyProp);
      setThreeManager(sceneManager);
      setSceneControllerArray(controller);
      onThreeManagerCreation(sceneManager);
      dispatch(recreateAllObjects(sceneManager.id));
      threeCanvasRef.current.addEventListener('resize', sceneManager.adjustSceneToCanvasSize, false);
    }
  }, [threeCanvasRef]);

  const getCanvasRelativePosition = (clientX: number, clientY: number) => {
    if (!threeCanvasRef.current) return;
    
    const rect = threeCanvasRef.current.getBoundingClientRect();
    return {
      x: (clientX - rect.left) * threeCanvasRef.current.offsetWidth  / rect.width,
      y: (clientY - rect.top ) * threeCanvasRef.current.offsetHeight / rect.height,
    };
  }

  const onDocumentMouseMove = React.useCallback((clientX: number, clientY: number) => {
    if (!threeCanvasRef.current) return;

    // if there is no click event then skip the mouse move handling code
    if (mouseStart.x === unsetMousePosition.x && mouseStart.y === unsetMousePosition.y) return;

    // if the mouse is already flagged to be moved during the click then skip
    if (mouseMoved) return;

    // If dragging dont select an object
    if (Math.abs(mouseStart.x  - clientX) >= 1 || Math.abs(mouseStart.y - clientY) >= 1) {
      setMouseMoved(true);
    }
  }, [mouseStart]);

  const onDocumentMouseUp = React.useCallback((clientX: number, clientY: number) => {
    if (!threeCanvasRef.current) return;

    // if there is no click event then skip the mouse move handling code
    if (mouseStart.x === unsetMousePosition.x && mouseStart.y === unsetMousePosition.y) return;

    // reset mouse start position
    setMouseStart(unsetMousePosition);

    // In case of dragging dont select an object and reset the click
    if (mouseMoved) return setMouseMoved(false);

    const pos = getCanvasRelativePosition(clientX, clientY) || {x: 0, y: 0};
    const pickPosition = new THREE.Vector2();
    pickPosition.x = (pos.x / threeCanvasRef.current.offsetWidth ) *  2 - 1;
    pickPosition.y = (pos.y / threeCanvasRef.current.offsetHeight) * -2 + 1;
    // check if the click is within the canvas area
    if (pickPosition.x < -1 || pickPosition.x > 1) return;
    if (pickPosition.y < -1 || pickPosition.y > 1) return;

    const selectedObject = threeManager?.getObjectAtScreenPosition(pickPosition) || null;
    const scene = scenes.scenes.find(scene => scene.id === threeManager?.id);
    const selectedObjectSceneId = new SceneHelper(scene?.data).getSceneModelByThreeId(selectedObject || 0)?.id || '';
    dispatch(changeSelectedObject(selectedObjectSceneId));
  }, [mouseStart, mouseMoved]);

  return (
    <div
      style={{ backgroundImage: `url(${TransparentBackground})`}}
      onMouseDown={(event) => setMouseStart({x: event.clientX, y: event.clientY})}
      onMouseMove={(event) => onDocumentMouseMove(event.clientX, event.clientY)}
      onMouseUp={(event) => onDocumentMouseUp(event.clientX, event.clientY)}
      className={classes.Canvas}
      ref={threeCanvasRef}
    />
  );
}
