import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, Paper, Divider } from '@material-ui/core';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SaveIcon from '@material-ui/icons/Save';

import useStyles from './ModelToolbar.styles';
import { ObjectEditOption } from 'features/ui/types';
import { ToggleButtons } from '../ToggleButtons/ToggleButtons';
import { Dialog } from 'shared/components/Dialog';
import { Tooltip } from 'shared/components/Tooltip';

interface ModelToolbarProps {
  disableObjectTools?: boolean,
  selectedEditOption: ObjectEditOption,
  adminView: boolean,
  onNewScene?: () => void,
  onSaveSceneClick?: () => void,
  onDeleteClick?: () => void,
  onExportClick?: () => void,
  onEditOptionClick?: (objectEditOption: ObjectEditOption) => void,
}

export function ModelToolbar(props: ModelToolbarProps) {
  const {
    disableObjectTools = false,
    selectedEditOption,
    adminView = false,
    onNewScene = () => {},
    onSaveSceneClick = () => {},
    onDeleteClick = () => {},
    onExportClick = () => {},
    onEditOptionClick = (objectEditOption: ObjectEditOption) => {},
  } = props;
  const classes = useStyles(useTheme());

  const [ isNewSceneDialogOpen, setNewSceneDialogOpen ] = React.useState(false);

  const handleEditOptionClick = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => {
    const newAlignments: keyof typeof ObjectEditOption = newAlignment;
    const editOption = ObjectEditOption[newAlignments];
    onEditOptionClick(editOption);
  };

  const handleNewSceneClick = () => {
    setNewSceneDialogOpen(true);
  }

  const createToggleButtons = () => {
    return [
      {
        tooltipLabel: 'Move',
        disabled: disableObjectTools,
        value: ObjectEditOption.Move.toString(),
        content: <ControlCameraIcon />,
      },
      {
        tooltipLabel: 'Rotate',
        disabled: disableObjectTools,
        value: ObjectEditOption.Rotate.toString(),
        content: <AutorenewIcon />,
      },
      {
        tooltipLabel: 'Scale',
        disabled: disableObjectTools,
        value: ObjectEditOption.Scale.toString(),
        content: <PhotoSizeSelectSmallIcon />,
      },
    ]
  }

  return (
    <>
      <Dialog
        isOpen={isNewSceneDialogOpen}
        title={'Create new scene?'}
        onCloseClick={() => setNewSceneDialogOpen(false)}
        buttons={[
          { color: 'inherit', variant: 'text', label: 'No', onClick: () => setNewSceneDialogOpen(false)},
          { color: 'primary', variant: 'contained', label: 'Yes', onClick: () => { onNewScene(); setNewSceneDialogOpen(false) }}
        ]}
      >
        Do you want to create a new Scene? You current scene will be deleted.
      </Dialog>
      <div id="sceneToolbar" className={classes.SceneToolbar}>
        <Paper elevation={0} className={classes.DocumentToolbar}>
          <Tooltip title="New Scene" placement="right">
            <IconButton aria-label="new scene" onClick={handleNewSceneClick}>
              <InsertDriveFileIcon />
            </IconButton>
          </Tooltip>
          {
            adminView &&
            <Tooltip title="Save Scene" placement="right">
              <IconButton aria-label="save" onClick={onSaveSceneClick}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title="Snapshot" placement="right">
            <IconButton aria-label="snapshot" onClick={onExportClick}>
              <PhotoCameraIcon />
            </IconButton>
          </Tooltip>
        </Paper>

        <Paper elevation={0} className={classes.ModelToolbar}>
          <ToggleButtons
            onToggleChange={handleEditOptionClick}
            selectedToggle={selectedEditOption ? selectedEditOption.toString() : ObjectEditOption.Move.toString()}
            toggleButtonData={createToggleButtons()}
          />
          <Divider/>
          <Tooltip title="Delete" placement="right">
            <span>
              <IconButton disabled={disableObjectTools} aria-label="delete object" onClick={onDeleteClick}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Paper>
      </div>
    </>
  );
}
