import { SceneManagerFactory } from "./SceneManagerFactory";
import { addControllerAsSubscriber } from 'store';
import { SceneManagerController } from '../Controller/SceneManagerController';
import { ModelStateUpdateHandler } from '../StateUpdateHandler/ModelStateUpdateHandler';
import { ControlsManagerController } from "../Controller/ControlsManagerController";
import { ControlsManager } from "../Manager/ControlsManager";
import { ControlsStateUpdateHandler } from "../StateUpdateHandler/ControlsStateUpdateHandler";
import { SceneManager } from "../Manager/SceneManager";
import { StateUpdateHandlerAbstract } from "../StateUpdateHandler/StateUpdateHandlerAbstract";
import { ControllerAbstract } from "../Controller/ControllerAbstract";

export interface SceneFactoryResult {
  sceneManager: SceneManager;
  updateHandler: Array<StateUpdateHandlerAbstract<any>>;
  controller: Array<ControllerAbstract>;
}

export class SceneFactory {
  static createEmptyScene(document: Document, divElement: HTMLDivElement, id?: string): SceneFactoryResult {
    const sceneManager = SceneManagerFactory.createEmptySceneManager(document, divElement, id);
    const { controlsManager } = sceneManager;

    const modelStateUpdateHandler = new ModelStateUpdateHandler();
    sceneManager.addUpdateHandler(modelStateUpdateHandler);

    const controlsStateUpdateHandler = new ControlsStateUpdateHandler();
    controlsManager.addUpdateHandler(controlsStateUpdateHandler);

    const sceneManagerController = new SceneManagerController(sceneManager);
    addControllerAsSubscriber(sceneManagerController);

    const controlsManagerController = new ControlsManagerController(controlsManager as ControlsManager);
    addControllerAsSubscriber(controlsManagerController);

    return {
      sceneManager,
      updateHandler: [modelStateUpdateHandler, controlsStateUpdateHandler],
      controller: [sceneManagerController, controlsManagerController],
    };
  }
}