import * as React from 'react';

import '../static.css';

export const Impress = () => (
  <div className="static">
    <div dangerouslySetInnerHTML={{ __html: impress }} />
  </div>
)

const impress = `
  <style>
    
  </style>

  <div data-custom-class="body">
    <p style="font-size:15px;"><strong><span style="font-size: 26px;"><span data-custom-class="title">IMPRESS</span></span></strong></p>
    <p style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">Holistic Coder GmbH</span></span>&nbsp;</strong>&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">Sitz: Friedrichstrasse 36, 95444 Bayreuth</span></p>
    <p><span style="color: rgb(0, 0, 0);">Registergericht: Amtsgericht Bayreuth, HRB 7386</span></p>
    <p><span style="color: rgb(0, 0, 0);">Geschäftsführer: Christian Bühlmeyer</span></p>
    <br>
    <p style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span data-custom-class="heading_1">Contact</span></span>&nbsp;</strong>&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">christian.buehlmeyer@holistic-coder.com</span></p>
  </div>
`;