import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Separator: {
    width: '70%',
    height: '4px',
    borderTop: '1px solid dimgrey',
    borderBottom: '1px solid dimgrey',
    margin: '8px auto 8px auto',
  },
}));
