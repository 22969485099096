import React from 'react';
import * as ReactGA from 'react-ga';
import { useTheme } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import useStyles from './ModelPatternProperty.styles';
import { Button, ButtonGroup, Slider, Typography } from '@material-ui/core';
import { ObjectPattern } from 'modules/ThreeManager/Models/ObjectPattern';
import { convertEventToBase64 } from 'shared/utils/misc.helper';

interface ModelPatternPropertyProps {
  patternProperty: ObjectPattern,
  repeatPattern: number,
  onPatternUpload?: (base64File: string) => void,
  onPatternLibraryClick?: () => void,
  onPatternRepeatChange?: (repeat: number) => void,
}

export function ModelPatternProperty(props: ModelPatternPropertyProps) {
  const {
    patternProperty,
    repeatPattern = 1,
    onPatternUpload = (base64File: string) => {},
    onPatternLibraryClick = () => {},
    onPatternRepeatChange = () => {},
  } = props;
  const classes = useStyles(useTheme());

  const handlePatternUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const base64Image = await convertEventToBase64(e);
    onPatternUpload(base64Image);
    ReactGA.event({
      category: 'Model Simulator',
      action: 'Upload Pattern Image',
    });
  }

  return (
    <div className={classes.ModelPatternProperty}>
      <div className={classes.ModelPatternPropertyImageContainer}>
        <img
          alt=""
          className={classes.ModelPatternPropertyImage}
          src={patternProperty?.textureUri}
        />
        <div className={classes.ButtonGroup}>
          <ButtonGroup color="inherit" variant="contained">
            <Button
              className={classes.Button}
              startIcon={<PublishIcon />}
              component="label"
            >
              Upload
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handlePatternUpload}
              />
            </Button>
            <Button
              className={classes.Button}
              startIcon={<PhotoLibraryIcon />}
              onClick={onPatternLibraryClick}
              disabled={true}
            >
              Library
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className={classes.ModelPatternPropertySlider}>
        <Typography id="pattern-repeat" gutterBottom>
          Pattern Repeat
        </Typography>
        <Slider
          value={repeatPattern}
          onChange={(event: any, value: any) => onPatternRepeatChange(value)}
          onChangeCommitted={(event: any, value: any) => onPatternRepeatChange(value)}
          min={1}
          max={10}
          step={0.1}
          disabled={!patternProperty?.textureUri}
        />
      </div>
    </div>
  );
}
