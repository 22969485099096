import * as THREE from 'three';
import { Vector3d } from "../Models/Vector3d";

export class VectorHelper {
  static isEqual(vectorA: Vector3d, vectorB: Vector3d, delta = 0) {
    return Math.abs(vectorA.x - vectorB.x) < delta
      && Math.abs(vectorA.y - vectorB.y) < delta
      && Math.abs(vectorA.z - vectorB.z) < delta;
  }

  static convertThreeVector3ToVector3d(threeVector: THREE.Vector3): Vector3d {
    return { x: threeVector.x, y: threeVector.y, z: threeVector.z };
  }

  static convertVector3dToThreeVector3(vector: Vector3d): THREE.Vector3 {
    return new THREE.Vector3(vector.x, vector.y, vector.z);
  }
}