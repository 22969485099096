import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  NotFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    color: '#121212',
  },
  NotFoundImage: {
    width: '60%',
    height: '60%',
    maxWidth: '300px',
  }
}));
