import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ModelPreview: {
    height: '300px',
  },
  FabricObjectProperties: {
    padding: theme.spacing(3),
  },
  FabricImageLayer: {
    
  }
}));
