import { Theme, Tooltip as MUTooltip, withStyles } from '@material-ui/core';

export const Tooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 'bold',
  }
}))(MUTooltip);
