import React from 'react';
import { Slider, Typography } from '@material-ui/core';

interface PatternPropertyProps {
  repeatCount?: number,
  patternRepeatDisabled?: boolean,
  onChangePatternRepeat?: (newRepeatCount: number, updateReduxState?: boolean) => void,
}

export function PatternProperty(props: PatternPropertyProps) {
  const {
    repeatCount = 1,
    patternRepeatDisabled = false,
    onChangePatternRepeat = (newRepeatCount: number, updateReduxState = false) => {},
  } = props;

  return (
    <>
      <Typography id="pattern-repeat" gutterBottom>
        Pattern Repeat
      </Typography>
      <Slider
        value={repeatCount}
        onChange={(event: any, value: any) => onChangePatternRepeat(value)}
        onChangeCommitted={(event: any, value: any) => onChangePatternRepeat(value, true)}
        aria-labelledby="continuous-slider"
        min={1}
        max={20}
        step={0.1}
        disabled={patternRepeatDisabled}
      />
    </>
  );
}
