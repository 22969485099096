import { ThunkAction } from "redux-thunk";
import store from "store";
import { generateUUID } from 'shared/utils/misc.helper';

export abstract class StateUpdateHandlerAbstract<T> {
  id: string;

  constructor() {
    this.id = generateUUID();
  }

  abstract emit(t: T): void;

  dispatch(thunk: ThunkAction<void, any, null, any>){
    store.dispatch(thunk);
  }
}