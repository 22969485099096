import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  BackButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
  },
  BackToSceneButton: { },
  MappingEditorUpperTab: {
    height: '285px',
    overflow: 'auto',
    padding: '8px',
  },
  ToolSelection: {
  },
  Tools: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

  },
  ToolsButton: {
    position: 'relative',
    width: '200px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
    
    }
  },
  ToolText: {
    width: '100px',
    marginLeft: theme.spacing(3),
  },
  uploadPatternDesign: {
    display: 'none',
  },
  UploadLabel: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  LayerSelection: {
    flexGrow: 1,
  },
  FabricObjectProperties: {
    padding: theme.spacing(3),
  },
  FabricImageLayer: {
    
  }
}));
