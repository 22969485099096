import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Header.styles';
import Logo from 'shared/assets/images/logo.svg';
import { AccountPanel } from 'shared/components/AccountPanel';
import { Button } from '@material-ui/core';
import history from 'shared/utils/history';

interface HeaderProps {
}

export function Header(props: HeaderProps) {
  const classes = useStyles(useTheme());
  //window.location.pathname = '/mockupscenes'
  return (
    <>
      <header className={classes.Header}>
        <div className={classes.LogoContainer}>
          <img alt="" className={classes.Logo} src={Logo} />
          &nbsp;Beta
        </div>
        <div className={classes.Options}>
          <Button color="primary" variant="text" onClick={() => { history.push('/mockupscenes') }}>Scene Templates</Button>
        </div>
        <AccountPanel />
      </header>
    </>
  );
}
