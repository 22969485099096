import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Simulator: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#E5E5E5',
    display: 'flex',
    flexDirection: 'column',
  },
  SimulatorContent: {
    flexGrow: 1,
  },
}));
