import { Scene3d, SceneState, SceneLight, SceneLightType, PrimitiveObject } from "./types";
import { SceneObjectBase } from "modules/ThreeManager/Models/SceneObjectBase"
import { SceneModel } from "modules/ThreeManager/Models/SceneModel"
import { generateUUID } from "shared/utils/misc.helper";
import { ObjectStatus } from "modules/ThreeManager/Models/enum/ObjectStatus";
import { SceneHelper } from "modules/ThreeManager/helper/SceneHelper";

const createDefaultDataState = (): Scene3d => {
  return Object.assign({}, {
    settings: { },
    models: [ ],
    primitives: [ ],
    lights: [
      {
        id: SceneHelper.generateInternalSceneId(),
        threeObjectId: 0,
        label: 'Point Light - Shadow Caster',
        visible: true,
        type: SceneLightType.DirectionalLight,
        castShadow: true,
        property: {
          intensity: 1.5,
          color: "#FFFFFF",
        },
        position: {x: 8, y: 6, z: 6},
        rotation: {x: 0, y: 0, z: 0},
        scale: {x: 1, y: 1, z: 1},
        status: ObjectStatus.CreationRequested,
      },
      {
        id: SceneHelper.generateInternalSceneId(),
        threeObjectId: 0,
        label: 'Point Light - Non-Shadow Caster',
        visible: true,
        type: SceneLightType.DirectionalLight,
        castShadow: false,
        property: {
          intensity: 0.5,
          color: "#FFFFFF",
        },
        position: {x: 0, y: 6, z: 6},
        rotation: {x: 0, y: 0, z: 0},
        scale: {x: 1, y: 1, z: 1},
        status: ObjectStatus.CreationRequested,
      }
    ],
    cameraSetting: {
      position: {x: -2, y: 2, z: 3},
      lookAt: {x: 0, y: 0.5, z:0},
    }
  })
}

export function createDefaultState(id?: string): SceneState {
  const defaultDataState = createDefaultDataState();

  return {
    id: id || generateUUID(),
    loading: false,
    data: defaultDataState,
  };
}

export function getScene(sceneId: string, scenes: Array<SceneState>) {
  const foundScene = scenes.find(scene => scene.id === sceneId);
  if (foundScene) return foundScene;
  
  const createdScene = createDefaultState(sceneId);
  scenes.push(createdScene);
  return createdScene;
}

export function getModelByStoreId(sceneState: SceneState, id: string): SceneModel | undefined {
  return sceneState.data.models.find(model => model.id === id);
}

export function getLightByStoreId(sceneState: SceneState, id: string): SceneLight | undefined {
  return sceneState.data.lights.find(light => light.id === id);
}

export function getPrimitiveByStoreId(sceneState: SceneState, id: string): PrimitiveObject | undefined {
  return sceneState.data.primitives.find(primitive => primitive.id === id);
}

export function getObjectByStoreId(sceneState: SceneState, id: string): SceneObjectBase | undefined {
  const foundModel = getModelByStoreId(sceneState, id) as SceneObjectBase;
  const foundLight = getLightByStoreId(sceneState, id) as SceneObjectBase;
  const foundPrimitive = getPrimitiveByStoreId(sceneState, id) as SceneObjectBase;

  return foundModel || foundLight || foundPrimitive;
}

export function setAllObjectStates(objects: SceneObjectBase[], objectStatus: ObjectStatus) {
  const allObjects = [...objects];
  allObjects.forEach(object => object.status = objectStatus);
  return allObjects;
}

export function mergeSceneState(sceneStateTo: SceneState, sceneStateFrom: SceneState) {
  sceneStateTo.data.lights.push(...sceneStateFrom.data.lights);
  sceneStateTo.data.models.push(...sceneStateFrom.data.models);
  sceneStateTo.data.primitives.push(...sceneStateFrom.data.primitives);
  return sceneStateTo;
}
