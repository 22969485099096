import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ThumbnailCollection.styles';

export interface ThumbnailCollectionData {
  _id: string;
  thumbnailUrl: string;
}

interface ThumbnailCollectionProps {
  data: Array<ThumbnailCollectionData>;
  onSelect?: (_id: string) => void,
  onCollectionEndReached?: () => void,
}

export function ThumbnailCollection(props: ThumbnailCollectionProps) {
  const {
    onSelect = (_id: string) => {},
    onCollectionEndReached = () => {},
  } = props;
  const classes = useStyles(useTheme());
  const intersectionRef = React.useRef<HTMLDivElement>(null);

  const handleThumbClick = (_id: string) => {
    onSelect(_id);
  }

  const loadAdditionalDesigns = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      onCollectionEndReached();
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => loadAdditionalDesigns(entry),
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1
      }
    );
    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }
  }, [intersectionRef]);

  return (
    <div className={classes.ThumbnailCollection}>
      <ol className={classes.ThumbnailGrid}>
        {
          props.data.map(thumb => (
            <li className={classes.Thumbnail} key={thumb._id} onClick={() => handleThumbClick(thumb._id)}>
              <img alt="" src={thumb.thumbnailUrl}/>
            </li>
          ))
        }
      </ol>
      <div className={classes.IntersectionBar} ref={intersectionRef}></div>
      <div className={classes.IntersectionGutter}></div>
    </div>
  );
}
