import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Sidebar.styles';

interface SidebarProps {
  children?: React.ReactNode;
  className?: string,
}

export function Sidebar(props: SidebarProps) {
  const classes = useStyles(useTheme());

  return (
    <section className={`${props.className || ''} ${classes.Sidebar}`}>
      { props.children }
    </section>
  );
}
