import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GeometryLoader } from "../Loader/GeometryLoader";
import { GeometryFactoryAbstract } from "./AbstractFactories/GeometryFactoryAbstract";

export class GeometryFactory extends GeometryFactoryAbstract {
  async loadModel(key: string) {
    const gltfLoader = new GLTFLoader();
    const geometryLoader = new GeometryLoader(gltfLoader);
    return await geometryLoader.load(key);
  }
}
