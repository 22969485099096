export enum ModelCategory {
    All = 'All',
    Cosmetics = 'Cosmetics',
    Plants = 'Plants',
    Decorations = 'Decorations',
    Electronics = 'Electronics',
    Frames = 'Frames',
    Textiles = 'Textiles',
    Packaging = 'Packaging',
    Walls = 'Walls',
    Floors = 'Floors',
    Furniture = 'Furniture',
    WorkUtils = 'WorkUtils',
    Lights = 'Lights'
}