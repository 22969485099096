import * as THREE from 'three';
import { Scene } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { CameraSettings } from "features/scenes/types";
import { ControlsManagerAbstract, ControlsUpdateMode } from "./ControlsManagerAbstract";
import { ControlsFactory } from '../Factories/ControlsFactory';
import { ObjectEditOption } from 'features/ui/types';
import { VectorHelper } from '../helper/VectorHelper';

export class ControlsManager extends ControlsManagerAbstract {

  private orbitControls: OrbitControls;
  private transformControls: TransformControls | null = null;
  private dragChangeEffectHandler: (event: THREE.Event) => void = () => {};

  constructor(
    scene: Scene,
    camera: THREE.Camera,
    renderer: THREE.WebGLRenderer,
    canvas: HTMLCanvasElement,
  ) {
    super(
      scene,
      camera,
      renderer,
      canvas,
    );
    this.orbitControls = ControlsFactory.createOrbitControls(camera, renderer.domElement);
    this.orbitControls.update();
    this.orbitControls.addEventListener( 'change', this.handleOrbitControlsChange.bind(this));

  }

  setControlVisibility(isVisible: boolean) {
    if (this.transformControls !== null) {
      this.transformControls.visible = isVisible;
      this.renderCallback(true);
    }
  }

  setCamera(cameraSettings: CameraSettings) {
    const { lookAt, position } = cameraSettings;
    this.camera.position.set(position.x, position.y, position.z);
    this.orbitControls.target.set(lookAt.x, lookAt.y, lookAt.z);
    this.orbitControls.update();
  }
  
  setSelectedObject(selectedThreeObjectId?: number) {
    const selectedObject = this.scene.getObjectById(selectedThreeObjectId || -1) || null;
    if (!selectedObject) return this.removeTransformControls();

    if (this.transformControls?.userData.selectedObjectId !== selectedObject.id) {
      // First remove transformationControls from previous object
      this.removeTransformControls();

      // This assertion has to be after the removeEventListener; otherwise it cant remove the previously set listener
      // as the `this.dragChangeEffectHandler` function is overwritten and cannot be found
      this.dragChangeEffectHandler = this.createDragChangeEffectHandler(selectedObject);
  
      this.transformControls = new TransformControls( this.camera, this.canvas );
      this.transformControls.addEventListener( 'change', () => this.renderCallback());
      this.transformControls.addEventListener( 'dragging-changed', this.dragChangeEffectHandler);
      this.scene.add(this.transformControls);
      this.transformControls.attach(selectedObject);
      this.transformControls.userData = { selectedObjectId: selectedObject.uuid };
    }
    
    this.renderCallback();
  }
  
  setCameraToFitObject(objectId: number, offset: number) {
    const object = this.scene.getObjectById(objectId);

    if (!object) return;

    // check if camera is PerspectiveCamera
    if (this.camera.type === new THREE.PerspectiveCamera().type) {
      const perspectiveCamera = this.camera as THREE.PerspectiveCamera;

      const boundingBox = new THREE.Box3();
  
      // get bounding box of object - this will be used to setup controls and camera
      boundingBox.setFromObject( object );
  
      const center = boundingBox.getCenter(new THREE.Vector3(0, 0, 0));
      const size = boundingBox.getSize(new THREE.Vector3(0, 0, 0));
  
      // get the max side of the bounding box (fits to width OR height as needed )
      const maxDim = Math.max( size.x, size.y, size.z );
      const fov = perspectiveCamera.fov * ( Math.PI / 180 );
      let cameraZ = maxDim / 2 / Math.tan( fov / 2 );
  
      cameraZ *= offset; // zoom out a little so that objects don't fill the screen
  
      perspectiveCamera.position.z = cameraZ;
  
      const minZ = boundingBox.min.z;
      const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;
  
      perspectiveCamera.far = cameraToFarEdge * 3;
      perspectiveCamera.updateProjectionMatrix();
  
      // set camera to rotate around center of loaded object
      this.orbitControls.target = center;

      // prevent camera from zooming out far enough to create far plane cutoff
      this.orbitControls.maxDistance = cameraToFarEdge * 2;
      this.orbitControls.saveState();
      this.orbitControls.update();
    }
  }

  setEditOption(editOption: ObjectEditOption) {
    if (!this.transformControls) return;
    switch(editOption) {
      case ObjectEditOption.Move: this.transformControls.mode = 'translate'; break;
      case ObjectEditOption.Rotate: this.transformControls.mode = 'rotate'; break;
      case ObjectEditOption.Scale: this.transformControls.mode = 'scale'; break;
    }
  }

  private createDragChangeEffectHandler(selectedObject: THREE.Object3D) {
    return (event: THREE.Event) => {
      if (this.orbitControls) this.orbitControls.enabled = !event.value;
      if (!event.value) {
        if (this.transformControls?.mode === 'translate') {
          const {x, y, z} = selectedObject.position;
          this.controlsStateUpdateHandlersEmitAll(
            ControlsUpdateMode.PositionUpdate,
            { threeObjectId: selectedObject.id, vector: { x, y, z } },
          );
        } else if (this.transformControls?.mode === 'rotate') {
          const {x: rotationX, y: rotationY, z: rotationZ} = selectedObject.rotation;
          const normalizedX = rotationX / Math.PI;
          const normalizedY = rotationY / Math.PI;
          const normalizedZ = rotationZ / Math.PI;
          this.controlsStateUpdateHandlersEmitAll(
            ControlsUpdateMode.RotationUpdate,
            { threeObjectId: selectedObject.id, vector: { x: normalizedX, y: normalizedY, z: normalizedZ } },
          );
        } else if (this.transformControls?.mode === 'scale') {
          const {x, y, z} = selectedObject.scale;
          this.controlsStateUpdateHandlersEmitAll(
            ControlsUpdateMode.ScaleUpdate,
            { threeObjectId: selectedObject.id, vector: { x, y, z } },
          );
        }
      }
    };
  }

  private removeTransformControls() {
    if (!this.transformControls) return;

    this.transformControls.enabled = true;
    this.transformControls.removeEventListener( 'dragging-changed', this.dragChangeEffectHandler);
    this.transformControls.removeEventListener( 'change', () => this.renderCallback());
    this.transformControls.detach();
    this.transformControls.dispose();
    this.scene.remove(this.transformControls);
    this.transformControls = null;
  }

  private handleOrbitControlsChange(event: any) {
    this.renderCallback();

    const { position } = event.target.object;
    const { target } = this.orbitControls;

    this.controlsStateUpdateHandlersEmitAll(
      ControlsUpdateMode.CameraPositionLookAtUpdate,
      {
        position: VectorHelper.convertThreeVector3ToVector3d(position),
        lookAt: VectorHelper.convertThreeVector3ToVector3d(target),
      },
    );
  }

}