import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Sidebar: {
    width: "300px",
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    flexDirection: "column"
  }
}));
