import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppDispatch } from 'store';

import { Ui, ObjectEditOption, SimulatorViewState } from './types';

const initialState: Ui = {
  selectedObjectSceneId: '',
  editorSceneId: '',
  objectEditOption: ObjectEditOption.Move,
  simulatorViewState: SimulatorViewState.Simulator3d,
  collapsedObjects: [],
  currentGuidingTooltipStep: 1,
  tourOpen: !localStorage['tourAlreadyDone'],
};

const uiSlice = createSlice({
  name: 'Scene',
  initialState,
  reducers: {
    setEditorSceneIdAction(state, action: PayloadAction<string>) {
      state.editorSceneId = action.payload;
    },
    changeSelectedObjectAction(state, action: PayloadAction<string>) {
      state.selectedObjectSceneId = action.payload;
    },
    changeSimulatorViewStateAction(state, action: PayloadAction<SimulatorViewState>) {
      state.simulatorViewState = action.payload;
    },
    changeSelectedChildObjectAction(state, action: PayloadAction<string>) {
      state.selectedChildObjectName = action.payload;
    },
    changeEditOptionAction(state, action: PayloadAction<ObjectEditOption>) {
      state.objectEditOption = action.payload;
    },
    expandObjectAction(state, action: PayloadAction<string>) {
      if (!state.collapsedObjects.includes(action.payload)) state.collapsedObjects.push(action.payload);
    },
    collapseObjectAction(state, action: PayloadAction<string>) {
      state.collapsedObjects = state.collapsedObjects.filter(object => object !== action.payload);
    },
    openTourAction(state) {
      state.tourOpen = true;
    },
    closeTourAction(state) {
      state.tourOpen = false;
      localStorage['tourAlreadyDone'] = true;
    },
  }
});

const {
  setEditorSceneIdAction,
  changeSelectedObjectAction,
  changeSelectedChildObjectAction,
  changeSimulatorViewStateAction,
  changeEditOptionAction,
  expandObjectAction,
  collapseObjectAction,
  openTourAction,
  closeTourAction,
} = uiSlice.actions;

export const setEditorSceneId = (sceneId: string) : AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(setEditorSceneIdAction(sceneId));
}

export const changeSelectedObject = (objectSceneId: string): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(changeEditOptionAction(ObjectEditOption.Move));

  if (!objectSceneId) {
    dispatch(changeSelectedChildObjectAction(''));
    dispatch(changeSelectedObjectAction(''));
  }

  dispatch(changeSelectedObjectAction(objectSceneId));

  // take care that the first child is selected on object change
  const selectedObject = getState().scenes.scenes[0]?.data.models.find(model => model.id === objectSceneId);
  if (selectedObject && selectedObject.objectMaterials) {
    const keys = Object.keys(selectedObject.objectMaterials);
    if (keys.length) dispatch(changeSelectedChildObjectAction(keys[0]));
    else dispatch(changeSelectedChildObjectAction(''));
  } else {
    dispatch(changeSelectedChildObjectAction(''));
  }
}

export const changeSelectedChildObject = (childObjectName = ''): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeSelectedChildObjectAction(childObjectName));
}

export const openImageEditor = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeSimulatorViewStateAction(SimulatorViewState.MappingEditor));
}

export const openSimulator3d = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeSimulatorViewStateAction(SimulatorViewState.Simulator3d));
}

export const openTemplateScenes = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeSimulatorViewStateAction(SimulatorViewState.TemplateScenes));
}

export const changeEditOption = (objectEditOption: ObjectEditOption): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(changeEditOptionAction(objectEditOption));
}

export const expandObject = (expandObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(expandObjectAction(expandObjectId));
}

export const collapseObject = (collapseObjectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(collapseObjectAction(collapseObjectId));
}

export const openTour = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(openTourAction());
}

export const closeTour = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(closeTourAction());
}

export default uiSlice.reducer;
