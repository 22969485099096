import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './MappingEditorRightSidebar.styles';
import { Sidebar } from 'shared/components/Sidebar';
import { Tabs } from 'shared/components/Tabs';
import { ThreeModelPreview } from 'modules/Simulator3d/components/ThreeModelPreview';
import { SceneModel } from 'modules/ThreeManager/Models/SceneModel';
import { ImageManager } from 'modules/MappingEditor/utils/ImageManager/ImageManager';
import { ImageEditState } from 'features/imageEdit/types';
import { PatternProperty } from './PatternProperty';
import { convertObjectToImageType } from 'modules/MappingEditor/utils/fabric.helper';
import { ImageType } from 'modules/MappingEditor/models/enum/ImageType';
import { PrimitiveProperty } from './PrimitiveProperty';
import { Separator } from 'shared/components/Separator';
import { SceneManager } from 'modules/ThreeManager/Manager/SceneManager';

interface MappingEditorRightSidebarProps {
  model?: SceneModel,
  imageEditState: ImageEditState,
  imageEditObjects: fabric.Object[],
  onChange: () => void,
  onSceneMangerCreated: (sceneManager: SceneManager) => void,
}

export function MappingEditorRightSidebar(props: MappingEditorRightSidebarProps) {
  const {
    model,
    imageEditState,
    onChange,
    onSceneMangerCreated,
  } = props;
  const [repeatCount, setRepeatCount] = React.useState<number>(1);
  const classes = useStyles(useTheme());

  const changePattern = (newRepeatCount: number, updateReduxState: boolean = false) => {
    setRepeatCount(newRepeatCount);
    ImageManager.getInstance().changeRepeatCount(newRepeatCount);
    if (updateReduxState) onChange();
  }

  const handleColorChange = (color: string) => {
    ImageManager.getInstance().changeColor(color);
    onChange();
  }

  React.useEffect(() => {
    const selectedFabricObject = imageEditState.objects.find(object => object.data?.id === imageEditState.selectedObjectId);
    setRepeatCount(selectedFabricObject?.data?.repeat || 0);
  }, [imageEditState.selectedObjectId]);

  const renderProperty = React.useCallback(() => {
    const selectedFabricObject = imageEditState.objects.find(object => object.data?.id === imageEditState.selectedObjectId);
    if (!selectedFabricObject) return <div>No object selected</div>;
    let imageType = convertObjectToImageType(selectedFabricObject);
    switch(imageType) {
      case ImageType.Circle:
      case ImageType.Rectangle:
        return <PrimitiveProperty
          color={typeof selectedFabricObject.fill === 'string' ? selectedFabricObject.fill : '#FFFFFF'}
          onColorChange={handleColorChange}
        />;
      case ImageType.Pattern:
      default:
        return <PatternProperty
          onChangePatternRepeat={changePattern}
          repeatCount={repeatCount}
          patternRepeatDisabled={imageEditState.selectedObjectId === ''}
        />
    }
  }, [imageEditState]);

  return (
    <Sidebar>
      <div className={classes.ModelPreview}>
        <Tabs labels={['Preview']}>
          {
            model && <ThreeModelPreview model={model} onSceneMangerCreated={onSceneMangerCreated} />
          }
        </Tabs>
      </div>
      <Separator />
      <div className={classes.ModelPreview}>
        <Tabs labels={['Properties']}>
          <div className={classes.FabricObjectProperties}>
            { renderProperty() }
          </div>
        </Tabs>
      </div>
    </Sidebar>
  );
}
