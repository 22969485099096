import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './StandardMaterialProperty.styles';
import { Slider, Typography } from '@material-ui/core';

interface StandardMaterialPropertyProps {
  standardMaterialProperty: THREE.MeshStandardMaterialParameters,
  onRoughnessChange: (roughness: number) => void,
  onMetalnessChange: (metalness: number) => void,
}

export function StandardMaterialProperty(props: StandardMaterialPropertyProps) {
  const {
    standardMaterialProperty = {},
    onRoughnessChange,
    onMetalnessChange,
  } = props;
  const classes = useStyles(useTheme());

  return (
    <div className={classes.MaterialProperty}>
      <div className={classes.MaterialPropertySlider}>
        <Typography id="standardMaterial-roughness" gutterBottom>
          Roughness
        </Typography>
        <Slider
          value={standardMaterialProperty?.roughness || 1}
          onChange={(event: any, value: any) => onRoughnessChange(value)}
          onChangeCommitted={(event: any, value: any) => onRoughnessChange(value)}
          min={0}
          max={1}
          step={0.01}
        />
      </div>
      <div className={classes.MaterialPropertySlider}>
        <Typography id="standardMaterial-metalness" gutterBottom>
          Metalness
        </Typography>
        <Slider
          value={standardMaterialProperty?.metalness || 0}
          onChange={(event: any, value: any) => onMetalnessChange(value)}
          onChangeCommitted={(event: any, value: any) => onMetalnessChange(value)}
          min={0}
          max={1}
          step={0.01}
        />
      </div>
    </div>
  );
}


/* roughness?: number;
	metalness?: number;
	map?: Texture | null;
	lightMap?: Texture | null;
	lightMapIntensity?: number;
	aoMap?: Texture | null;
	aoMapIntensity?: number;
	emissive?: Color | string | number;
	emissiveIntensity?: number;
	emissiveMap?: Texture | null;
	bumpMap?: Texture | null;
	bumpScale?: number;
	normalMap?: Texture | null;
	normalMapType?: NormalMapTypes;
	normalScale?: Vector2;
	displacementMap?: Texture | null;
	displacementScale?: number;
	displacementBias?: number;
	roughnessMap?: Texture | null;
	metalnessMap?: Texture | null;
	alphaMap?: Texture | null;
	envMap?: Texture | null;
	envMapIntensity?: number;
	refractionRatio?: number;
	wireframe?: boolean;
	wireframeLinewidth?: number;
	skinning?: boolean;
	vertexTangents?: boolean;
	morphTargets?: boolean;
	morphNormals?: boolean; */