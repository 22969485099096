export const deepClone = (object: any) => {
  return JSON.parse(JSON.stringify(object))
}

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line 
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const moveArrayItem = (data: Array<any>, from: number, to: number) => {
  // remove `from` item and store it
  var f = data.splice(from, 1)[0];
  // insert stored item into position `to`
  data.splice(to, 0, f);

  return data;
}

export const deepCopy = (object: any) => {
  let outObject, value, key

  if (typeof object !== "object" || object === null) {
    return object // Return the value if "object" is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(object) ? [] : {}

  for (key in object) {
    value = object[key]

    // Recursively (deep) copy for nested objects, including arrays
    object[key] = deepCopy(value)
  }

  return outObject
}


export async function convertEventToBase64(e: React.ChangeEvent<HTMLInputElement>): Promise<string> {
  return new Promise((resolve, reject) => {
    if (e?.target?.files?.length) {
      const patternFile: File = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (f) => {
          const data = f.target?.result as string;
          if (data) {
            resolve(data);
          } else {
            resolve('');
          }
      };
      reader.readAsDataURL(patternFile);
    } else {
      resolve(''); 
    }
  })
}

export const createTitle = (title: string) => `MockupFox - ${title}`;

export const hashString = (str: string) => Array.from(str).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);