import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  ModelSelection: {
    display: 'flex',
    flexGrow: 1,
    height: 0,
  },
  ModelSelectionTitle: {
    fontSize: '1.6rem',
    marginLeft: '16px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  ModelSelectionCategory: {
    height: '38px',
    width: '38px',
    padding: '8px',
    borderTop: `1px solid ${theme.palette.background.paper}`,
  },
  ModelSelectionCategoryContainer: {
    overflow: 'hidden',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  ModelSelectionCategoryBlock: {
    overflow: 'hidden',
  },
  ModelSelectionCategorySelected: {
    backgroundColor: theme.palette.background.paper,
    opacity: '1.0',
  },
  ModelSelectionCategoryNonSelected: {
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  ModelSelectionBody: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    overflowY: 'auto',
    flexGrow: 1,
  },
  ModelSelectionCategoryIcon: {
    height: '100%',
    width: '100%',
    opacity: '0.7',
    '&:hover': {
      opacity: '1.0',
    },
  }
}));
