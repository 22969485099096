import * as THREE from 'three';

export class ThreeHelper {

  static raycastFromCamera(scene: THREE.Scene, camera: THREE.Camera, originPosition: THREE.Vector2) {
    const raycaster =  new THREE.Raycaster();                                        
    raycaster.setFromCamera( originPosition, camera );
    const modelGroup = scene.children.reduce<THREE.Object3D[]>((groupObjects, group) => [...groupObjects, ...group.children], [])
    const intersects = raycaster.intersectObjects(modelGroup);

    if (intersects.length > 0) {
      return intersects[0].object.parent;
    }
    return null;
  }
}
