import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  Tooltip: {
    background: '#FFFFFF',
    minHeight: '70px',
    borderRadius: theme.shape.borderRadius,
    color: 'black',
    maxWidth: '400px',
    border: '1px solid #FFFFFF',
  },
  TooltipHeader: {
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  TooltipClose: {
    cursor: 'pointer',
  },
  TooltipBody: {
  },
  TooltipFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}));
