import { SceneLight } from "features/scenes/types";
import { SceneModel } from "modules/ThreeManager/Models/SceneModel";
import { generateUUID } from "shared/utils/misc.helper";
import { StateUpdateHandlerAbstract } from "../StateUpdateHandler/StateUpdateHandlerAbstract";

export enum StateUpdateMode {
  ObjectCreationInProgress,
  ObjectCreationFailed,
  ObjectCreated,
  ObjectUpdateInProgress,
  ObjectUpdateFailed,
  ObjectUpdated,
  ObjectDeletionInProgress,
  ObjectDeleted,
}

export interface ObjectAndThreeIdPayload {
  storeObjectId?: string,
  threeObjectId?: number
}

export interface ModelStateUpdateMessage {
  sceneId: string,
  stateUpdate: StateUpdateMode,
  payload: ObjectAndThreeIdPayload,
}

export abstract class SceneManagerAbstract {

  readonly id: string;
  protected updateHandlers: Array<StateUpdateHandlerAbstract<ModelStateUpdateMessage>> = [];

  constructor(id?: string) {
    this.id = id || generateUUID();
  }

  abstract render(forced: boolean): void;
  abstract createModel(model: SceneModel): Promise<void>;
  abstract updateModel(model: SceneModel): Promise<void>;
  abstract createLight(light: SceneLight): Promise<void>;
  abstract updateLight(light: SceneLight): Promise<void>;
  abstract deleteObject(objectId: number): Promise<void>;
  abstract getObjectAtScreenPosition(screenPosition: THREE.Vector2): number | null;
  abstract getSceneAsPngImage(): any;

  addUpdateHandler(updateHandler: StateUpdateHandlerAbstract<ModelStateUpdateMessage>) {
    if (this.updateHandlers.find(elem => elem.id === updateHandler.id)) return;
    this.updateHandlers.push(updateHandler);
  }

  removeUpdateHandler(updateHandler: StateUpdateHandlerAbstract<ModelStateUpdateMessage>) {
    this.updateHandlers = this.updateHandlers.filter(elem => elem.id !== updateHandler.id);
  }

  protected modelStateUpdateHandlersEmitAll(stateUpdate: StateUpdateMode, payload: ObjectAndThreeIdPayload) {
    const message: ModelStateUpdateMessage = {
      sceneId: this.id,
      stateUpdate: stateUpdate,
      payload,
    }
    this.updateHandlers.forEach(updateHandler => updateHandler.emit(message));
  }

}