import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  inputPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'sans-serif',
    marginTop: '6px',
    backgroundColor: '#F4F7F4',
  },
  inputError: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.error.main,
  },
  inputErrorText: {
    color: theme.palette.error.main,
    fontSize: '0.7rem',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: 'sans-serif',
    '& input': {
      textAlign: 'center',
      color: '#000000',
      height: '1.3rem'
    }
  },
  inputField: {
    width: '100%',
    color: '#FFFFFF',
    marginBottom: theme.spacing(4),
  },
  payButton: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  buttonProgress: {
    
  }
}));
