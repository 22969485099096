import * as React from 'react';
import { fabric } from 'fabric';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import useStyles from './ImageEdit.styles';
import { ImageManager } from 'modules/MappingEditor/utils/ImageManager/ImageManager';
import { changeSelection, addObject, removeObject, modifyObject, resetImageEditStore } from 'features/imageEdit/imageEdit';
import { classToPlainObject } from 'modules/MappingEditor/utils/fabric.helper';

interface ImageEditProps {
  onImageChange: (imageBase64: string) => void,
  backgroundImageUrl: string | undefined,
  initialImageData: fabric.Object[],
}

export const ImageEdit = (props: ImageEditProps) => {
  const { onImageChange, backgroundImageUrl, initialImageData } = props;
  const dispatch = useDispatch();
  const classes = useStyles(useTheme());
  const fabricCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    dispatch(resetImageEditStore());
    document.body.addEventListener('keydown', handleFabricKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleFabricKeyDown);
    }
  }, []);

  const selectionChanged = (fabricObject: fabric.Object) => {
    if (fabricObject) dispatch(changeSelection(fabricObject.data?.id));
    else dispatch(changeSelection(''));
  }

  const addedObject = async (fabricObject: fabric.Object) => {
    dispatch(addObject(classToPlainObject(fabricObject)));
    onImageChange(await ImageManager.getInstance().getBase64Image(512));
  }

  const removedObject = async (fabricObject: fabric.Object | null) => {
    dispatch(removeObject(classToPlainObject(fabricObject)));
    onImageChange(await ImageManager.getInstance().getBase64Image(512));
  }

  const modifiedObject = async (fabricObject: fabric.Object) => {
    dispatch(modifyObject(classToPlainObject(fabricObject)));
    onImageChange(await ImageManager.getInstance().getBase64Image(512));
  }

  const handleFabricKeyDown = (event: KeyboardEvent) => {
    switch(event.key) {
       case 'Delete': ImageManager.getInstance().deleteObject();
    }
  }

  React.useEffect(() => {
    const canvasSize = Math.min(containerRef.current?.offsetHeight || 0, containerRef.current?.offsetWidth || 0);
    const canvas = new fabric.Canvas(
      fabricCanvasRef.current,
      {
        height: canvasSize,
        width: canvasSize,
        preserveObjectStacking: true,
      }
    );
    ImageManager.getInstance().bind(canvas);
    if (backgroundImageUrl) ImageManager.getInstance().setOverlayWithImgUrl(backgroundImageUrl);
    if (initialImageData && initialImageData.length) {
      ImageManager.getInstance().addFabricObjects(initialImageData);
    }

    canvas.on('object:removed', (event: any) => removedObject(event.target));
    canvas.on('object:added', (event: any) => addedObject(event.target));
    canvas.on('object:modified', (event: any) => modifiedObject(event.target));

    canvas.on('selection:cleared', (event: any) => selectionChanged(event.target));
    canvas.on('selection:created', (event: any) => selectionChanged(event.target));
    canvas.on('selection:updated', (event: any) => selectionChanged(event.target));
  }, [fabricCanvasRef]);

  React.useEffect(() => {
    const canvasSize = Math.min(containerRef.current?.offsetHeight || 0, containerRef.current?.offsetWidth || 0);
    ImageManager.getInstance().resizeCanvas(canvasSize, 2048);
  }, [fabricCanvasRef.current?.offsetHeight, fabricCanvasRef.current?.offsetWidth]);

  return (
    <div
      className={classes.ImageEditContainer}
      ref={containerRef}
    >
      <canvas
        className={classes.ImageEditCanvas}
        id="main-canvas"
        ref={fabricCanvasRef}
      />
    </div>
  )
}
