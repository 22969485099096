import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';

export const ProtectedRoute = ({...rest}) => {
  const { user } = useSelector((state: RootState) => state);

  return (!user.data && !user.loading) ? <Redirect to="/login" /> : <Route {...rest} />;
}
