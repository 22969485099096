import * as React from 'react';
import { SketchPicker, RGBColor } from 'react-color';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ColorPicker.styles';

export const convertHexToRgb = (hexString: string): RGBColor => {
  const r = parseInt(hexString.slice(1, 3), 16);
  const g = parseInt(hexString.slice(3, 5), 16);
  const b = parseInt(hexString.slice(5, 7), 16);
  return {r, g, b};
}

export const convertRgbToHex = (color: RGBColor): string => {
  const toHex = (n: number) => ('0' + n.toString(16)).slice(-2);
  return `#${toHex(color.r)}${toHex(color.g)}${toHex(color.b)}`;
}

export const convertRgbToHexNumber = (color: RGBColor) => {
  return parseInt(convertRgbToHex(color).replace('#','0x'));
}

export const convertHexNumberToRgb = (colorHexValue: number): RGBColor => {
  return convertHexToRgb('#' + colorHexValue.toString(16).toUpperCase());
}

export const ColorPicker: React.FC<{
  onColorChange: (color: string) => void,
  color: string,
}> = ({
  onColorChange,
  color,
} ) => {

  const classes = useStyles(useTheme());

  return (
    <div className={classes.ColorPicker}>
      <SketchPicker
        color={color}
        onChange={ (e) => onColorChange(convertRgbToHex(e.rgb)) }
        disableAlpha={true}
        presetColors={[]}
        styles={{picker: {picker: {height: 10}}}}
      />
    </div>
  )
}