import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './ResetPassword.styles';
import useParentStyles from '../LoginDialog.styles';
import { Button, TextField } from '@material-ui/core';
import UserApi from 'shared/utils/api/user';
import Logo from 'shared/assets/images/mockupfox_logo_black.svg';
import Pattern from 'shared/assets/images/pattern_background.svg';

export interface ResetPasswordProps {
  onSwitchToRegisterClick: () => void;
  onSwitchToLoginClick: () => void;
}

export function ResetPassword(props: ResetPasswordProps) {
  const [ email, setEmail ] = React.useState('');
  const [ isSending, setIsSending ] = React.useState(false);
  const [ isReset, setIsReset ] = React.useState(false);
  const [ error, setError ] = React.useState('');

  const {
    onSwitchToRegisterClick,
    onSwitchToLoginClick,
  } = props;
  
  const classes = useStyles(useTheme());
  const parentClasses = useParentStyles(useTheme());

  const handlePasswordResetClick = () => {
    setIsSending(true);

    new UserApi().resetPassword(email)
      .then(done => {
        setIsReset(true);
        setIsSending(false);
      })
      .catch(e => {
        setError(e.response?.data?.message || e.message || 'An error occured');
      })
  }

  const isDisabled = () => {
    return isSending || isReset;
  }

  return (
    <div className={parentClasses.LoginDialog}>
      <div className={parentClasses.Pattern}>
        <img alt="" src={Pattern} />
      </div>
      <div className={parentClasses.LoginContent}>
        <img alt="" className={classes.Logo} src={Logo} />
        <div className={parentClasses.LoginDialogTitle}>
          Reset Password
        </div>
        <div className={parentClasses.LoginDialogBody}>
          <TextField
            className={classes.LoginInput}
            label="Your Email"
            value={email}
            onChange={(event) => { setEmail(event.target.value); setError(''); }}
          ></TextField>
          
          <div className={parentClasses.LoginDialogButtonBar}>
            <Button disabled={isDisabled()} color="primary" variant="contained" size="large" onClick={handlePasswordResetClick} >
              Reset Password
            </Button>
          </div>
          { 
            error &&
            <div className={parentClasses.LoginError}>{error}</div>
          }
          { 
            isReset &&
            <div className={parentClasses.LoginSuccess}>You got an email with the new password</div>
          }
          <div className={classes.NotRegisteredYetContainer}>
            <span className={parentClasses.LoginDialogLink} onClick={onSwitchToRegisterClick}>Register an account</span>
            <span className={parentClasses.LoginDialogLink} onClick={onSwitchToLoginClick}>Login instead</span>                
          </div>
        </div>
      </div>
    </div>
  );
}
