import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  NoSelectionProperty: {
    margin: theme.spacing(1),
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gray',
  }
}));
