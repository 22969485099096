import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const imageWidth = '250px';

export default makeStyles((theme: Theme) => createStyles({
  ModelMappingProperty: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ModelMappingPropertyImage: {
    width: imageWidth,
    height: imageWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
  },
  EditButton: {
    width: imageWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#121212',
  }
}));
