import { fabric } from 'fabric';
import { ImageType } from '../models/enum/ImageType';

export const classToPlainObject = (obj: any) => {
  if (!obj) return null;
  const object = obj.toObject();
  object.data = Object.assign({}, obj.data);
  //check if image data in JSON form is available then stringify
  if (object.data?.image?.type) object.data.image = JSON.stringify(obj.data?.image);
  return object;
}

export function cloneObject(obj: fabric.Object): Promise<fabric.Object> {
  return new Promise((resolve, _) => {
    obj.clone((clonedObject: fabric.Object) => {
      resolve(clonedObject as any);
    })
  })
}

export function convertObjectToImageType(object: fabric.Object) {
  if (object.type === 'rect') {
    if (typeof object.fill === 'string') return ImageType.Rectangle;
    else return ImageType.Pattern;
  }

  if (object.type === 'circle') return ImageType.Circle;
  if (object.type === 'image') return ImageType.Image;
  if (object.type === 'textbox') return ImageType.TextBox;
}