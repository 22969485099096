import React from 'react';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './Tabs.styles';

interface TabsProps {
  labels: Array<string>;
  children: any;
  class?: string;
}

export function Tabs(props: TabsProps) {
  const { children, labels = [] } = props;
  const classes = useStyles(useTheme());
  const [ selectedTabIndex, setSelectedTabIndex ] = React.useState(0);

  // make sure that we deal with an array even if there is just one element as child
  const tabComponents = Array.isArray(children) ? children : [ children ];

  const renderTabSelector = () => (
    <div className={classes.TabTitleContainer}>
      { 
        tabComponents.map((tabComponent, index) => (
          <div
            key={`Tab_${index}`}
            className={`${classes.TabTitle} ${index === selectedTabIndex ? classes.SelectedTab : ''}`}
            onClick={() => setSelectedTabIndex(index)}
          >
            {labels[index] || `Tab ${index}`}
          </div>
        ))
      }
    </div>
  )

  const renderTabBody = () => (
    <div className={`${classes.TabBody} ${props.class}`}>
      {tabComponents[selectedTabIndex]}
    </div>
  )

  return (
    <div className={classes.Tabs}>
      { renderTabSelector() }
      { renderTabBody() }
    </div>
  );
}
